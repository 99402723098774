import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getXYChart} from "../../../components/Charts/commons";
//
//
function CustomerLollipopChart({id, data}) {
  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###");

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})

      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      // Create axes
      let yRenderer = am5xy.AxisRendererY.new(root, {
        minGridDistance: 15
      });

      yRenderer.labels.template.setAll({
        fontSize: 9,
        paddingRight: 10,
        oversizedBehavior: "truncate",
        maxWidth: 150
      });

      yRenderer.grid.template.setAll({
        location: 0.5,
        strokeDasharray: [1, 3]
      });


      let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        minDeviation: 0.1,
        renderer: yRenderer,
        categoryField: "customer"
      }))

      yAxis.data.setAll(data);

      let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        extraMax: 0,
        renderer: am5xy.AxisRendererX.new(root, {})
      }))
      let xRenderer = xAxis.get("renderer");
      xRenderer.grid.template.set("forceHidden", true);
      xRenderer.labels.template.setAll({
        fontSize: 12,
      });


      // Add series
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        categoryYField: "customer",
        valueXField: "val_ore",
        adjustBulletPosition: false,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{categoryY}: [bold]{valueX}[/]"
        })
      }))

      series.columns.template.setAll({
        height: 0.5
      });

      series.bullets.push(function() {
        return am5.Bullet.new(root, {
          locationX: 1,
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill")
          })
        })
      })

      series.data.setAll(data);
      series.appear(1000);

      return () => {
        root.dispose()
      }
    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full h-[30rem]`}/>
}

export default React.memo(CustomerLollipopChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
