import React, {useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import TableStyle from "../../../ui-components/TableStyle";
import {tableIcons} from "../../../ui-components/TableIcons";
import {OPTIONS, getLocalization, genericOnRowUpdate, genericOnRowAdd, genericOnRowDelete} from "./Params";


function TopicsTable({intl, push, data, setData, item, areas, types}) {

  const [LOCALIZATION] = useState(getLocalization(intl))
  const _OPTIONS = {...OPTIONS, filtering: true, paging: true, pageSize: 5}

  const cod = 'id_topic'
  const des = 'des_topic'

  const _onRowUpdate = (newData, oldData) =>
    genericOnRowUpdate(newData, oldData, item, cod, setData, data, intl, push)

  const _onRowAdd = (newData) =>
    genericOnRowAdd(newData, item, cod, setData, data, intl, push)

  const _onRowDelete = (oldData) =>
    genericOnRowDelete(oldData, item, cod, setData, data, intl, push)


  return (
    <TableStyle>
      <MaterialTable
        columns={[
          {
            title: intl.formatMessage({id: "area"}),
            field: 'cod_area',
            lookup: Object.values(areas).reduce((obj, i) => _.set(obj, [i.cod_area], i.des_area), {})
          },
          {
            title: intl.formatMessage({id: "type"}),
            field: 'cod_type',
            lookup: Object.values(types).reduce((obj, i) => _.set(obj, [i.cod_type], i.des_type), {})
          },
          {
            title: intl.formatMessage({id: "name"}),
            field: des
          },
        ]}
        data={_.orderBy(Object.values(data), ['cod_area', 'cod_type', 'des_topic'])
          .map((r, i) => ({...r, id: i}))}
        icons={tableIcons}
        title={intl.formatMessage({id: 'topics'})}
        options={_OPTIONS}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowAdd: _onRowAdd,
          onRowDelete: _onRowDelete
        }}
      />
    </TableStyle>
  );
}

export default React.memo(TopicsTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.data, nextProps.data)
    && _.isEqual(props.areas, nextProps.areas)
    && _.isEqual(props.types, nextProps.types);
});
