import React, {useEffect, useState} from "react";
import {PageSection} from "../../../ui-components/Container";
import RadioGroup from "../../../ui-components/RadioGroup";
import BudgetTrendChart from "./BudgetTrendChart";
import {useIntl} from "react-intl";
import AccountTable from "./AccountTable";
import {currencyStyleUsd} from "../../../utils/constants";

export const CloudDetailView = ({accountDetail, monthlyAggregation, organizationalUnits}) => {

  const dataPoints = monthlyAggregation.length - 1
  const currentMonth = new Date(monthlyAggregation[dataPoints]['month'])
  const previousMonth = new Date(monthlyAggregation[dataPoints - 1]['month'])
  const [kpiPeriod, setKpiPeriod] = useState('mtd');
  const [accountKpi, setAccountKpi] = useState([]);
  const intl = useIntl();

  const getFilteredData = (period) => {
    switch (period) {
      case 'mtd':
        return [monthlyAggregation[dataPoints]]
      case 'pm':
        return [monthlyAggregation[dataPoints - 1]]
      case 'ytd':
        return monthlyAggregation.filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear())
      default:
        return monthlyAggregation.filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear() - 1)
    }
  }

  const getKpi = (ou, title, data) => {
    const budgetMeasure = ou === 'total' ? 'amt_budget' : `budget_${ou}`
    const billingMeasure = ou === 'total' ? 'amt_billing' : `amt_${ou}`
    const forecastMeasure = ou === 'total' ? 'amt_forecast' : `forecast_${ou}`
    const accountMeasure = ou === 'total' ? 'num_accounts' : `num_${ou}`
    const budget = data.reduce((obj, i) => obj + (i[budgetMeasure] || 0), 0)
    const billing = data.reduce((obj, i) => obj + (i[billingMeasure] || 0), 0)
    const forecast = ['mtd', 'ytd'].includes(kpiPeriod) ? data.reduce((obj, i) => obj + (i[forecastMeasure] || 0), 0) + billing : billing
    const variation = Math.round((forecast - budget) / budget * 100)
    return {
      title: title,
      accounts: data[data.length - 1][accountMeasure],
      budget: intl.formatNumber(budget, currencyStyleUsd),
      billing: intl.formatNumber(billing, currencyStyleUsd),
      forecast: intl.formatNumber(forecast, currencyStyleUsd),
      variation: variation,
      customStyle: variation > 0 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
    }
  }

  useEffect(() => {
    const data = getFilteredData(kpiPeriod)
    let res = [getKpi('total', 'Tutti gli account', data)]
    organizationalUnits.filter(i => !['Core', 'Sospesi'].includes(i)).forEach(ou => {
      res.push(getKpi(ou, ou, data))
    })
    setAccountKpi(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiPeriod])

  return (
    <div>
      <div className="flex flex-col sm:flex-row gap-10 items-center">
        <RadioGroup
          id="choose_kpi_period"
          options={[
            {value: "mtd", name: "Mtd"},
            {value: "ytd", name: "Ytd"},
            {value: "pm", name: "Pm"},
            {value: "py", name: "PY"},
          ]}
          currentValue={kpiPeriod}
          onChange={(value) => {
            setKpiPeriod(value)
          }}
        />
        <div className="w-full">
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-5">
            {accountKpi.map(kpi => (
              <div key={kpi.title} className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow text-center text-sm">
                <div className="flex flex-row justify-between mb-2">
                  <dt className="truncate font-bold text-gray-700">{kpi.title}</dt>
                  <dt className="truncate text-am-700 capitalize font-bold">{kpi.accounts}</dt>
                </div>
                <div className="flex flex-row justify-between">
                  <dd className="truncate mt-1 tracking-tight text-gray-500">Budget</dd>
                  <dd className={`mt-1 tracking-tight text-am-700`}>{kpi.budget}</dd>
                </div>
                <div className="flex flex-row justify-between">
                  <dd className="truncate mt-1 tracking-tight text-gray-500">Billing</dd>
                  <dd className={`mt-1 font-semibold tracking-tight text-am-700`}>{kpi.billing}</dd>
                </div>
                {['mtd', 'ytd'].includes(kpiPeriod) &&
                  <div className="flex flex-row justify-between">
                    <dd className="truncate mt-1 tracking-tight text-gray-500">Forecast</dd>
                    <dd className={`mt-1 font-semibold tracking-tight text-am-700`}>{kpi.forecast}</dd>
                  </div>
                }
                <div className="content-center">
                <span
                  className={`inline-flex items-center px-4 rounded-full font-bold text-xs h-6 mt-2 ${kpi.customStyle}`}>
                  {kpi.variation > 0 ? '+' : ''}{kpi.variation}%
                </span>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div>
        <BudgetTrendChart
          id="budget-trend-chart"
          data={monthlyAggregation.map(i => ({
            ...i,
            amt_forecast: new Date(i.month) >= previousMonth ? i.amt_forecast + i.amt_billing : null})
          )}
        />
      </div>
      <div>
        <PageSection title="Dettaglio account">
          <AccountTable
            data={accountDetail}
            organizationalUnits={organizationalUnits}
          />
        </PageSection>
      </div>
    </div>
  )
}