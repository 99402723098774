import React, {useEffect, useState} from "react";

import _ from "lodash";
import apiRequest from "../../../services/apiRequest";

import Button from "../../../ui-components/Button";
import AddRevenueDialog from "../../../components/Dialogs/AddRevenue";
import {PlusCircleIcon, ArrowPathIcon} from "@heroicons/react/24/solid";

import MaterialTable from "@material-table/core";
import TableStyle from "@ui-components/TableStyle";
import {tableIcons} from "@ui-components/TableIcons";
import {getColumns, LOCALIZATION, OPTIONS} from "@components/Tables/Params/RevenueEntry";
import ManageRevenueTrancheDialog from "../../../components/Dialogs/ManageRevenueTranche";

const statusOptions = ['ESOLVER', 'RINNOVO', 'DELTA', 'COMMITTED']

export const DataEntryView = ({intl, push, data, setData, selectedYear, setSelectedYear, pmList}) => {

  const [entry, setEntry] = useState(data.entry
    .reduce((obj, i) => _.set(obj, [`${i.id_documento}_${i.id_riga}`], {
      ...i,
      id: `${i.id_documento}_${i.id_riga}`,
      cod_des_commessa: `${i.cod_commessa}_${i.des_commessa}`
    }), {})
  )

  useEffect(() => {
    setData({...data, entry: Object.values(entry)})
  }, [entry]) // eslint-disable-line react-hooks/exhaustive-deps

  const [showAdd, setShowAdd] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [editTranche, setEditTranche] = useState();
  const [tableFilters, setTableFilters] = useState();
  const [columns] = useState(getColumns(intl, statusOptions, pmList, setEditTranche, tableFilters));

  const runUpdating = () => {
    setIsUpdating(true)
    apiRequest.get("/economics/run-revenue-update")
      .then(() => {
        setSelectedYear(undefined)
        setSelectedYear(selectedYear)
        push({title: "Dati aggiornati", type: "success"});
      })
      .catch(() => {
        push({title: "Errore imprevisto", type: "error"})
      })
      .finally(() => setIsUpdating(false));
  }

  const _onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        apiRequest
          .post(`/economics/entry/${oldData.id_documento}/${oldData.id_riga}`, newData)
          .then((res) => {
            const key = `${res.id_documento}_${res.id_riga}`
            const tmp = {
              ...entry, [key]: {
                ...res,
                id: `${res.id_documento}_${res.id_riga}`,
                cod_des_commessa: `${res.cod_commessa}_${res.des_commessa}`,
              }
            }
            setEntry(tmp)
            push({title: "Riga aggiornata con successo", type: "success"});
            resolve();
          })
          .catch(() => {
            push({
              title: "Errore durante l'aggiornamento della riga",
              type: "error",
            });
            reject();
          });
      }
    });

  const _onRowDelete = (oldData) =>
    new Promise((resolve, reject) => {
      apiRequest
        .delete(`/economics/entry/${oldData.id_documento}/${oldData.id_riga}`)
        .then(() => {
          const {[oldData.id]: tmp, ...rest} = entry;
          setEntry({...rest});
          push({title: "Riga eliminata con successo", type: "success"});
          resolve();
        })
        .catch(() => {
          push({
            title: "Errore durante l'eliminazione della riga",
            type: "error",
          });
          reject();
        });
    });

  return (
    <div className="mt-4 flex flex-col mb-10 gap-5">
      <div className="flex flex-row justify-between">
        <Button styleType="secondary" onClick={runUpdating} submitting={isUpdating}>
          <ArrowPathIcon width="18" height="18"/>
          <span className="ml-2">Lancia aggiornamento</span>
        </Button>
        <Button styleType="secondary" onClick={() => setShowAdd(true)}>
          <PlusCircleIcon width="18" height="18"/>
          <span className="ml-2">Aggiungi</span>
        </Button>
      </div>
      <TableStyle>
        <MaterialTable
          columns={columns}
          data={Object.values(entry)}
          icons={tableIcons}
          isLoading={entry === undefined}
          options={OPTIONS}
          localization={LOCALIZATION}
          onFilterChange={(filter) => setTableFilters(filter)}
          editable={{
            onRowUpdate: _onRowUpdate,
            onRowDelete: _onRowDelete,
            isDeleteHidden: (rowData) => rowData["status"] === 'ESOLVER',
          }}
        />
      </TableStyle>
      <AddRevenueDialog
        open={showAdd}
        toggleOpen={() => setShowAdd(false)}
        entry={entry}
        pmList={pmList}
        setEntry={setEntry}
        selectedYear={selectedYear}
        statusOptions={statusOptions}
        intl={intl}
      />
      {editTranche &&
        <ManageRevenueTrancheDialog
          editTranche={editTranche}
          selectedYear={selectedYear}
          toggleOpen={() => setEditTranche(undefined)}
          entry={entry}
          setEntry={setEntry}
          intl={intl}
        />
      }
    </div>);
}
