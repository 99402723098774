import React, {useContext, useEffect, useState} from "react";

import AuthenticationService from "../../services/AuthenticationService";

// UI
import {PageHeading} from "../../ui-components/Container";
import CourseTable from "../../components/Tables/CourseTable";
import {useParams} from "react-router-dom";
import CourseSpecifics from "../CourseSpecifics";
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext/DataContext";
import {useIntl} from "react-intl";
import Placeholder from "../../ui-components/Placeholder";
import Tabs from "../../ui-components/Tabs";

function Courses() {

  const params = useParams();
  const userData = AuthenticationService.getUserData();
  const idCourse = params['courseId']
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);
  const intl = useIntl();

  const [tabIndex, setTabIndex] = useState(0);
  const [myCourses, setMyCourses] = useState([]);
  const [courseList, setCourseList] = useState();
  const [refresh, setRefresh] = useState(false);


  const fetchAllCourses = () => {
    apiRequest
      .get("/course-suite/courses/skill/evaluation")
      .then((result) => {
        setCourseList(result);
      })
      .catch((e) => {
        console.log(e);
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }

  const fetchAllCourseType = () => {
    apiRequest
      .get("/course-suite/course_type")
      .then((result) => {
        dataContext.setCourseTypeList(result);
      })
      .catch((e) => {
        console.log(e);
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error"});
      })
  }

  const fetchAllSkill = () => {
    apiRequest
      .get("/skills")
      .then((result) => {
        dataContext.setSkillList(result);
      })
      .catch((e) => {
        console.log(e);
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error"});
      })
  }

  const fetchMyCourses = () => {
    apiRequest
      .get(`/course-suite/attend/user/${userData.id}`)
      .then((result) => {
        (result || []).forEach((i) => {setMyCourses([...myCourses, i.id_course_attended]);});
      })
  }

  useEffect(() => {
    fetchAllCourses();
    fetchMyCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  useEffect(() => {
    if (!dataContext.skillList) {
      fetchAllSkill();
    }
    if (!dataContext.courseTypeList) {
      fetchAllCourseType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.skillList, dataContext.courseTypeList])

  if (idCourse) {
    return <CourseSpecifics id_course={idCourse}/>
  } else {
    return (
      <div>
        <PageHeading title={intl.formatMessage({ id: "corsi"})}/>
        <div className="-mt-8">
        {(dataContext.courseTypeList && courseList && myCourses)
          ? 
          <div className="flex flex-col gap-4 mb-10">
            <Tabs
              setActiveTab={setTabIndex}
              active={tabIndex}
              tabs={[
                {name: intl.formatMessage({id: "all_courses"})},
                {name: intl.formatMessage({id: "my_courses"})}
              ]}
            />
            <CourseTable
              data={tabIndex === 0 ? courseList : (courseList || []).filter((course) => myCourses.includes(course.id_course))}
              isPersonal={tabIndex === 1}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
          : <div>
            <Placeholder width="w-full" height="h-12 mt-5"/>
            <Placeholder width="w-full" height="h-12" classNames="bg-am-700 my-3"/>
            <Placeholder width="w-full" height="h-96"/>
          </div>
        }
        </div>
      </div>
    );
  }
}

export default Courses;