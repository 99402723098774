import React, { useLayoutEffect } from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { addLicense, getXYChart } from "../../components/Charts/commons";
import { useIntl } from "react-intl";


function OkrTeamProgressBarChart({ id, data, measure }) {
  const intl = useIntl();

  useLayoutEffect(
    () => {
      if (!data)
        return;

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)]);
      root.locale = am5locales_it_IT;

      // Create chart
      let chart = getXYChart(root, { layout: root.verticalLayout })

      chart.get("colors").set("colors", [
        am5.color(0xFBBA72),
        am5.color(0xCA5310),
        am5.color(0x8F250C),
        am5.color(0x691E06)
      ]);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          categoryField: "category"
        })
      );

      xAxis.data.setAll(data);


      // Add series
      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: intl.formatMessage({ id: "completion_progress" }),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "category",
        })
      );
      series1.appear(1200);

      //tooltip setup
      var tooltip = am5.Tooltip.new(root, {
        autoTextColor: false,
        getFillFromSprite: false,
        labelText: "[bold]{name}[/]\n{categoryX}: {valueY}%"
      });

      tooltip.label.setAll({
        fill: 0x00000
      })
      tooltip.get("background").setAll({
        fill: am5.color(0x79CDCD),
        fillOpacity: 0.6
      });

      series1.set("tooltip", tooltip);

      series1.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}%",
        tooltipY: 0
      });

      series1.data.setAll(data);
      series1.columns.template.adapters.add("fill", function (fill, target) {
        return chart.get("colors").getIndex(series1.columns.indexOf(target));
      });
      series1.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors").getIndex(series1.columns.indexOf(target));
      });
      return () => {
        root.dispose()
      }

    },
    [id, data] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const height = measure === 'num' ? 'h-64' : 'h-96'

  return <div id={id} className={`-mt-5 w-full ${height}`} />
}

export default React.memo(OkrTeamProgressBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.measure, nextProps.measure)
})
