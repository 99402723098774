import React from "react";
import Input from "../../ui-components/Input";
import { useIntl } from "react-intl";

function CourseAttendance({reportHour, setReportHour}) {

  const intl = useIntl();

  return (
    <div className="flex flex-row items-center gap-x-5">
      <div className="mb-5">
        {intl.formatMessage({id: "hours_spent"})}
      </div>
      <div className="w-24">
        <Input
          value={reportHour}
          required
          type="number"
          numberOptions={{minimumFractionDigits: 0, maximumFractionDigits: 0}}
          errorMessage={intl.formatMessage({ id: "positive_integer_error"})}
          onChange={(e) => {setReportHour(parseInt(e.target.value))}}
        />
      </div>
    </div>
  );
}

export default CourseAttendance;