import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, REVENUE_COLORS, getXYChart} from "../../../components/Charts/commons";


function RevenueTrendBarChart({id, data, categories, selectedView}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.€");
      root.dateFormatter.set("dateFormat", "MMM yyyy")

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 11,
        height: 11
      });

      legend.labels.template.setAll({
        fontSize: 11,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let xAxis
      if (selectedView === 'quarterly') {
        xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: "quarter",
          renderer: am5xy.AxisRendererX.new(root, {
            // cellStartLocation: 0.1,
            // cellEndLocation: 0.9,
            // minGridDistance: 20
          })
        }))
      } else {
        xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: 'month', count: 1},
            gridIntervals: [{timeUnit: "month", count: 1}],
            renderer: am5xy.AxisRendererX.new(root, {}),
          })
        )
      }
      xAxis.data.setAll(data);
      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.set("fontSize", 11);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {}),
        // tooltip: am5.Tooltip.new(root, {}),
        calculateTotals: true
      }))
      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("forceHidden", true);

      const getValueField = (showTotal, name, measure, py) => {
        if (showTotal) {
          return name
        }
        if (py) {
          return `${selectedView}_contab_${name}_py`
        } else {
          return `${selectedView}_${measure}_${name}`
        }
      }

      // Add series
      function makeSeries(name, measure, showTotal, stacked, py) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: py ? `${name} PY` : name,
          xAxis: xAxis,
          yAxis: yAxis,
          fill: REVENUE_COLORS[name],
          valueYField: getValueField(showTotal, name, measure, py),
          valueXField: selectedView === 'quarterly' ? null : "month",
          categoryXField: selectedView === 'quarterly' ? "quarter" : null,
          stacked: stacked,
          maskBullets: false,
          legendLabelText: measure === 'fcst' ? `${name} (previsto)` : py ? `${name} PY` : name,
          tooltip: !showTotal && am5.Tooltip.new(root, {
            labelText: `[bold]{name}${measure === 'fcst' ? ' (previsto)' : ''}: {valueY}[/]`,
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        series.columns.template.setAll({
          width: am5.percent(60)
        });

        if (measure === 'fcst') {
          series.columns.template.set("fillPattern", am5.LinePattern.new(root, {
            color: am5.color(0xffffff),
            fillOpacity: 0.9,
            rotation: 45,
            width: 200,
            height: 200
          }));
        }

        if (py) {
          series.columns.template.setAll({
            fillOpacity: 0.5,
          });
        }

        if (showTotal) {
          series.bullets.push(function(target, series, dataItem) {
            let index = chart.series.indexOf(series);
            let dataItemIndex = series.dataItems.indexOf(dataItem);
            let sum = 0;
            chart.series.eachReverse(function(series, seriesIndex) {
              if (seriesIndex <= index) {
                let seriesDataItem = series.dataItems[dataItemIndex];
                sum += (seriesDataItem.get("valueY") || 0);
                if (!series.get("stacked")) {
                  index = -1;
                }
              }
            });
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: root.numberFormatter.format(sum),
                fill: am5.color(0x000000),
                centerX: selectedView === 'quarterly' ? am5.p50 : am5.p0,
                centerY: am5.p100,
                populateText: true,
                fontSize: 10,
                fontWeight: 700,
                rotation: selectedView === 'quarterly' ? 0 : -70,
              })
            });
          });
        }

        series.data.processor = am5.DataProcessor.new(root, {
          dateFields: ['month'],
          dateFormat: "dd/MM/yyyy"
        })

        if (!showTotal) {
          legend.data.push(series);
        }
        series.data.setAll(data);
        series.appear(1000);

        return series
      }

      makeSeries('Prestazioni', 'contab', false, false, true)
      makeSeries('Canoni', 'contab', false, true, true)
      makeSeries("none", 'contab', true, true);
      makeSeries('Prestazioni', 'contab', false, false)
      makeSeries('Canoni', 'contab', false, true)
      makeSeries('Prestazioni', 'fcst', false, true)
      makeSeries('Canoni', 'fcst', false, true)
      makeSeries('Delta', 'delta', false, true)
      makeSeries('Committed', 'committed', false, true)
      makeSeries("none", false, true, true);

      return () => {
        root.dispose()
      }
    },
    [id, data, categories, selectedView] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`w-full h-96`}/>
}

export default React.memo(RevenueTrendBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.selectedView, nextProps.selectedView)
})
