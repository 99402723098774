import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {getPieChart, addLicense, setChartColor} from "../../components/Charts/commons";


function StaffTeamDonutChart({id, data, measure}) {

  useLayoutEffect(() => {

    addLicense();

    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    let chart = getPieChart(root)

    // Add series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: measure,
      categoryField: "value",
      alignLabels: false,
      radius: am5.percent(40)
    }))

    series.slices.template.setAll({
      templateField: "settings",
    })

    series.slices.template.adapters.add(
      "tooltipText",
      (text, target) => {
        const data = target.dataItem?.dataContext;
        return `[bold]${data.name}[/]\n {value} ({valuePercentTotal.formatNumber('0.')}%)`;
      }
    );

    // Disabling labels and ticks
    // series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    series.labels.template.setAll({
      maxWidth: 120,
      oversizedBehavior: "truncate", // to truncate labels, use "truncate", else "wrap"
      text: "[bold fontSize:10px]{category}[/]\n[fontSize:14px]{value}[/][/]",
    });


    setChartColor(series)
    series.data.setAll(data)

    chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: `[bold fontSize:24px]${Math.round(data.reduce((acc, i) => acc + i[measure], 0) * 10) / 10}[/]`
      })
    );

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id, measure]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} className="w-full h-72"/>
}

export default React.memo(StaffTeamDonutChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.measure, nextProps.measure)
})
