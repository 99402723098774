import React, {useContext, useEffect, useState} from "react";

import AttendanceReportTable from "../../../components/Tables/AttendanceReportTable";
import {NotificationsContext} from "../../../ui-components/Notifications";
import {PageHeading} from "../../../ui-components/Container";
import apiRequest from "../../../services/apiRequest";
import { useIntl } from "react-intl";


function AttendanceReport() {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [attendanceReportList, setAttendanceReportList] = useState([]);

  // TODO we can add more columns (user team, date subscription...)

  const fetchAttendanceReport = () => {
    apiRequest
      .get("/course-suite/attendance-report")
      .then((result) => {
        const temp = (result || []).map((e) => ({
          hours: e.hours,
          full_name: e['attend']['user_attended'].full_name,
          name_course: e['attend']['course_attended'].name_course
        }));
        setAttendanceReportList(temp);
      })
      .catch(() => {
        push({title: intl.formatMessage({ id: "error_loading"}), type: "error"});
      })
  }

  useEffect(() => {
    fetchAttendanceReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <PageHeading title="Settings"/>
      <AttendanceReportTable
        title={intl.formatMessage({id: "attendance_report"})}
        data={attendanceReportList}
      />
    </div>
  )
}

export default AttendanceReport;