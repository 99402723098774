import React, {useContext, useEffect, useState} from "react";


import {PageHeading, PageSection} from "../../../ui-components/Container";
import {NotificationsContext} from "../../../ui-components/Notifications";
import apiRequest from "../../../services/apiRequest";
import {useIntl} from "react-intl";
import Tree from "./Tree";


function OrganizationChart() {

  const intl = useIntl();

  const {push} = useContext(NotificationsContext);
  const [organizationChart, setOrganizationChart] = useState([]);

  const fetchOrganizationChart = () => {
    apiRequest
      .get("/roles-almagammaorganizationChart/list/username/organization")
      .then((result) => {
        setOrganizationChart(result);
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }

  useEffect(() => {
    fetchOrganizationChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="flex flex-col ">
      <PageHeading title="Settings"/>
      <div className="-mt-5">
        <PageSection title={intl.formatMessage({id: "organization_chart"})}>
          {(organizationChart && organizationChart.length > 0) ? (
            <div className="OrganizationChart flex w-full rounded-md overflow-auto m-4 h-screen">
              <Tree root={organizationChart[0]} data={organizationChart} depth={3}/>
            </div>
          ) : null}
        </PageSection>
      </div>
    </div>);
}

export default OrganizationChart;