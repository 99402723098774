import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, GESCLI_COLORS, getXYChart} from "../../../components/Charts/commons";


function GescliTrendChart({id, data, measure, categories}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", "#,###.");
      root.dateFormatter.set("dateFormat", "MMM yyyy")

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineX.set("visible", false);
      cursor.lineY.set("visible", false);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let xAxis = chart.xAxes.push(
        chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: 'month', count: 1},
            gridIntervals: [{timeUnit: "month", count: 1}],
            renderer: am5xy.AxisRendererX.new(root, {}),
          })
        ))
      xAxis.data.setAll(data);
      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.set("fontSize", 12);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.05,
        renderer: am5xy.AxisRendererY.new(root, {}),
        calculateTotals: true
      }))
      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("fontSize", 11);


      // Add series
      function makeSeries(name) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name.replace("_", ' ').toUpperCase(),
          xAxis: xAxis,
          yAxis: yAxis,
          fill: GESCLI_COLORS[name],
          valueYField: `val_${name}`,
          valueYShow: measure === 'percentage' ? "valueYTotalPercent": 'valueY',
          valueXField: "month",
          stacked: true,
          legendLabelText: name.replace("_", ' ').toUpperCase(),
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueX.formatDate()}\n[bold]{name}: {valueY} ({valueYTotalPercent}%)[/]",
            tooltipY: am5.percent(10),
            fontSize: 8
          })
        }))

        series.columns.template.setAll({
          fillOpacity: 0.65,
          visible: true
        });

        if (name === categories[categories.length - 1]) {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: "[bold]{valueYTotal}[/]",
                centerX: am5.p50,
                centerY: am5.percent(85),
                populateText: true,
                fontSize: 9
              })
            });
          });
        }

        series.data.processor = am5.DataProcessor.new(root, {
          dateFields: ['month'],
          dateFormat: "dd/MM/yyyy"
        })

        legend.data.push(series);
        series.data.setAll(data);
        series.appear(1000);

        return series
      }

      categories.forEach(c => {
        makeSeries(c)
      })


      return () => {
        root.dispose()
      }
    },
    [id, data, categories, measure] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return <div id={id} className={`-mt-5 w-full h-96`}/>
}

export default React.memo(GescliTrendChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.measure, nextProps.measure)
})
