import React from "react";
import {Link} from "react-router-dom";
import Placeholder from "./Placeholder";


// Constants
const BREADCRUMB_ITEMS_SEPARATOR = '/'


const Breadcrumb = ({
    items,
    textColorClass = "text-gray-500",
    textColorSelectedClass = "text-gray-900",
    textColorHoverClass = "hover:text-gray-900",
    separatorColorClass = "text-gray-300",
    classNames = "",
}) => {

  return (
    <div className={`w-full flex ${classNames}`}>
      {
        items.map((item, idx) => {
          const active = item.href !== undefined || item.onClick !== undefined;
          const isLast = idx === items.length - 1;
          return <div className="flex" key={idx}>
            <Link
              to={item.href ?? '#'}
              onClick={item.onClick}
              className={
                `${isLast ? "font-medium" : ""} ` +
                `${isLast ? textColorSelectedClass : textColorClass} ` +
                `${textColorHoverClass} ` +
                `${!active ? "cursor-default" : ""} ` +
                `${item.title ? "" : "pointer-events-none"}`
              }
            >
              {item.title || <Placeholder width="w-28" height="h-5" classNames="mt-0.5" />}
            </Link>
            {
              // add items separator
              !isLast ?
                <span className={`pointer-events-none mx-4 ${separatorColorClass}`}>
                  {BREADCRUMB_ITEMS_SEPARATOR}
                </span> :
                null
            }
          </div>
        })
      }
    </div>
  );
};

export default Breadcrumb;
