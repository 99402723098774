import React, { useEffect, useContext, useState } from "react";
import { NotificationsContext } from "../../ui-components/Notifications";
import { useIntl } from "react-intl";
import apiRequest from "../../services/apiRequest";
import Button from "../../ui-components/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function TeamProgressOkr({ userId, userName, id, startDate, endDate, okrs}) {

  const { push } = useContext(NotificationsContext);
  const intl = useIntl();
  const [subs, setSubs] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const fetchOkrSubData = (userId) => {
    apiRequest
      .get(`/okrs/subordinates_okrs_progress?start_date=${startDate}&end_date=${endDate}&user_id=${userId}`)
      .then((result) => {
        setSubs(Object.values(result));
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }


  // const fetchOkrsData = (value) => {
  //   //Obtains all active OKRs of the user with id `value`, but limits them to 3 {slice(0, 3)}
  //   apiRequest
  //     .get(`/okrs/subordinates_okrs_progress/${value}?start_date=${getDateString(startDate)}&end_date=${getDateString(endDate)}`)
  //     .then((result) => {
  //       setOkrs(Object.keys(result).slice(0, 3).map(key => ({
  //         name: key,
  //         count: result[key][0],
  //         value: result[key][1],
  //         id: result[key][2]
  //       })));
  //     })
  //     .catch(() => {
  //       push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
  //     })
  // }

  // const fetchSubordinates = (value) => {
  //   apiRequest
  //     .get(`/roles-almagamma/organization-chart/list_subordinates/${value}/`)
  //     .then((result) => {
  //       setSubs(result);
  //     })
  //     .catch(() => {
  //       push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
  //     })
  // }

  useEffect(() => {
    // fetchOkrsData(userId);
    fetchOkrSubData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const style = `flex flex-col gap-2 bg-gray-${(id % 2) + 1}00 border border-neutral-600 rounded-2xl p-4`;

  return (
    <div className={style} key={userId}>
      <div className="grid grid-cols-one-two">
        <div className="flex flex-col gap-3 justify-between">
          <p className="font-medium text-lg">{userName}</p>
          {subs.length > 0 ? (
            <div>
              <Button
                styleType={"secondary"}
                onClick={() => {
                  setExpanded(!expanded);
                }}>
                {expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
              </Button>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-2">
          {okrs.length === 0 ? (
            <p className="italic">{intl.formatMessage({ id: "okr_none" })}</p>
          ) : (
            okrs.map((element, key) => {
              return (
                <div key={key} className="bg-white border border-slate-300 rounded-xl p-2 grid grid-cols-four-one">
                  <p>{element["name"]}</p>
                  <div className="relative bg-gray-300 rounded-lg text-center h-6">
                    <div style={{ width: `${element["progress"]}%` }} className="bg-green-500 h-full rounded-lg"></div>
                    <div className="absolute inset-0 w-full">{element["progress"] === null ? 100 : element["progress"].toFixed(0)}%</div>
                  </div>
                </div>
              )
            })
          )}
        </div>
      </div>
      {expanded ? 
        <div className="flex flex-col gap-2">
          {
            subs.map((element) => {
              return (
                <TeamProgressOkr
                  userId={element["id_user"]}
                  userName={element["name"]}
                  okrs={element["okr_list"]}
                  id={id + 1}
                  startDate={startDate}
                  endDate={endDate}
                />
              )
            })
          }
        </div> : null
      }
    </div>
  )

}

export default TeamProgressOkr;