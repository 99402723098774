import React from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import {AppDetailSchema, AppSchema, HostGlobalStatus, ServiceStatus} from "@pages/Monitoring/Monitoring.types";
import {IntlShape} from "react-intl";


export const returnColorByStatus = (status: HostGlobalStatus | ServiceStatus | undefined) => {
  if (status === 'OK') {
    return "bg-green-500";
  } else if (status === 'WARNING') {
    return "bg-yellow-500";
  } else if (status === 'CRITICAL') {
    return "bg-red-500";
  } else {
    return "bg-gray-300";
  }
}

export const checkboxOptions = (statusCount: { OK: number, WARNING: number, KO: number }) => [
  {
    name: (
      <div className="flex items-center text-green-500 -mt-0.5">
        <CheckCircleIcon className="w-6 h-6 mr-1"/>
        {"Ok " + statusCount.OK}
      </div>
    ),
    value: 'OK',
  },
  {
    name: (
      <div className="flex items-center text-yellow-500 -mt-0.5">
        <ExclamationCircleIcon className="w-6 h-6 mr-1"/>
        <span>{"Warning " + statusCount.WARNING}</span>
      </div>
    ),
    value: 'WARNING',
  },
  {
    name: (
      <div className="flex items-center text-red-500 -mt-0.5">
        <XCircleIcon className="w-6 h-6 mr-1"/>
        {"Ko " + statusCount["KO"]}
      </div>
    ),
    value: 'CRITICAL',
  },
]

export const CardIconStatus = ({codStatus}: { codStatus: HostGlobalStatus | undefined }) => {
  switch (codStatus) {
    case 'OK':
      return <CheckCircleIcon className="w-10 h-10 mr-2 text-green-500"/>
    case 'WARNING':
      return <ExclamationCircleIcon className="w-10 h-10 mr-2 text-yellow-500"/>
    case 'CRITICAL':
      return <XCircleIcon className="w-10 h-10 mr-2 text-red-500"/>
    default:
      return <QuestionMarkCircleIcon className="w-10 h-10 mr-2 text-gray-500"/>
  }
}


const statusBullet = (color: string, big: boolean = false) => {
  const bgColor = color === 'bg-gray-300' ? 'bg-gray-500/10' : `${color}/20`
  return (
    <div className="flex items-center gap-x-1.5 mt-1">
      <div className={`flex-none rounded-full ${bgColor} ${big ? 'p-2' : 'p-1'}`}>
        <div className={`${big ? 'h-3 w-3 ' : 'h-2 w-2'} rounded-full ${color}`}>
        </div>
      </div>
    </div>
  )
}

export const EnvStatusInfo = ({title, link, status}: { title: string, link?: string, status?: HostGlobalStatus }) => {
  const color = returnColorByStatus(status)
  return <div className="flex flex-row gap-2 mt-4 border-t-2 pt-2 items-start">
    {statusBullet(color)}
    <div className="flex flex-col gap-1">
      <span>{title}</span>
      {link ?
        <span className="text-xs">
          <a href={"https://" + link} target="blank">
            {link}
          </a>
        </span>
        : null
      }
    </div>
  </div>
}


export const EtlMetadata = ({intl, app}: { intl: IntlShape, app: AppSchema }) =>
  <div className="mt-2 text-center flex flex-col items-center">
    <span className="font-bold mt-2">Ultima run ETL</span>
    <span className="text-xs mt-1">
    {intl.formatMessage({id: "last_run_status"})}:{" "}
      <span
        className={`font-bold ${
          app.tms_last_etl_status === "OK"
            ? "text-green-500"
            : app.tms_last_etl_status === "UNKNOWN"
              ? "text-yellow-500"
              : ['KO', 'CRITICAL'].includes(app.tms_last_etl_status ?? '')
                ? "text-red-500"
                : ""
        }`}
      >
      {app.tms_last_etl_status ? app.tms_last_etl_status : "N/A"}
    </span>
    </span>
    <span className="text-xs mt-1">
      {intl.formatMessage({id: "last_run_date"})}:{" "}
      <span className="font-bold">
        {app.tms_last_etl_run_date
          ? new Intl.DateTimeFormat("it-IT", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(app.tms_last_etl_run_date))
          : "N/A"}
      </span>
    </span>
  </div>


export const UsersMetadata = ({intl, app}: { intl: IntlShape, app: AppSchema }) =>
  <div className="mt-2 text-center flex flex-col items-center">
    <span className="font-bold mt-2">
      {intl.formatMessage({id: "users"})}
    </span>
    <span className="text-xs mt-1">
      {intl.formatMessage({id: "users_num"})}:{" "}
      <span className="font-bold">
        {app.val_total_registered_users}
      </span>
    </span>
    <span className="text-xs mt-1">
      {intl.formatMessage({id: "last_week_access"})}:{" "}
      <span className="font-bold">
        {app.val_total_last_week_access}
      </span>
    </span>
  </div>


export const ServiceStatusDetail = (
  {title, intl, status, appDetail}: {
    title: string,
    intl: IntlShape,
    status?: HostGlobalStatus,
    appDetail: AppDetailSchema
  }
) => {
  return (
    <div className="mt-2 mb-5 border-t-2 pt-2">
      <div className="flex flex-row gap-2">
        {statusBullet(returnColorByStatus(status), true)}
        <span className="mr-2 mt-1 font-bold">{title} <small className="ml-2">{appDetail.flg_scheduled_downtime
          ? `(${intl.formatMessage({id: "host_in_downtime"})})` : null}</small></span>
      </div>
      <div className="flex flex-grow gap-3 sm:ml-8 mt-1 text-xs">
        <div className="flex flex-row gap-1 items-center">
          {statusBullet(returnColorByStatus(appDetail.cod_frontend_status))}
          <span className="mt-1">Frontend</span>
        </div>
        <div className="flex flex-row gap-1 items-center">
          {statusBullet(returnColorByStatus(appDetail.cod_backend_status))}
          <span className="mt-1">Backend</span>
        </div>
        <div className="flex flex-row gap-1 items-center">
          {statusBullet(returnColorByStatus(appDetail.cod_database_status))}
          <span className="mt-1">Database</span>
        </div>
      </div>
    </div>
  )
}