import React, {useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {tableIcons} from "../../../ui-components/TableIcons";
import TableStyle from "../../../ui-components/TableStyle";
import {OPTIONS, getLocalization, genericOnRowUpdate, genericOnRowAdd, genericOnRowDelete} from "./Params";


function AreasTypesTable({intl, push, data, setData, item}) {

  const [LOCALIZATION] = useState(getLocalization(intl))

  const cod = `cod_${item}`
  const des = `des_${item}`

  const _onRowUpdate = (newData, oldData) =>
    genericOnRowUpdate(newData, oldData, item, cod, setData, data, intl, push)

  const _onRowAdd = (newData) =>
    genericOnRowAdd(newData, item, cod, setData, data, intl, push)

  const _onRowDelete = (oldData) =>
    genericOnRowDelete(oldData, item, cod, setData, data, intl, push)


  return (
    <TableStyle>
      <MaterialTable
        columns={[
          {title: intl.formatMessage({id: "code"}), field: cod, width: "30%"},
          {title: intl.formatMessage({id: "description"}), field: des, width: "70%"}
        ]}
        data={Object.values(data).map((r, i) => ({...r, id: i}))}
        icons={tableIcons}
        title={intl.formatMessage({id: `${item}s`})}
        options={OPTIONS}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowAdd: _onRowAdd,
          onRowDelete: _onRowDelete
        }}
      />
    </TableStyle>
  );
}

export default React.memo(AreasTypesTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.data, nextProps.data);
});
