import React, {useCallback, useContext, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import moment from "moment";
import _ from "lodash";
import Input from "../../Input";
import Modal from "../../Modal";
import Button from "../../Button";
import Select from "../../Select";
import {NotificationsContext} from "../../Notifications";
import Placeholder from "../../Placeholder";
import RadioGroup from "../../RadioGroup";
import { api } from "@services/apiRequest";
import useRoles from "./hooks/useRoles";
import useAlmagammaRoles from "./hooks/useAlmagammaRoles";
import useOrganizationChart from "./hooks/useOrganizationChart";
import DatePicker from "../../DatePicker";

function AddUserDialog({open, toggleOpen, users, setUsers}) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [codGescli, setCodGescli] = useState("");
  const [roleId, setRoleId] = useState("");
  const [datIngresso, setDatIngresso] = useState(new Date());
  const [AlmagammaRoleId, setAlmagammaRoleId] = useState("");
  const [OrgChartId, setOrgChartId] = useState("");
  const [enrichedUsers, setEnrichedUsers] = useState(null);
  const {push} = useContext(NotificationsContext);
  const roles = useRoles();
  const almagammaRoles = useAlmagammaRoles();
  const organizationChart = useOrganizationChart();

  useEffect(() => {
    let _users = users && _.values(users).map((user) => ({...user}));
    _users && organizationChart && almagammaRoles && _users.forEach((user) => {
      if (!user.org_chart && !user.almagamma_role) {
        const orgchart = organizationChart.find((org) => (org.id_user === user.id))
        const role = (orgchart) ? almagammaRoles.find((role) => (role['id_role'] === orgchart.id_user_role)) : undefined
        user.org_chart = (orgchart) ? orgchart : undefined
        user.almagamma_role = (role) ? role : undefined
      }
    })
    setEnrichedUsers(_users);
  }, [users, almagammaRoles, organizationChart])

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!roleId) {
          push({
            title: intl.formatMessage({id: "need_to_select_a_role"}),
            type: "error",
          });
          return;
        }

        if (!email || !name || !codGescli) {
          push({
            title: intl.formatMessage({id: "enter_name_or_email"}),
            type: "error",
          });
          return;
        }

        if (!AlmagammaRoleId) {
          push({
            title: intl.formatMessage({id: "enter_almagamma_role"}),
            type: "error",
          });
          return;
        }

        if (!OrgChartId) {
          push({
            title: intl.formatMessage({id: "enter_almagamma_superior"}),
            type: "error",
          });
          return;
        }

        setLoading(true);
        const { data } = await api.post("/users", {
          email,
          full_name: name,
          role_id: roleId,
          disabled: false
        });

        const { data: org_chart } = await api.post("/roles-almagamma/organization-chart", {
          id_user: data['id'],
          id_user_role: parseInt(AlmagammaRoleId),
          id_chief: parseInt(OrgChartId.substring(0, OrgChartId.indexOf("@"))),
          id_chief_role: parseInt(OrgChartId.substring(OrgChartId.indexOf("@") + 1, OrgChartId.length)),
          cod_gescli: codGescli,
          dat_ingresso: moment(datIngresso).format('YYYY-MM-DD'),
          dat_nascita: '1990-01-01',
          num_ore_giornaliere: 8
        });
        data.org_chart = org_chart;
        data.almagamma_role = almagammaRoles.find((role) => (role['id_role'] === parseInt(AlmagammaRoleId)))

        setUsers({...users, [data['id']]: data});

        toggleOpen();
        push({
          title: intl.formatMessage({id: "user_created_successfully"}),
          type: "success",
        });
      } catch (error) {
        console.log(error)
        push({
          title: intl.formatMessage({id: "server_error"}),
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [email, name, roleId, AlmagammaRoleId, OrgChartId, setUsers, toggleOpen, users, push]
  );
  return (
    <div>
      <Modal opened={open} onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="add_user"/>
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              label={intl.formatMessage({
                id: "name_and_surname",
              })}
              type="text"
              errorMessage={intl.formatMessage({
                id: "enter_name_and_surname",
              })}
              required
            />
          </div>
          <div>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              label="Email"
              type="email"
              errorMessage={intl.formatMessage({
                id: "enter_email",
              })}
              required
            />
          </div>
          <div>
            <Input
              value={codGescli}
              onChange={(e) => {
                setCodGescli(e.target.value);
              }}
              label="Codice Gescli"
              type="text"
              errorMessage="Inserisci il codice gescli"
              required
            />
          </div>
          <div className="mb-6">
            <DatePicker
              value={datIngresso}
              onChange={(i) => setDatIngresso(i)}
              label="Data ingresso"
              errorMessage="Seleziona data"
              minDate={null}
            />
          </div>
          <div className="mb-4">
            {roles ? (
              <div>
                <RadioGroup
                  horizontal
                  title={intl.formatMessage({
                    id: "role",
                  })}
                  options={roles.map((role) => ({
                    value: role.id,
                    name:
                      role.name.charAt(0).toUpperCase() + role.name.slice(1),
                  }))}
                  currentValue={roleId}
                  onChange={(value) => {
                    setRoleId(value);
                  }}
                />
              </div>
            ) : (
              <div>
                <Placeholder width="w-4/5"/>
                <Placeholder width="w-3/5"/>
              </div>
            )}
          </div>
          {almagammaRoles &&
            <div className="AlmagammaRoleId">
              <Select
                options={[{value: undefined, name: "Select Role"}].concat(almagammaRoles.map((role) => ({
                  value: role['id_role'],
                  name: role['role_name'],
                })))}
                onChange={(e) => {
                  setAlmagammaRoleId(e.target.value)
                }}
                value={AlmagammaRoleId}
                label="Ammagamma Role"
                required
              />
            </div>
          }
          {enrichedUsers && users &&
            <div className="OrganizationChartId mt-5">
              <Select
                options={[{value: undefined, name: "Select Superior"}].concat(enrichedUsers.map((user) => ({
                  value: user.org_chart.id_user + "@" + user.org_chart.id_user_role,
                  name:
                    user.full_name + ": " + user.almagamma_role.role_name,
                })))}
                onChange={(e) => {
                  setOrgChartId(e.target.value);
                }}
                value={OrgChartId}
                label="Superior"
                required
              />
            </div>
          }
          <div className="mt-4 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              <FormattedMessage id="add"/>
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddUserDialog;
