import React, {useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components
import AddEvaluationP2P from "../Dialogs/AddEvaluationP2P";
import AddStaffToProject from "../Dialogs/AddStaffToProject";

// UI
import {AdjustmentsVerticalIcon, MinusCircleIcon, WrenchIcon} from "@heroicons/react/24/outline";
import Modal from "../../ui-components/Modal";


// Table
import MaterialTable from "@material-table/core";
import {tableIcons} from "../../ui-components/TableIcons";
import {getColumns, LOCALIZATION, OPTIONS} from "./Params/Staff";

function StaffTable({projStaff, setProjStaff, project, editMode, organizationRoles}) {

  const {push} = useContext(NotificationsContext);
  const [columns] = useState(getColumns(organizationRoles));
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);

  const [rowDataId, setRowDataId] = useState(undefined);
  const [userId, setUserId] = useState(null);

  const [showEval, setShowEval] = useState(false);
  const [modifyStaff, setModifyStaff] = useState(false);

  const removeUserFromData = (id_user) => {
    setProjStaff(projStaff.filter(function (obj) {
      return obj.id !== id_user;
    }))
  }

  const removeUserFromProject = (id_user) => {
    apiRequest
      .delete(`/projects/working_on/${project.id_proj}/${id_user}`)
      .then((result) => {
        if (result) {removeUserFromData(id_user)}
        push({title: "Elemento rimosso correttamente", type: "success"});
      })
      .catch(() => {push({title: "Qualcosa è andato storto", type: "error"})})
  }


  useEffect(() => {
    apiRequest
      .get(`/projects/working_on/${project.id_proj}`)
      .then((result) => {setShowEval(result === true)})
      .catch(() => {push({title: "Qualcosa è andato storto", type: "error"})})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projStaff])

  return (
    <>
      <MaterialTable
        columns={columns}
        data={projStaff}
        icons={tableIcons}
        isLoading={projStaff === undefined}
        options={OPTIONS}
        localization={LOCALIZATION}
        className="items-center"
        actions={[
          {
            icon: () => <WrenchIcon className="h-5 w-5"/>,
            tooltip: 'Edit Staffing',
            onClick: (event, rowData) => {
              setUserId(rowData['id']);
              setModifyStaff(true);
            },
            disabled: !editMode
          },
          {
            icon: () => <MinusCircleIcon className="h-5 w-5"/>,
            tooltip: 'Delete User',
            onClick: (event, rowData) => {
              setRowDataId(rowData['id']);
              setRemoveUserModal(true);
            },
            disabled: !editMode
          },
          {
            icon: () => <AdjustmentsVerticalIcon className="h-5 w-5"/>,
            tooltip: 'Evaluate User (WIP)',
            onClick: (event, rowData) => {
              setUserId(rowData['id']);
              setShowEvaluation(true);
            },
            // disabled: !showEval
            disabled: true // TODO
          }

        ]}
      />
      {userId && showEval && projStaff && showEvaluation && (
        // TODO apply evaluation new style and structure also to P2P
        <AddEvaluationP2P
          open={showEvaluation}
          toggleOpen={() => setShowEvaluation(false)}
          id_proj={project.id_proj}
          id_user={userId}
        />
      )}
      {removeUserModal ? (
        <Modal
          title="Do you really want to remove a user from staff?"
          onExit={() => setRemoveUserModal(false)}
          onDismiss={() => {
          }}
          onConfirm={() => {
            if (rowDataId) {
              removeUserFromProject(rowDataId);
            }
          }}
          confirmText="OK"
          dismissText="ANNULLA"
        />
      ) : null}
      {userId && <AddStaffToProject
        open={modifyStaff}
        toggleOpen={() => setModifyStaff(false)}
        project={project}
        userId={userId}
        setProjStaff={setProjStaff}
        projStaff={projStaff}
        organizationRoles={organizationRoles}
      />}
    </>
  );
}

export default React.memo(StaffTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.projStaff, nextProps.projStaff) && _.isEqual(props.editMode, nextProps.editMode);
});
