import { useState, useEffect } from 'react';
import { api } from '@services/apiRequest';
import {HostSchema} from "@pages/Monitoring/Monitoring.types";


function useHostsWithoutSummary() {
  const [hosts, setHosts] = useState<HostSchema[] | undefined>(undefined);

  useEffect(() => {
    async function _fetchData() {
      const { data } = await api.get<HostSchema[]>('/monitoring/hosts_without_summary')

      setHosts(data);
    }

    _fetchData()

  }, []);

  return hosts;
}

export default useHostsWithoutSummary;