import {ArrowRightIcon} from "@heroicons/react/20/solid";

export const getColumns = (organizationRoles) => [
  {
    title: "Name",
    field: "name",
    type: "string",
    render: rowData => <div className="flex flex-col">
      <div className="font-light italic text-xxs">{organizationRoles[rowData['id']]}</div>
      <div className="font-medium">{rowData['name']}</div>
      <div className="text-gray-400 text-center flex flex-row">
        <div>
          {(new Date(rowData["dat_init"])).toLocaleDateString('it-IT')}
        </div>
        <div>
          <ArrowRightIcon className="w-5 h-5 mx-2"/>
        </div>
        <div>
          {(new Date(rowData["dat_fine"])).toLocaleDateString('it-IT')}
        </div>

      </div>
    </div>

  }
]

export const OPTIONS = {
  filtering: false,
  toolbar: false,
  paging: false,
  sorting: true,
  showTitle: false,
  header: false,
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  actionsColumnIndex: -1
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    addTooltip: "Aggiungi",
    deleteTooltip: "Elimina",
    emptyDataSourceMessage: "Non sono presenti dati da visualizzare"
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  },
  toolbar: {
    searchTooltip: "Cerca",
    searchPlaceholder: "Cerca"
  }
};