import React, {useEffect, useState} from "react";
import {PageSection} from "../../../ui-components/Container";
import RadioGroup from "../../../ui-components/RadioGroup";
import ServiceLollipopChart from "./ServiceLollipopChart";
import CloudTrendBarChart from "./CloudTrendBarChart";
import {useIntl} from "react-intl";
import _ from 'lodash'
import {currencyStyleUsd} from "../../../utils/constants";

export const CloudAnalyticsView = ({monthlyAggregation, organizationalUnits, serviceDetail, accountDetail}) => {

  const currentMonth = new Date(monthlyAggregation[monthlyAggregation.length - 1]['month'])
  const previousMonth = new Date(new Date(currentMonth).setDate(currentMonth.getDate() - 1))
  const [servicePeriod, setServicePeriod] = useState('mtd');
  const [accountKpi, setAccountKpi] = useState([]);
  const [serviceData, setServiceData] = useState();
  const intl = useIntl();

  useEffect(() => {
    if (monthlyAggregation) {

      const currentMonthData = monthlyAggregation[monthlyAggregation.length - 1]
      const currentMonth = new Date(currentMonthData['month'])
      const previousYear = currentMonth.getFullYear() - 1
      const forecast = currentMonthData.amt_billing + currentMonthData.amt_forecast
      const pm_billing = monthlyAggregation[monthlyAggregation.length - 2].amt_billing
      const variation = Math.round((forecast - pm_billing) / pm_billing * 100)

      setAccountKpi([
        {
          title: "Previous Year",
          label: previousYear,
          value: intl.formatNumber((accountDetail || [])
              .filter(i => new Date(i.month).getFullYear() === previousYear)
              .reduce((obj, i) => obj + i.amt_billing, 0),
            currencyStyleUsd)
        },
        {
          title: "Year To Date",
          label: currentMonth.getFullYear(),
          value: intl.formatNumber((accountDetail || [])
              .filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear())
              .reduce((obj, i) => obj + i.amt_billing, 0),
            currencyStyleUsd)
        },
        {
          title: "Month To Date",
          label: new Intl.DateTimeFormat('it', {month: 'short', year: '2-digit'}).format(currentMonth),
          value: intl.formatNumber(currentMonthData.amt_billing, currencyStyleUsd)
        },
        {
          title: "Forecast",
          label: new Intl.DateTimeFormat('it', {month: 'short', year: '2-digit'}).format(currentMonth),
          value: intl.formatNumber(forecast, currencyStyleUsd),
          valueStyle: "opacity-60",
          variation: variation,
          customStyle: variation > 0 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
        }
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyAggregation])

  useEffect(() => {
    let filtered = servicePeriod === 'mtd'
      ? serviceDetail.filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear() && new Date(i.month).getMonth() === currentMonth.getMonth())
      : servicePeriod === 'ytd' ? serviceDetail.filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear())
        : servicePeriod === 'pm' ? serviceDetail.filter(i => new Date(i.month).getFullYear() === previousMonth.getFullYear() && new Date(i.month).getMonth() === previousMonth.getMonth())
          : serviceDetail.filter(i => new Date(i.month).getFullYear() === currentMonth.getFullYear() - 1)
    filtered = _.orderBy(_(filtered).groupBy('service').map((items, service) => {
      return ({
        service: service,
        amt_billing: _.sumBy(items, 'amt_billing')
      })
    })
      .value(), 'amt_billing', 'asc')
    const total = filtered.reduce((acc, i) => acc + i.amt_billing, 0)
    const threshold = total / 100
    let res = [{
      service: 'Altri',
      amt_billing: filtered.filter(i => i.amt_billing < threshold).reduce((acc, i) => acc + i.amt_billing, 0)
    }]
    res = res.concat(filtered.filter(i => i.amt_billing >= threshold))
    res.push()
    setServiceData(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicePeriod])

  return (
    <div>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          {accountKpi.map(kpi => (
            <div key={kpi.title} className="overflow-hidden rounded-lg bg-white px-4 py-3 shadow text-center">
              <div className="flex flex-row justify-between">
                <dt className="truncate text-sm font-medium text-gray-500">{kpi.title}</dt>
                <dt className="truncate text-sm font-medium text-gray-700 capitalize">{kpi.label}</dt>
              </div>
              <div className="flex flex-row justify-between">
                <dd
                  className={`mt-1 text-lg md:text-2xl font-semibold tracking-tight text-am-700 ${kpi.valueStyle}`}>{kpi.value}</dd>
                {kpi.variation &&
                  <span
                    className={`inline-flex items-center px-4 rounded-full font-bold text-xs h-6 mt-2 ${kpi.customStyle}`}>
                    {kpi.variation > 0 ? '+' : ''}{kpi.variation}% PM
                  </span>
                }
              </div>
            </div>
          ))}
        </dl>
      </div>
      <div className="mt-8 flex flex-col lg:flex-row gap-3">
        <div className="full lg:w-2/3">
          <div>
            <PageSection title="Trend spese mensili">
              <CloudTrendBarChart
                id="trend-by-cost"
                data={monthlyAggregation}
                measure='amt'
                organizationalUnits={organizationalUnits}
              />
            </PageSection>
          </div>
          <div className="-mt-10">
            <PageSection title="Numero account">
              <CloudTrendBarChart
                id="trend-by-account"
                data={monthlyAggregation}
                measure='num'
                organizationalUnits={organizationalUnits}
              />
            </PageSection>
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <PageSection title="Servizi AWS">
            <RadioGroup
              id="choose_period"
              title="Periodo"
              horizontal
              options={[
                {value: "mtd", name: "Mtd"},
                {value: "ytd", name: "Ytd"},
                {value: "pm", name: "Pm"},
                {value: "py", name: "PY"},
              ]}
              currentValue={servicePeriod}
              onChange={(value) => {
                setServicePeriod(value)
              }}
            />
            {serviceData &&
              <ServiceLollipopChart
                id="cloud-service-chart"
                data={(serviceData || []).map(i => ({
                  ...i,
                  service: i.service.replace("Amazon", "")
                    .replace("Elastic Container Service", "ECS")
                    .replace(" - Compute", "")
                    .replace("for AWS Compute usage", "for Compute")
                }))}
              />
            }
          </PageSection>
        </div>
      </div>
    </div>
  )
}