import React, {useCallback, useMemo, useState} from "react";

// Services
import {FormattedMessage, useIntl} from "react-intl";

// Ui
import CheckboxGroup from "@ui-components/CheckboxGroup";
import Select from "@ui-components/Select";
import Button from "@ui-components/Button";
import Modal from "@ui-components/Modal";

import AppDetail from "@pages/Monitoring/Overview/AppDetail";

// Types
import {AppSchema, HostGlobalStatus, MonitoringOverviewProps} from "@pages/Monitoring/Monitoring.types";
import {
  CardIconStatus,
  checkboxOptions,
  EnvStatusInfo, EtlMetadata, UsersMetadata,
} from "@pages/Monitoring/Overview/Overview.lib";

export function MonitoringOverview(
  {
    appList,
    customers,
    hostsWithoutSummary
  }: MonitoringOverviewProps) {

  const intl = useIntl();
  const [customerSelected, setCustomerSelected] = useState<string>();
  const [isChecked, setIsChecked] = useState<(HostGlobalStatus)[]>([]);
  const [appData, setAppData] = useState<AppSchema | undefined>(undefined);
  const [showMissing, setShowMissing] = useState(false);

  let statusCount = {
    OK: 0,
    WARNING: 0,
    KO: 0,
  };

  const filteredAppList = customerSelected ?
    appList.filter((app) => app.des_customer_name === customerSelected) :
    appList;

  if (filteredAppList) {
    for (let i = 0; i < filteredAppList.length; i++) {
      let status = filteredAppList[i].cod_app_status_prod;
      if (status === 'OK') {
        statusCount.OK += 1;
      } else if (status === 'WARNING') {
        statusCount.WARNING += 1;
      } else if (
        status === 'CRITICAL' ||
        status === 'UNKNOWN' ||
        !status
      ) {
        statusCount["KO"] += 1;
      }
    }
  }

  const filterByChecks = useCallback(
    (listToFilter: AppSchema[]) => {
      if (isChecked.length === 0) {
        return listToFilter;
      }
      let okList: AppSchema[] = [];
      let warningList: AppSchema[] = [];
      let criticalList: AppSchema[] = [];
      isChecked.forEach((item) => {
        if (item === 'OK') {
          okList = listToFilter.filter(
            (app) => app.cod_app_status_prod === 'OK'
          );
        }
        if (item === 'WARNING') {
          warningList = listToFilter.filter(
            (app) => app.cod_app_status_prod === 'WARNING'
          );
        }
        if (item === 'CRITICAL') {
          criticalList = listToFilter.filter(
            (app) =>
              app.cod_app_status_prod === 'CRITICAL' ||
              app.cod_app_status_prod === 'UNKNOWN' ||
              !app.cod_app_status_prod
          );
        }
      });
      return [...okList, ...warningList, ...criticalList];
    },
    [isChecked]
  );

  const getFilteredList = () => {
    if (!customerSelected) {
      return filterByChecks(appList);
    }

    let filtered = appList.filter(
      (app) => app.des_customer_name === customerSelected
    );
    filtered = filterByChecks(filtered);

    return filtered;
  };

  let filteredList = useMemo(getFilteredList, [
    customerSelected,
    appList,
    filterByChecks,
  ]);


  return (
    <div>
      <div className="flex flex-col items-center gap-y-8 mt-5">
        <div className="w-full flex flex-col items-center gap-y-3 sm:flex-row sm:gap-x-5 sm:justify-around">
          <div className="select-user w-64">
            <div className="font-bold text-lg">
              <FormattedMessage id="customers"/>
            </div>
            {customers && (
              <div className="w-64 sm:w-full">
                {/*@ts-ignore*/}
                <Select
                  label={null}
                  customStyles={null}
                  options={[{name: "", value: ""}].concat(
                    customers.sort((a, b) => a.des_customer.localeCompare(b.des_customer)).map((i) => ({
                      name: i.des_customer.toUpperCase(),
                      value: i.des_customer,
                    }))
                  )}
                  value={customerSelected}
                  onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                    setCustomerSelected(value.target.value);
                  }}
                />
              </div>
            )}
          </div>
          <div className="px-6 py-4 border-2 rounded-3xl">
            <h1 className="text-center mb-2">
              {intl.formatMessage({id: "production_app_list"})}:{" "}
              <span className="font-bold">{filteredAppList && filteredAppList.length}</span>
            </h1>
            <div className="flex flex-row gap-10">
              <CheckboxGroup
                title={null}
                description={null}
                horizontal={true}
                options={checkboxOptions(statusCount)}
                values={isChecked}
                onChange={(value: HostGlobalStatus) => {
                  if (isChecked.indexOf(value) >= 0) {
                    setIsChecked([...isChecked.filter((v) => v !== value)]);
                  } else {
                    setIsChecked([...isChecked, value]);
                  }
                }}
              />
              {/*@ts-ignore*/}
              <Button className="text-xs h-8" styleType="white" onClick={() => setShowMissing(true)}>
                In attesa ({hostsWithoutSummary.length})
              </Button>
              {showMissing ?
                <Modal onExit={() => setShowMissing(false)}>
                  <ul>
                    <div className="font-bold mb-5">Host in attesa di monitoraggio:</div>
                    {hostsWithoutSummary
                      .sort((a, b) => a.des_host.localeCompare(b.des_host))
                      .map(i => <li className="my-1"><span className="mx-1">&#8226;</span>{i.des_host}</li>)}
                  </ul>
                </Modal> : null
              }

            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-3 justify-center mb-5">
          {filteredList &&
            filteredList.map((app) => (
              <div
                key={`${app.des_app_name}-${app.des_customer_name}`}
                className="rounded-3xl border-2 w-[330px] shadow-xl p-3"
              >
                <div className="flex flex-row">
                  <CardIconStatus codStatus={app.cod_app_status_prod}/>
                  <div className="flex flex-col text-sm">
                    <span>
                      {app.des_customer_name.toUpperCase()}
                    </span>
                    <span className="font-bold text-base">
                      {app.des_app_name}
                    </span>
                  </div>
                </div>
                <EnvStatusInfo title="Sviluppo" link={app.des_dev_link} status={app.cod_app_status_dev}/>
                <EnvStatusInfo title="Produzione" link={app.des_prod_link} status={app.cod_app_status_prod}/>
                <div className="grid grid-cols-2 justify-items-center">
                  <EtlMetadata intl={intl} app={app}/>
                  <UsersMetadata intl={intl} app={app}/>
                </div>
                <div className="flex justify-center mt-4 mb-1">
                  {/*@ts-ignore*/}
                  <Button onClick={() => {setAppData(app)}} className="h-8 text-sm" styleType="white">
                    {intl.formatMessage({id: "detail"})}
                  </Button>
                </div>
              </div>
            ))}
          {appData ?
            <Modal
              opened={true}
              onExit={() => setAppData(undefined)}
              maxWidth="sm:max-w-xl"
            >
              <AppDetail appData={appData}/>
            </Modal>
            : null
          }
        </div>
      </div>
    </div>
  );
}
