import React, {useContext, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "@ui-components/Notifications";

import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import Select from "../../ui-components/Select";
import {PlusCircleIcon, TrashIcon} from "@heroicons/react/24/solid";
import {currencyStyleEur} from "@utils/constants";
import Toggle from "../../ui-components/Toggle";
import InputMultiLine from "../InputMultiLine";

const initRow = {month: undefined, amt_importo: undefined, flg_canone: false, num_mesi_ripartizione: undefined}

function ManageRevenueTrancheDialog({toggleOpen, editTranche, entry, setEntry, intl, selectedYear}) {
  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(editTranche.note || '');
  const [flgDone, setFlgDone] = useState(editTranche.flg_done || false);
  const [tranche, setTranche] = useState(editTranche.tranche || {})

  const _year = Number(selectedYear)

  const _onSubmit = () =>
    new Promise((resolve, reject) => {
      let missingData = false
      let totImport = 0
      Object.values(tranche).forEach(t => {
        if (!t.month || !t.amt_importo || (t.flg_canone && !t.num_mesi_ripartizione)) {
          missingData = true
        }
        totImport += t.amt_importo
      })
      if (missingData) {
        push({title: "Dati mancanti", type: "error"});
      } else if (totImport !== editTranche.amt_importo) {
        push({title: "La somma delle tranche è diversa dall'import totale", type: "error"});
        reject();
      } else {
        setLoading(true);
        const cleanedRow = {...editTranche, tranche: tranche, note: note, flg_done: flgDone}
        apiRequest.post(`/economics/entry/${editTranche.id_documento}/${editTranche.id_riga}`, cleanedRow)
          .then(res => {
            const key = `${res.id_documento}_${res.id_riga}`
            setEntry({
              ...entry, [key]: {
                ...res,
                id: `${res.id_documento}_${res.id_riga}`,
                cod_des_commessa: `${res.cod_commessa}_${res.des_commessa}`
              }
            });
            toggleOpen();
            push({title: "Dati salvati correttamente", type: "success"});
            resolve();
          })
          .catch(() => {
            push({title: "Errore imprevisto", type: "error"})
            reject();
          })
          .finally(() => setLoading(false))
      }
    });


  return (
    <div>
      <Modal opened={true} onExit={toggleOpen} maxWidth="sm:max-w-3xl">
        <div className="m-8">
          <h3 className="text-center text-xl font-extrabold text-gray-900 mb-">
            Gestisci tranche di fatturazione
          </h3>
          <div className="text-left text-md text-gray-500 mb-2 mt-5 grid grid-cols-2">
            <div>Commessa: <span className="font-semibold">{editTranche.des_commessa}</span></div>
            <div>Mese ingresso: <span className="font-semibold capitalize">
              {intl.formatDate(editTranche.month, {month: "long", year: "numeric"})}
              </span>
            </div>
            <div>Cliente: <span className="font-semibold">{editTranche.des_cliente}</span></div>
            <div>Data ordine arrivato: <span className="font-semibold capitalize">
              {editTranche.dat_arrivo_ordine ? intl.formatDate(editTranche.dat_arrivo_ordine, {
                day: "numeric",
                month: "long",
                year: "numeric"
              }) : ''}
              </span>
            </div>
            <div>Importo totale: <span
              className="font-semibold">{intl.formatNumber(editTranche.amt_importo, currencyStyleEur)}</span>
            </div>
            <div>Data vinta in Hubspot: <span className="font-semibold capitalize">
              {editTranche.dat_vinta_hubspot ? intl.formatDate(editTranche.dat_vinta_hubspot, {
                day: "numeric",
                month: "long",
                year: "numeric"
              }) : ''}
              </span>
            </div>
          </div>
          <div className="note mt-5 -mb-2">
            <InputMultiLine
              value={note}
              onChange={(e) => {
                setNote(e.target.value)
              }}
              placeholder={intl.formatMessage({id: "notes"})}
            />
          </div>
          <div className="flex flex-row items-center gap-3 justify-center">
            <span className="mt-3 font-bold text-sm">Dati completati</span>
            <div>
              <Toggle
                checked={flgDone}
                onChange={() => setFlgDone(!flgDone)}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <table className="min-w-full divide-y divide-gray-300 mt-5">
              <thead>
              <tr>
                {['mese', 'importo', 'Canone', 'Ripartizione'].map(c => (
                  <th key={c} scope="col" className="capitalize text-gray-900 text-sm">{c}</th>
                ))}
                <th scope="col" className="relative">
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
              {Object.keys(tranche).map((t, index) => <tr key={index}>
                <td>
                  <div className="mr-3">
                    <Select
                      options={[{value: undefined, name: ""}]
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year, i, 1), {month: "short", year: "2-digit"})
                        })))
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year + 1}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year + 1, i, 1), {month: "short", year: "2-digit"})
                        })))
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year + 2}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year + 2, i, 1), {month: "short", year: "2-digit"})
                        })))
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year + 3}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year + 3, i, 1), {month: "short", year: "2-digit"})
                        })))
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year + 4}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year + 4, i, 1), {month: "short", year: "2-digit"})
                        })))
                        .concat([...Array(12).keys()].map(i => ({
                          value: `${_year + 5}-${(Number(i) + 1).toString().padStart(2, '0')}-01`,
                          name: intl.formatDate(new Date(_year + 5, i, 1), {month: "short", year: "2-digit"})
                        })))
                      }
                      value={tranche[t].month}
                      onChange={(e) => setTranche({...tranche, [t]: {...tranche[t], month: e.target.value}})}
                    />
                  </div>
                </td>
                <td>
                  <div className="mt-2 -mb-2 mr-3">
                    <Input
                      value={tranche[t].amt_importo}
                      onChange={(e) => {
                        setTranche({...tranche, [t]: {...tranche[t], amt_importo: parseFloat(e.target.value)}})
                      }}
                      placeholder="Inserisci importo"
                      type="number"
                      numberOptions={{
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="mt-3 w-24">
                    <Toggle
                      checked={tranche[t].flg_canone}
                      onChange={() => setTranche({
                        ...tranche,
                        [t]: {...tranche[t], flg_canone: !tranche[t].flg_canone}
                      })}
                    />
                  </div>
                </td>
                <td>
                  {tranche[t].flg_canone ?
                    <div className="mt-2 -mb-2 mr-3 w-32">
                      <Input
                        value={tranche[t].num_mesi_ripartizione}
                        onChange={(e) => {
                          setTranche({
                            ...tranche,
                            [t]: {...tranche[t], num_mesi_ripartizione: parseFloat(e.target.value)}
                          })
                        }}
                        placeholder="Inserisci # mesi"
                        type="number"
                        numberOptions={{
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }}
                      />
                    </div> : null}
                </td>
                <td>
                  <Button
                    styleType="white"
                    onClick={() => {
                      const {[t]: tmp, ...rest} = tranche;
                      setTranche({...rest});
                    }}
                  >
                    <TrashIcon width="18" height="18"/>
                  </Button>
                </td>
              </tr>)}
              </tbody>
            </table>
            <div className="w-18">
              <Button styleType="secondary"
                      onClick={() => setTranche({...tranche, [Object.keys(tranche).length + 1]: initRow})}>
                <PlusCircleIcon width="18" height="18"/>
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-end justify-end" onClick={_onSubmit}>
          <Button submitting={loading} type="submit">
            Salva
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ManageRevenueTrancheDialog;
