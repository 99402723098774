import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "../../../ui-components/Notifications";
import Placeholder from "../../../ui-components/Placeholder";
import {PageHeading} from "../../../ui-components/Container";
import apiRequest from "../../../services/apiRequest";
import Tabs from "../../../ui-components/Tabs";
import _ from "lodash";

import {CloudAnalyticsView} from "./AnalyticsView";
import {CloudDetailView} from "./DetailView";

function CloudCostAnalysis() {

  const {push} = useContext(NotificationsContext);

  const [data, setData] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [organizationalUnits, setOrganizationalUnits] = useState();
  const [monthlyAggregation, setMonthlyAggregation] = useState();

  const fetchData = () => {
    apiRequest
      .get("/economics/cloud")
      .then((result) => {
        const ous = [...new Set(result['account'].map(i => i.ou_name))].sort().reverse()
        setData(result);
        setOrganizationalUnits(ous);
        setMonthlyAggregation(aggregateDataByMonth(result, ous));
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const aggregateDataByMonth = (_data, _organizationalUnits) => {
    // const res = {}
    let res = []
    _organizationalUnits.forEach(ou => {
      const tmp = _(_data['account'].filter(i => i.ou_name === ou))
        .groupBy('month')
        .map((items, month) => {
          const bill = _.sumBy(items, 'amt_billing')
          const forecast = _.sumBy(items, 'amt_forecast')
          const budget = _.sumBy(items, 'amt_budget')
          return ({
            month: month,
            [`amt_${ou}`]: bill,
            [`budget_${ou}`]: budget,
            [`forecast_${ou}`]: forecast ? forecast - bill : 0,
            [`num_${ou}`]: items.length
          })
        })
        .value()
      res = _.values(_.merge(_.keyBy(res, 'month'), _.keyBy(tmp, 'month')));
    })
    res = res.map(i => ({...i,
      amt_forecast: Object.entries(i).filter(i => i[0].startsWith('for')).reduce((acc, i) => acc + i[1], 0),
      amt_budget: Object.entries(i).filter(i => i[0].startsWith('bud')).reduce((acc, i) => acc + i[1], 0),
      amt_billing: Object.entries(i).filter(i => i[0].startsWith('amt')).reduce((acc, i) => acc + i[1], 0),
      num_accounts: Object.entries(i).filter(i => i[0].startsWith('num')).reduce((acc, i) => acc + i[1], 0),
    }))
    return _.sortBy(res, i => i.month)
  }



  return (
    <div className="flex flex-col ">
      <PageHeading title="Analisi costi cloud"/>
      {monthlyAggregation ?
        <div className="-mt-10">
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={[
              {name: "Analytics"},
              {name: "Dettaglio Account"}
            ]}
          />
          {tabIndex === 0 &&
            <CloudAnalyticsView
              monthlyAggregation={monthlyAggregation}
              organizationalUnits={organizationalUnits}
              serviceDetail={data.service}
              accountDetail={data.account}
            />
          }
          {tabIndex === 1 &&
            <CloudDetailView
              accountDetail={data.account}
              monthlyAggregation={monthlyAggregation}
              organizationalUnits={organizationalUnits}
            />
          }
        </div>
        :
        <div className="flex flex-col w-full mb-4 gap-y-5">
          <Placeholder height="h-20" classNames="rounded-lg"/>
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-60" classNames="rounded-lg"/>
          </div>
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-60" classNames="rounded-lg"/>
          </div>
        </div>
      }
    </div>
  )
}

export default CloudCostAnalysis;