import React, {useCallback, useContext, useState} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";
import {formatDate} from "../../utils";
import _ from "lodash"

import Select from "../../ui-components/Select";
import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import DatePicker from "../../ui-components/DatePicker";
import InputMultiLine from "../InputMultiLine";
import {CheckIcon, XMarkIcon, PlusIcon} from "@heroicons/react/20/solid";

function AddNewProject({open, toggleOpen, intl, dataContext}) {

  const initialState = {
    cod_customer: undefined,
    project_number: null,
    project_name: null,
    des_project: null,
    dat_init: null,
    dat_finish: null
  }
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(initialState);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState({cod_customer: undefined, des_customer: undefined});

  const submitCustomer = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!newCustomer.cod_customer || !newCustomer.des_customer) {
          push({
            title: intl.formatMessage({id: "missing_data"}),
            type: "error",
          });
          return
        }
        setLoadingCustomer(true);
        apiRequest
          .post("/projects/customers", newCustomer)
          .then((res) => {
            dataContext.setCustomerList([...dataContext.customerList, res])
            push({title: intl.formatMessage({id: "customer_added"}), type: "success"});
            setNewCustomer({cod_customer: undefined, des_customer: undefined});
            setProject({...project, cod_customer: res['cod_customer']})
            setAddNewCustomer(false)
          })
          .catch(() => {
            push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
          })

      } catch (error) {
        console.log(error)
        push({title: "Errore imprevisto", type: "error"});
      } finally {
        setLoadingCustomer(false);
      }
    },
    [newCustomer] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!project.cod_customer || !project.project_number || !project.project_name || !project.dat_init || !project.dat_finish) {
          push({
            title: intl.formatMessage({id: "missing_data"}),
            type: "error",
          });
          return
        }

        setLoading(true);
        let data = _.cloneDeep(project);
        data.dat_init = formatDate(data.dat_init)
        data.dat_finish = formatDate(data.dat_finish)

        apiRequest
          .post("/projects", data)
          .then((res) => {
            dataContext.setProjectList([...dataContext.projectList, res])
            toggleOpen();
            push({title: intl.formatMessage({id: "project_added"}), type: "success"});
          })
          .catch(() => {
            push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
          })

      } catch (error) {
        console.log(error)
        push({title: "Errore imprevisto", type: "error"});
      } finally {
        setLoading(false);
      }
    },
    [project] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!open) {
    return null;
  }

  console.log(dataContext.customerList.map(i => ({value: i['cod_customer'], name: i['des_customer']})))

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            {intl.formatMessage({id: "add_project"})}
          </h3>
        </div>
        <form id="add-project-form" onSubmit={_onSubmit}>
          <div className="w-full flex justify-center gap-x-3">
            <div className="w-4/5">
              {addNewCustomer ? (
                <div className="flex gap-x-4">
                  <div className="w-1/3">
                    <Input
                      value={newCustomer.cod_customer}
                      onChange={(e) => {
                        setNewCustomer({...newCustomer, cod_customer: e.target.value})
                      }}
                      label={intl.formatMessage({id: "cod_customer"})}
                      type="number"
                      placeholder="123"
                      errorMessage={intl.formatMessage({id: "add_cod_customer"})}
                      required
                    />
                  </div>
                  <div className="w-2/3">
                    <Input
                      value={newCustomer.des_customer}
                      onChange={(e) => {
                        setNewCustomer({...newCustomer, des_customer: e.target.value})
                      }}
                      label={intl.formatMessage({id: "des_customer"})}
                      type="text"
                      placeholder={intl.formatMessage({id: "des_customer"})}
                      errorMessage={intl.formatMessage({id: "add_des_customer"})}
                      required
                    />
                  </div>
                </div>
              ) : (
                <Select
                  options={[{value: undefined, name: ""}]
                    .concat(dataContext.customerList
                      .map(i => ({value: i['cod_customer'], name: i['des_customer']})))}
                  label={intl.formatMessage({id: "customer"})}
                  value={project.cod_customer}
                  onChange={(e) => {
                    setProject({...project, cod_customer: e.target.value})
                  }}
                />
              )}
            </div>
            <div className="w-1/5 pt-6">
              {addNewCustomer ? (
                <div className="flex flex-row">
                  <Button
                    onClick={submitCustomer}
                    className="pl-2 pr-2 pt-2 pb-2 mr-2"
                    submitting={loadingCustomer}
                  >
                    <CheckIcon className={`w-5 h-5 ${loadingCustomer ? 'text-am-400' : ''}`}/>
                  </Button>
                  <Button
                    styleType="empty"
                    onClick={() => setAddNewCustomer(false)}
                    className="pl-2 pr-2 pt-2 pb-2 bg-gray-200 "
                  >
                    <XMarkIcon className="w-5 h-5"/>
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => setAddNewCustomer(true)}
                  className="pl-2 pr-2 pt-2 pb-2 ml-5"
                >
                  <PlusIcon className="w-5 h-5"/>
                </Button>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center gap-x-10 mt-5">
            <div className="sm:w-1/3">
              <Input
                value={project.project_number}
                onChange={(e) => {
                  setProject({...project, project_number: e.target.value})
                }}
                label={intl.formatMessage({id: "project_number"})}
                type="text"
                placeholder="20XX_YYY"
                errorMessage={intl.formatMessage({id: "add_project_number"})}
                required
              />
            </div>
            <div className="sm:w-2/3">
              <Input
                value={project.project_name}
                onChange={(e) => {
                  setProject({...project, project_name: e.target.value})
                }}
                label={intl.formatMessage({id: "project_name"})}
                type="text"
                placeholder={intl.formatMessage({id: "add_project_name"})}
                errorMessage={intl.formatMessage({id: "add_project_name"})}
                required
              />
            </div>
          </div>
          <div>
            <InputMultiLine
              rows={4}
              value={project.des_project}
              onChange={(e) => {
                setProject({...project, des_project: e.target.value})
              }}
              label={intl.formatMessage({id: "project_description"})}
              type="text"
              placeholder={intl.formatMessage({id: "add_project_description"})}
            />
          </div>
          <div className="flex flex-col sm:flex-row justify-center gap-x-10">
            <div className="w-full sm:w-6/12">
              <DatePicker
                minDate={null}
                value={project.dat_init}
                onChange={(i) => {
                  setProject({...project, dat_init: i})
                }}
                label={intl.formatMessage({id: "date_init"})}
                placeholder={intl.formatMessage({id: "add_dat_init"})}
              />
            </div>
            <div className="w-full sm:w-6/12 ">
              <DatePicker
                minDate={null}
                value={project.dat_finish}
                onChange={(i) => {
                  setProject({...project, dat_finish: i})
                }}
                label={intl.formatMessage({id: "date_finish"})}
                placeholder={intl.formatMessage({id: "add_dat_finish"})}
              />
            </div>
          </div>
          <div className="mt-6 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              {intl.formatMessage({id: "add"})}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddNewProject;
