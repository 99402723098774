import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "../../../ui-components/Notifications";
import apiRequest from "../../../services/apiRequest";
import {useIntl} from "react-intl";
import _ from "lodash";

import Placeholder from "../../../ui-components/Placeholder";
import {PageHeading} from "../../../ui-components/Container";
import GescliTrendChart from "./GescliTrendChart";
import RadioGroup from "../../../ui-components/RadioGroup";
import CheckboxGroup from "../../../ui-components/CheckboxGroup";
import MissingHoursDetail from "./MissingHoursDetail";
import PeriodDetail from "./PeriodDetail";

const CATEGORIES = ['vendute', 'non_vendute', 'innovation', 'formazione', 'non_lavorate']
const dataInit = {
  trend: [],
  current_month: '',
  previous_month: '',
  missing_hours: [],
  period_options: {
    'month': [],
    'quarter': [],
    'year_to_month': [],
    'year': [],
  }
}

function DashboardGescli() {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [data, setData] = useState(dataInit);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [hoTl, setHoTl] = useState(['HO', 'TL']);
  const [measure, setMeasure] = useState('abs');
  const [monthlyAggregation, setMonthlyAggregation] = useState();
  const [missingHours, setMissingHours] = useState({lastMonth: [], currentMonth: []})

  const fetchData = () => {
    apiRequest
      .get("/economics/gescli")
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data.trend.length && data.current_month) {
      const res = _(data.trend.filter(i => i.month === data.current_month))
        .groupBy('cod_team')
        .map((items, t) => {
          return ({
            value: t,
            name: items[0].des_team,
            num_resources: [...new Set(items.map(i => i.cod_risorsa))].length,
            fte: Math.round(_.uniqBy(items, 'cod_risorsa').reduce((acc, i) => acc + i.fte, 0) * 10) / 10
          })
        }).value()
      setTeams(_.orderBy(res, 'num_resources', 'desc'))
      setSelectedTeams(res.map(i => i.value))
    }
  }, [data.trend, data.current_month])

  const aggregateDataByMonth = (_data) => {
    // const res = {}
    let res = []
    CATEGORIES.forEach(c => {
      const tmp = _(_data.filter(i => i.cod_categoria === c))
        .groupBy('month')
        .map((items, month) => {
          return ({
            month: month,
            [`val_${c}`]: _.sumBy(items, 'val_ore')
          })
        })
        .value()
      res = _.values(_.merge(_.keyBy(res, 'month'), _.keyBy(tmp, 'month')));
    })
    res = res.map(i => ({
      ...i,
      val_ore: Object.entries(i).filter(i => i[0].startsWith('val_')).reduce((acc, i) => acc + i[1], 0)
    }))
    return _.sortBy(res, i => i.month)
  }

  useEffect(() => {
    if (data.trend.length) {

      const filterAll = selectedTeams.length === 0 || selectedTeams.length === teams.length
      let _data = filterAll ? data.trend
        : data.trend.filter(i => selectedTeams.includes(i.cod_team))

      let _missing = filterAll ? data.missing_hours
        : data.missing_hours.filter(i => selectedTeams.includes(i.cod_team))

      if (!hoTl.includes('HO')) {
        _data = _data.filter(i => !i.flg_ho)
        _missing = _missing.filter(i => !i.flg_ho)
      }
      if (!hoTl.includes('TL')) {
        _data = _data.filter(i => !i.flg_tl)
        _missing = _missing.filter(i => !i.flg_tl)
      }

      setMonthlyAggregation(aggregateDataByMonth(_data));
      setMissingHours({
        lastMonth: _missing.filter(i => i.dat_riferimento.substring(0, 7) === data.previous_month.substring(0, 7)),
        currentMonth: _missing.filter(i => i.dat_riferimento.substring(0, 7) === data.current_month.substring(0, 7))
      })

    }
  }, [selectedTeams, hoTl]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col ">
      <PageHeading
        title="Analisi ore"
        contentActions={data.current_month
          ? <MissingHoursDetail
            intl={intl}
            data={missingHours}
            currentMonth={data.current_month}
            previousMonth={data.previous_month}
          />
          : null}
      />
      <div className="-mt-5">
        {teams && monthlyAggregation ?
          <div className="flex flex-col items-center">
            <CheckboxGroup
              horizontal
              options={teams}
              values={selectedTeams}
              onChange={(value) => {
                if (selectedTeams.indexOf(value) >= 0) {
                  setSelectedTeams([...selectedTeams.filter((v) => v !== value)]);
                } else {
                  setSelectedTeams([...selectedTeams, value]);
                }
              }}
            />
            <div className="w-full flex flex-col sm:flex-row justify-center mb-4 gap-x-24">
              <div className="mt-4">
                <CheckboxGroup
                  horizontal
                  options={[
                    {value: 'HO', name: 'Includi HO'},
                    {value: 'TL', name: 'Includi TL'}
                  ]}
                  values={hoTl}
                  onChange={(value) => {
                    if (hoTl.indexOf(value) >= 0) {
                      setHoTl([...hoTl.filter((v) => v !== value)]);
                    } else {
                      setHoTl([...hoTl, value]);
                    }
                  }}
                />
              </div>
              <div>
                <RadioGroup
                  id="choose_measure"
                  horizontal
                  options={[
                    {value: "abs", name: "Ore"},
                    {value: "percentage", name: "%"}
                  ]}
                  currentValue={measure}
                  onChange={(value) => {
                    setMeasure(value)
                  }}
                />
              </div>
            </div>
            <GescliTrendChart
              id="gescli-trend-chart"
              data={monthlyAggregation}
              categories={CATEGORIES}
              measure={measure}
            />
            {data.period_options.month.length ?
              <PeriodDetail
                periodOptions={data.period_options}
                selectedTeams={selectedTeams.length === 0 ? teams.map(i => i.value) : selectedTeams}
                hoTl={hoTl}
                intl={intl}
                categories={CATEGORIES}
              /> : null
            }
          </div> :
          <div className="flex flex-col w-full mb-4 gap-y-5">
            <Placeholder height="h-20" classNames="rounded-lg"/>
            <div className="flex flex-row gap-x-5">
              <Placeholder height="h-60" classNames="rounded-lg"/>
            </div>
            <div className="flex flex-row gap-x-5">
              <Placeholder height="h-60" classNames="rounded-lg"/>
            </div>
          </div>
        }
      </div>
    </div>);
}

export default DashboardGescli;