import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense} from "./commons";

function DoubleGaugeChart({id, data}) {

  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      startAngle: 180,
      endAngle: 360,
      radius: am5.percent(90),
      layout: root.verticalLayout
    }));

    // Measurement #1

    // Axis

    let axisRenderer1 = am5radar.AxisRendererCircular.new(root, {
      radius: -10,
      stroke: am5.color("#19475C"),
      strokeOpacity: 1,
      strokeWidth: 6,
      inside: true
    });

    axisRenderer1.grid.template.setAll({
      forceHidden: true
    });

    axisRenderer1.ticks.template.setAll({
      stroke: am5.color("#19475C"),
      visible: true,
      length: 10,
      strokeOpacity: 1,
      inside: true
    });

    axisRenderer1.labels.template.setAll({
      radius: 15,
      inside: true
    });

    let xAxis1 = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: 0,
      max: 150,
      strictMinMax: true,
      renderer: axisRenderer1
    }));

    // Add clock hand
    let axisDataItem1 = xAxis1.makeDataItem({
      value: 0,
      fill: am5.color("#19475C"),
      name: "Accountable Hours"
    });

    let clockHand1 = am5radar.ClockHand.new(root, {
      pinRadius: 14,
      radius: am5.percent(98),
      bottomWidth: 10
    });

    clockHand1.pin.setAll({
      fill: am5.color("#19475C")
    });

    clockHand1.hand.setAll({
      fill: am5.color("#19475C")
    });

    axisDataItem1.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand1
    }));

    xAxis1.createAxisRange(axisDataItem1);

    axisDataItem1.get("grid").set("forceHidden", true);
    axisDataItem1.get("tick").set("forceHidden", true);


    // Measurement #2

    // Axis

    let axisRenderer2 = am5radar.AxisRendererCircular.new(root, {
      //innerRadius: -40,
      stroke: am5.color("#9DA79D"),
      strokeOpacity: 1,
      strokeWidth: 6
    });

    axisRenderer2.grid.template.setAll({
      forceHidden: true
    });

    axisRenderer2.ticks.template.setAll({
      stroke: am5.color("#9DA79D"),
      visible: true,
      length: 10,
      strokeOpacity: 1
    });

    axisRenderer2.labels.template.setAll({
      radius: 15
    });

    let xAxis2 = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: 0,
      max: 150,
      strictMinMax: true,
      renderer: axisRenderer2
    }));

    // Add clock hand
    let axisDataItem2 = xAxis2.makeDataItem({
      value: 0,
      fill: am5.color("#9DA79D"),
      name: "Total Hours"
    });

    let clockHand2 = am5radar.ClockHand.new(root, {
      pinRadius: 10,
      radius: am5.percent(98),
      bottomWidth: 10
    });

    clockHand2.pin.setAll({
      fill: am5.color("#9DA79D")
    });

    clockHand2.hand.setAll({
      fill: am5.color("#9DA79D")
    });

    axisDataItem2.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: clockHand2
    }));

    xAxis2.createAxisRange(axisDataItem2);

    axisDataItem2.get("grid").set("forceHidden", true);
    axisDataItem2.get("tick").set("forceHidden", true);


    // Legend
    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.p50,
      centerX: am5.p50
    }));
    legend.data.setAll([axisDataItem1, axisDataItem2])

    // Animate values
    setInterval(function () {
      let value1 = Math.round(data['accountable_training_hours'] * data['available_training_hours'] / 100);
      axisDataItem1.animate({
        key: "value",
        to: value1,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });

      let value2 = Math.round(data['total_training_hours'] * data['available_training_hours'] / 100);
      axisDataItem2.animate({
        key: "value",
        to: value2,
        duration: 1000,
        easing: am5.ease.out(am5.ease.cubic)
      });

    }, 2000)

    chart.appear(1000, 100);

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minHeight: "300px"}}/>
}

export default React.memo(DoubleGaugeChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})