import React, { useContext, useState } from "react";
import Input from "../../ui-components/Input";
import InputMultiLine from "../InputMultiLine";
import DatePicker from "../../ui-components/DatePicker";
import Button from "../../ui-components/Button";
import { NotificationsContext } from "../../ui-components/Notifications";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import apiRequest from "../../services/apiRequest";
import moment from "moment";
import { useIntl } from "react-intl";
import RadioGroup from "../../ui-components/RadioGroup"


function SubOkrForm({ closeForm, fetchOkrData }) {

  const { push } = useContext(NotificationsContext);
  const intl = useIntl();
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [okrName, setOkrName] = useState();
  const [taskList, setTaskList] = useState([""]);
  const [note, setNote] = useState();
  const [uploading, setUploading] = useState(false);
  const [radio, setRadio] = useState("public");

  const nuovaVersione = () => {
    if (!uploading) {
      setUploading(true)
      let newOkr = {};
      newOkr["okr_name"] = okrName;
      newOkr["dat_start"] = moment(dateStart).format('YYYY-MM-DD');
      newOkr["dat_end"] = moment(dateEnd).format('YYYY-MM-DD');
      newOkr["note"] = note;
      newOkr["visibility"] = radio;


      apiRequest
        .post("/okrs", newOkr)
        .then((result) => {
          const temp = taskList.map((e) => ({ name: e, val_progress: 0 }))
          apiRequest
            .post("okrs/create_task_list/" + result.id_okr, temp)
            .then(() => {
              fetchOkrData();
              closeForm();
              push({ title: intl.formatMessage({ id: "okr_creation_success" }), type: "info" });
            }
            )
        })
    }
  }

  return (
    <div className="sub-okr-form flex flex-col border-x-2 my-4 rounded-lg px-4 pt-1 pb-3 gap-2">
      <div className="new-okr-title flex gap-3 justify-between">
        <div className="TitleTag flex text-2xl font-bold items-center">{intl.formatMessage({ id: "okr_title" })}</div>
        <div className="TitleInput flex w-10/12">
          <div className="flex-col w-full mt-4">
            <Input
              value={okrName}
              onChange={(e) => {
                setOkrName(e.target.value)
              }}
              placeholder={intl.formatMessage({ id: "okr_title_input" })}
              errorMessage={intl.formatMessage({ id: "okr_title_input_error" })}
              required
            />
          </div>
        </div>
      </div>
      <div className="dates flex flex-row justify-evenly -mx-7">
        <div className="date-start flex w-5/12">
          <DatePicker
            minDate={new Date()}
            maxDate={dateEnd}
            value={dateStart}
            onChange={setDateStart}
            label={intl.formatMessage({ id: "start_date_picker" })}
            placeholder={intl.formatMessage({ id: "start_date_picker" })}
            errorMessage={intl.formatMessage({ id: "start_date_picker_error" })}
            required
          />
        </div>
        <div className="date-end w-5/12">
          <DatePicker
            minDate={dateStart}
            value={dateEnd}
            onChange={setDateEnd}
            label={intl.formatMessage({ id: "end_date_picker" })}
            placeholder={intl.formatMessage({ id: "end_date_picker" })}
            errorMessage={intl.formatMessage({ id: "end_date_picker_error" })}
            required
          />
        </div>
      </div>
      <div className="RadioGroupVisibility pt-4">
        <RadioGroup
          id="radioGroup"
          horizontal
          title={intl.formatMessage({ id: "okr_visibility_title" })}
          description={intl.formatMessage({ id: "okr_visibility_message" })}
          options={[
            { value: "public", name: intl.formatMessage({ id: "okr_visibility_public" }) },
            { value: "team", name: intl.formatMessage({ id: "okr_visibility_team" }) },
            { value: "private", name: intl.formatMessage({ id: "okr_visibility_private" }) },
          ]}
          currentValue={radio}
          onChange={(value) => {
            setRadio(value);
          }}
        />
      </div>
      <div className="tasks">
        <div className="TitleTasks flex-col text-2xl font-bold items-center pb-1">{intl.formatMessage({ id: "task_title" })}</div>
        {
          taskList.map((key, index) => {
            return (
              <div key={index} className="Task flex gap-2 justify-between items-center">
                <div className="InputTask flex flex-col w-10/12">
                  <Input
                    placeholder={intl.formatMessage({ id: "task" })}
                    value={taskList[index]}
                    onChange={(e) => {
                      setTaskList([...taskList.slice(0, index), e.target.value, ...taskList.slice(index + 1, taskList.length)])
                    }}
                    errorMessage={intl.formatMessage({ id: "task_error" })}
                    required
                  />
                </div>
                <div className="Button-remove flex flex-col w-1/6 mb-5 pl-2">
                  <Button styleType={"danger"} onClick={
                    () => {
                      setTaskList([...taskList.slice(0, index), ...taskList.slice(index + 1, taskList.length)])
                    }
                  }>
                    <MinusCircleIcon className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            )
          })
        }
        <div className="button-add-task flex flex-col w-24">
          <Button
            styleType={"secondary"}
            onClick={() => {
              setTaskList([...taskList, ""])
            }}>
            <PlusCircleIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <div className="note mt-2">
        <InputMultiLine
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          placeholder={intl.formatMessage({ id: "notes" })}
        />
      </div>
      <div className="button-submit flex justify-end">
        <Button disabled={((taskList || []).filter(i => i !== '').length === 0) || (okrName === undefined || okrName === "") || (dateStart === undefined || dateEnd === undefined)}
        onClick={() => {
          if (dateStart && dateEnd) {
            nuovaVersione();
          } else {
            push({ title: intl.formatMessage({ id: "missing_dates" }), type: "error" });
          }
        }}>{intl.formatMessage({ id: "submit_okr" })}</Button>
      </div>
    </div>
  );
}

export default SubOkrForm;