import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  getXYChart,
  addSeriesBullet,
  addLicense
} from "../../../components/Charts/commons";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";


function BudgetTrendChart({id, data}) {

  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
    root.locale = am5locales_it_IT;
    root.numberFormatter.set("numberFormat", "#,###.$");
    root.dateFormatter.set("dateFormat", "MMM yyyy")

    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    legend.labels.template.setAll({
      fontSize: 10,
      fontWeight: "300"
    });

    // Create axes
    let xAxis = chart.xAxes.push(
      chart.xAxes.push(am5xy.DateAxis.new(root, {
          baseInterval: {timeUnit: 'month', count: 1},
          gridIntervals: [{timeUnit: "month", count: 1}],
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      ))
    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.set("fontSize", 10);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      extraMax: 0.1,
      logarithmic: true,
      renderer: am5xy.AxisRendererY.new(root, {}),
    }))
    let yRenderer = yAxis.get("renderer");
    yRenderer.grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("fontSize", 10);

    yAxis.data.setAll(data);

    // Add series
    function createSeries(field, name) {
      let series = chart.series.push(am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: "month",
        fill: field === 'amt_budget' ? am5.color("rgb(206,96,76)") : am5.color("rgb(0, 60, 80)"),
        stroke: field === 'amt_budget' ? am5.color("rgb(206,96,76)") : am5.color("rgb(0, 60, 80)"),
        strokeWidth: 2,
        strokeDasharray: field === 'amt_budget' && [2, 2],
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{name}[/] \n {valueX.formatDate()}: {valueY}"
        })
      }))

      if (field === 'amt_budget') {
        series.strokes.template.setAll({
          strokeDasharray: [2, 2],
          strokeWidth: 1
        });
      }

      if (field === 'amt_forecast') {
        series.strokes.template.setAll({
          strokeDasharray: [2, 2],
          fillOpacity: 0.5,
          opacity: 0.5,
        });
      }

      // Add bullet
      addSeriesBullet(root, series, field === 'amt_budget' ? 3 : 5, 0, field === 'amt_forecast' ? 0.5 : 1)

      series.data.processor = am5.DataProcessor.new(root, {
        numericFields: [field],
        dateFields: ['month']
      })
      series.data.setAll(data);
      series.appear(1000);

      legend.data.push(series);
    }

    createSeries('amt_budget', 'Budget')
    createSeries('amt_billing', 'Billing')
    createSeries('amt_forecast', 'Forecast')

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} className="w-full h-48"/>
}

export default React.memo(BudgetTrendChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
