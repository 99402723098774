import React, { useState } from "react";
import Node from "./Node";


function Tree({ root, data, depth }) {

  const children = data.filter(i => i['chief_name'] === root['user_name'] && i['chief_role'] === root['user_role'] && i !== root);
  const [active, setActive] = useState(depth > 0);

  if (children.length > 0) {
    const style = root["user_role"].includes("Leader") ? "Children flex flex-col gap-2" : "Children flex gap-2";
    return (
      <div className="Tree flex flex-col gap-2">
        {(root['user_role'] !== 'Admin') &&
          <div className="Parent flex justify-center gap-2"><Node Person={root} onClick={() => setActive(!active)} active={active} /></div>
        }
        {(active === true) &&
          <div className={style}>
            {children.map((child, key) => {
              return <Tree key={key} root={child} data={data} depth={depth - 1} />
            })}
          </div>
        }
      </div >
    )
  } else {
    return (
      <div className="Leaf gap-2"><Node Person={root} /></div>
    );
  }
}

export default Tree;