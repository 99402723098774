import React, { useContext, useState } from "react";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import { NotificationsContext } from "../../ui-components/Notifications";
import { useIntl } from "react-intl";

// Components
import TeamProgress from "./TeamProgress";
import PersonalOkr from "./PersonalOkr";


// UI
import { PageHeading } from "../../ui-components/Container";
import Toggle from "../../ui-components/Toggle"
import Tabs from "../../ui-components/Tabs"
import TeamOkrs from "./TeamOkrs";


function OkrPage() {

  const intl = useIntl();
  const today = new Date();
  const { push } = useContext(NotificationsContext);
  const userData = AuthenticationService.getUserData();

  const [toggleHistory, setToggleHistory] = useState(false);
  const [tabIndex, setTabIndex] = useState(0)


  return (
    <div className="static flex-col">
      <div className="flex flex-row items-center justify-between">
        <PageHeading title={intl.formatMessage({ id: "okrs" })} />
      </div>
      {(tabIndex !== 1) ?
        <div className="float-right -mt-24 px-1 flex-row flex">
          <p className="h-auto font-bold flex mr-2 mt-5">OKR storici</p>
          <Toggle checked={toggleHistory} onChange={() => setToggleHistory(!toggleHistory)} />
        </div>  : null
      }
      <div className="-mt-8">
        <Tabs
          setActiveTab={setTabIndex}
          active={tabIndex}
          tabs={userData.role_name === 'base' ?
            [
              {name: intl.formatMessage({ id: "okrs" })},
              {name: intl.formatMessage({ id: "my_team" })}
            ] :
            [
              {name: intl.formatMessage({ id: "okrs" })},
              {name: intl.formatMessage({ id: "my_team" })},
              {name: intl.formatMessage({ id: "teams_progress" })}
            ]}
        />
        {tabIndex === 0 &&
          <PersonalOkr
            intl={intl}
            push={push}
            today={today}
            userData={userData}
            toggleHistory={toggleHistory}
          />
        }
        {tabIndex === 1 &&
          <TeamOkrs intl={intl} push={push} today={today} />
        }
        {tabIndex === 2 &&
          <TeamProgress intl={intl} push={push} toggleHistory={toggleHistory} />
        }
      </div>
    </div>
  )
}

export default OkrPage;
