import React from "react";


export const COLUMNS = [
    {
        title: "Utente",
        field: "full_name",
        type: "string",
        width: "33%"
    },
    {
        title: "Corso",
        field: "name_course",
        type: "string",
        width: "33%"
    },
    {
        title: "Ore",
        field: "hours",
        type: "string",
        filtering: false,
        width: "33%",
        render: rowData => <p>{rowData["hours"] + ' h'}</p>

    },
]

export const OPTIONS = {
    filtering: true,
    toolbar: true,
    paging: true,
    sorting: true,
    searchFieldStyle: {width: "100%"},
    showTitle: true,
    filterCellStyle: {padding: 12, maxWidth: 200},
    headerStyle: {fontWeight: "bold", fontSize: 15, padding: 15 },
    cellStyle: {padding: 12},
};

export const LOCALIZATION = {
    header: {
        actions: ""
    },
    pagination: {
        labelDisplayedRows: "{from}-{to} di {count} righe",
        labelRowsSelect: "Righe",
    }
};
