import SearchSelect from "@ui-components/SearchSelect";
import Toggle from "@ui-components/Toggle";
import Input from "@ui-components/Input";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import { Fragment, useCallback, useEffect, useState } from "react";
import { currencyStyleEur } from "@utils/constants";
import { arrowSortAsc, arrowSortDesc } from "./ProductivityTable";
import {
  PlusIcon,
  MinusIcon,
  CheckCircleIcon,
  XCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { api } from "@services/apiRequest";

export default function DashBoardMarginality({ data, intl, push }) {
  const [selectedCommessa, setSelectedCommessa] = useState([]);
  const [inputValue, setInputValue] = useState(5000);
  const [tableData, setTableData] = useState(
    data.filter((item) => item.val_ricavi >= 5000)
  );
  const [sortDirection, setSortDirection] = useState({
    Ricavi: "asc",
    Marginalità_Totale: "asc",
    Commessa: "asc",
    Marginalità_Sviluppo: "asc",
    Marginalità_Ricorrente: "asc",
    val_costi_esterni: "asc",
  });
  const [sort, setSort] = useState("");
  const [showDetail, setShowDetail] = useState(
    new Array(data.length).fill(false)
  );
  const [selectedClient, setSelectedClient] = useState([]);
  const [marginalityClients, setMarginalityClients] = useState([]);
  const [marginalityCommesse, setMarginalityCommesse] = useState([]);
  const [clientMarginality, setClientMarginality] = useState([]);
  const [transformedData, setTransformedData] = useState();
  const [statusFilteredData, setStatusFilteredData] = useState();
  const [toggle, setToggle] = useState(false);
  const [marginalityCommesseInCorso, setMarginalityCommesseInCorso] =
    useState();
  const [selectedCommessaStatus, setSelectedCommessaStatus] = useState([
    "on_going",
    "terminated",
  ]);
  const renderArrow = (column) => {
    if (sort === column) {
      return sortDirection[column] === "asc" ? arrowSortAsc() : arrowSortDesc();
    }
    return null;
  };

  const fetchMarginalityClients = () => {
    api
      .get(`/economics/productivity/marginality/clients/${inputValue}`)
      .then(({ data: result }) => {
        setMarginalityClients(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchMarginalityCommesse = () => {
    const payload = {
      val_ricavi: inputValue,
      clients: selectedClient.map((item) => item.value),
    };
    api
      .post(`/economics/productivity/marginality/commesse`, payload)
      .then(({ data: result }) => {
        setMarginalityCommesse(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchMarginalityCommesseInCorso = () => {
    api
      .get("/economics/productivity/marginality/commesse-in-corso")
      .then(({ data: result }) => {
        setMarginalityCommesseInCorso(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  useEffect(() => {
    fetchMarginalityCommesseInCorso();
  }, [selectedCommessaStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  //Filter by client and commessa
  useEffect(() => {
    if (selectedCommessa.length < 1 && selectedClient.length < 1)
      return setTableData(data.filter((item) => item.val_ricavi >= inputValue));

    if (selectedCommessa.length > 0 && selectedClient.length > 0) {
      const commesse = data.filter((item) => {
        const commesse = selectedCommessa.map((commessa) => commessa.value);
        return commesse.includes(item.cod_commessa);
      });
      const clients = data.filter((item) => {
        const clients = selectedClient.map((client) => client.value);
        return clients.includes(item.cod_cliente);
      });
      return setTableData(_.union(commesse, clients));
    }

    if (selectedClient.length > 0) {
      setTableData(
        data.filter((item) => {
          const clients = selectedClient.map((client) => client.value);
          return (
            clients.includes(item.cod_cliente) && item.val_ricavi >= inputValue
          );
        })
      );
    }

    if (selectedCommessa.length > 0) {
      setTableData(
        data.filter((item) => {
          const commesse = selectedCommessa.map((commessa) => commessa.value);
          return commesse.includes(item.cod_commessa);
        })
      );
    }
  }, [selectedCommessa, data, selectedClient, inputValue]);

  useEffect(() => {
    fetchMarginalityClients();
    fetchMarginalityCommesse();
  }, [inputValue, selectedClient]); // eslint-disable-line react-hooks/exhaustive-deps

  //Transform data
  useEffect(() => {
    let tmp = [...tableData];
    let groupedData = _.groupBy(tmp, "cod_commessa");

    const transformedData = _.mapValues(groupedData, (group) => {
      const [first] = group;
      return {
        cod_cliente: first.cod_cliente,
        des_cliente: first.des_cliente,
        cod_commessa: first.cod_commessa,
        des_commessa: first.des_commessa,
        dettaglio_ricavi: _.mapValues(
          _.groupBy(group, "des_conto"),
          (contoGroup) => {
            const [firstConto] = contoGroup;
            return {
              val_ricavi: firstConto.val_ricavi,
            };
          }
        ),
        val_costi_esterni: first.val_costi_esterni,
        dettaglio: _.mapValues(
          _.groupBy(group, "cod_risorsa"),
          (resourceGroup) => {
            const [firstResource] = resourceGroup;
            return {
              des_role: firstResource.des_role,
              role_cost: firstResource.role_cost,
              tipo_prestazione: _.mapValues(
                _.groupBy(resourceGroup, "cod_tipo_prestazione"),
                (prestazioneGroup) => {
                  const [firstPrestazione] = prestazioneGroup;
                  return {
                    des_tipo_prestazione: firstPrestazione.des_tipo_prestazione,
                    val_ore: firstPrestazione.val_ore,
                  };
                }
              ),
            };
          }
        ),
      };
    });

    setTransformedData(transformedData);
  }, [tableData]);

  //filter by commessa status
  const filterByCommessaStatus = useCallback(() => {
    if (
      transformedData === undefined ||
      marginalityCommesseInCorso === undefined
    )
      return;
    if (
      selectedCommessaStatus.length === 0 ||
      selectedCommessaStatus.length === 2
    ) {
      setStatusFilteredData(transformedData);
      return;
    }

    let filteredData = _.values(transformedData);

    selectedCommessaStatus.forEach((status) => {
      if (status === "on_going")
        filteredData = filteredData.filter((item) =>
          marginalityCommesseInCorso
            .map((item) => item.cod_commessa)
            .includes(item.cod_commessa)
        );
      if (status === "terminated")
        filteredData = filteredData.filter(
          (item) =>
            !marginalityCommesseInCorso
              .map((item) => item.cod_commessa)
              .includes(item.cod_commessa)
        );
    });

    setStatusFilteredData(_.keyBy(filteredData, "cod_commessa"));
  }, [selectedCommessaStatus, transformedData, marginalityCommesseInCorso]);

  useEffect(() => {
    filterByCommessaStatus();
  }, [
    selectedCommessaStatus,
    transformedData,
    marginalityCommesseInCorso,
    filterByCommessaStatus,
  ]);

  //Sort table
  const sortTable = (data, column) => {
    let sortedData = data;
    let direction = sortDirection[column] === "asc" ? "desc" : "asc";
    setSortDirection({ ...sortDirection, [column]: direction });
    switch (column) {
      case "Commessa":
        sortedData =
          direction === "asc"
            ? _.orderBy(data, ["cod_commessa"], ["asc"])
            : _.orderBy(data, ["cod_commessa"], ["desc"]);
        break;
      case "Ricavi":
        const calcRicavi = (item) => {
          return Object.keys(item.dettaglio_ricavi).reduce(
            (acc, conto) => acc + item.dettaglio_ricavi[conto].val_ricavi,
            0
          );
        };
        sortedData =
          direction === "asc"
            ? _.orderBy(data, [calcRicavi], ["asc"])
            : _.orderBy(data, [calcRicavi], ["desc"]);
        break;
      case "Marginalità_Totale":
        const calcMarginality = (item) => {
          const val_ricavi = Object.keys(item.dettaglio_ricavi).reduce(
            (acc, conto) => acc + item.dettaglio_ricavi[conto].val_ricavi,
            0
          );
          return (
            (val_ricavi + item.val_costi_esterni - cost(item.dettaglio)) /
            val_ricavi
          );
        };
        sortedData =
          direction === "asc"
            ? _.orderBy(data, [calcMarginality], ["asc"])
            : _.orderBy(data, [calcMarginality], ["desc"]);
        break;
      case "Marginalità_Sviluppo":
        const calcMarginalitySviluppo = (item) => {
          const val_ricavi = Object.keys(item.dettaglio_ricavi)
            .filter((conto) => conto === "Prestazioni di servizi")
            .map((conto) => item.dettaglio_ricavi[conto].val_ricavi)
            .pop();

          let result = (val_ricavi - costSviluppo(item.dettaglio)) / val_ricavi;
          result = isNaN(result) ? 0 : result;
          return result;
        };
        sortedData =
          direction === "asc"
            ? _.orderBy(data, [calcMarginalitySviluppo], ["asc"])
            : _.orderBy(data, [calcMarginalitySviluppo], ["desc"]);
        break;
      case "Marginalità_Ricorrente":
        const calcMarginalityRicorrente = (item) => {
          const val_ricavi = Object.keys(item.dettaglio_ricavi)
            .filter((conto) => conto !== "Prestazioni di servizi")
            .map((conto) => item.dettaglio_ricavi[conto].val_ricavi)
            .pop();

          let result =
            (val_ricavi - costRicorrente(item.dettaglio)) / val_ricavi;
          result = isNaN(result) ? 0 : result;
          console.log(result);
          return result;
        };
        sortedData =
          direction === "asc"
            ? _.orderBy(data, [calcMarginalityRicorrente], ["asc"])
            : _.orderBy(data, [calcMarginalityRicorrente], ["desc"]);
        break;
      case "val_costi_esterni":
        sortedData =
          direction === "asc"
            ? _.orderBy(
                data,
                [(item) => Math.abs(item.val_costi_esterni)],
                ["asc"]
              )
            : _.orderBy(
                data,
                [(item) => Math.abs(item.val_costi_esterni)],
                ["desc"]
              );
        break;
      default:
        break;
    }
    setTransformedData(sortedData);
  };

  //Marginality functions
  const cost = (item) => {
    let cost = Object.keys(item).reduce((acc, risorsa) => {
      let val_ore = Object.keys(item[risorsa].tipo_prestazione).reduce(
        (acc, prestazione) =>
          acc + item[risorsa].tipo_prestazione[prestazione].val_ore,
        0
      );
      return acc + (item[risorsa].role_cost * val_ore) / 8;
    }, 0);
    return cost;
  };

  const costSviluppo = (item) => {
    let cost = Object.keys(item).reduce((acc, risorsa) => {
      let val_ore = Object.keys(item[risorsa].tipo_prestazione)
        .filter((tipo) => tipo === "VA.0002")
        .map((tipo) => item[risorsa].tipo_prestazione[tipo].val_ore)
        .pop();
      val_ore = val_ore ? val_ore : 0;
      return acc + (item[risorsa].role_cost * val_ore) / 8;
    }, 0);
    cost = isNaN(cost) ? 0 : cost;
    return cost;
  };

  const costRicorrente = (item) => {
    let cost = Object.keys(item).reduce((acc, risorsa) => {
      let val_ore = Object.keys(item[risorsa].tipo_prestazione)
        .filter((tipo) => tipo === "VA.0003")
        .map((tipo) => item[risorsa].tipo_prestazione[tipo].val_ore)
        .pop();
      val_ore = val_ore ? val_ore : 0;
      return acc + (item[risorsa].role_cost * val_ore) / 8;
    }, 0);
    cost = isNaN(cost) ? 0 : cost;
    return cost;
  };

  const formatMarginality = (item) => {
    const val_ricavi = Object.keys(item.dettaglio_ricavi).reduce(
      (acc, conto) => acc + item.dettaglio_ricavi[conto].val_ricavi,
      0
    );
    const val_costi_esterni = item.val_costi_esterni
      ? item.val_costi_esterni
      : 0;

    return Intl.NumberFormat("it-IT", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(
      (val_ricavi + val_costi_esterni - cost(item.dettaglio)) / val_ricavi // i costi esterni sono negativi nel db
    );
  };

  const formatMarginalitySviluppo = (item) => {
    const val_ricavi = Object.keys(item.dettaglio_ricavi)
      .filter((conto) => conto === "Prestazioni di servizi")
      .map((conto) => item.dettaglio_ricavi[conto].val_ricavi)
      .pop();
    let result = (val_ricavi - costSviluppo(item.dettaglio)) / val_ricavi;
    result = isNaN(result) ? 0 : result;
    return Intl.NumberFormat("it-IT", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result);
  };

  const formatMarginalityRicorrente = (item) => {
    const val_ricavi = Object.keys(item.dettaglio_ricavi)
      .filter((conto) => conto !== "Prestazioni di servizi")
      .map((conto) => item.dettaglio_ricavi[conto].val_ricavi)
      .pop();

    let result = (val_ricavi - costRicorrente(item.dettaglio)) / val_ricavi;
    result = isNaN(result) ? 0 : result;
    return Intl.NumberFormat("it-IT", {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result);
  };

  const calcClientMarginality = useCallback(
    (client) => {
      const clientData = Object.keys(transformedData)
        .map((item) => transformedData[item])
        .filter((item) => item.des_cliente === client);
      let totRicavi = 0;
      let totCostiEsterni = 0;
      let totCostoRisorse = 0;
      clientData.forEach((item) => {
        totRicavi += Object.keys(item.dettaglio_ricavi).reduce(
          (acc, conto) => acc + item.dettaglio_ricavi[conto].val_ricavi,
          0
        );
        totCostiEsterni += item.val_costi_esterni;
        totCostoRisorse += cost(item.dettaglio);
      });
      const marginality =
        (totRicavi + totCostiEsterni - totCostoRisorse) / totRicavi;
      return { client, totRicavi, totCostiEsterni, marginality };
    },
    [transformedData]
  );

  useEffect(() => {
    if (selectedClient.length > 0) {
      const calculatedMarginalities = selectedClient.map((client) =>
        calcClientMarginality(client.label)
      );
      setClientMarginality(calculatedMarginalities);
    } else setClientMarginality([]);
  }, [selectedClient, calcClientMarginality]);

  //Detail tables
  const renderRisorseDetail = (item) =>
    Object.keys(statusFilteredData[item].dettaglio).map((risorsa) => (
      <tr key={risorsa} className="odd:bg-white even:bg-gray-200">
        <td></td>
        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {risorsa.split(".").join(" ")}
        </td>
        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {statusFilteredData[item].dettaglio[risorsa].des_role}
        </td>

        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          Ore:{" "}
          {intl.formatNumber(
            Object.keys(
              statusFilteredData[item].dettaglio[risorsa].tipo_prestazione
            ).reduce(
              (acc, prestazione) =>
                acc +
                statusFilteredData[item].dettaglio[risorsa].tipo_prestazione[
                  prestazione
                ].val_ore,
              0
            )
          )}{" "}
          h
        </td>
        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          Costo:{" "}
          {intl.formatNumber(
            statusFilteredData[item].dettaglio[risorsa].role_cost,
            currencyStyleEur
          )}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    ));

  const renderRicaviDetail = (item) =>
    Object.keys(statusFilteredData[item].dettaglio_ricavi).map((des_conto) => (
      <tr key={des_conto} className="odd:bg-white even:bg-gray-200">
        <td></td>
        <td></td>
        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {des_conto}
        </td>
        <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {intl.formatNumber(
            statusFilteredData[item].dettaglio_ricavi[des_conto].val_ricavi,
            currencyStyleEur
          )}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    ));

  const renderDetail = (item) => {
    return toggle ? renderRicaviDetail(item) : renderRisorseDetail(item);
  };

  return (
    <div className="flex flex-col mt-8 sm:mt-2">
      <div className="mb-1">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:gap-x-4 -mb-2">
          <div className="flex items-center gap-x-2 mx-auto sm:mx-0">
            <p className="text-base">Filtra Cliente</p>
            <SearchSelect
              options={marginalityClients.map((item) => ({
                value: item.cod_cliente,
                label: item.des_cliente,
              }))}
              value={selectedClient}
              onChange={(value) => setSelectedClient(value)}
              isMulti
            />
          </div>
          <div className="flex items-center gap-x-2 mx-auto sm:mx-0">
            <p className="text-base">Filtra Commessa</p>
            <SearchSelect
              options={marginalityCommesse.map((item) => ({
                value: item.cod_commessa,
                label: item.cod_commessa + " - " + item.des_commessa,
              }))}
              value={selectedCommessa}
              onChange={(value) => setSelectedCommessa(value)}
              isMulti
            />
          </div>
          <div className="flex items-center gap-x-2 mx-auto sm:mx-0 xl:ml-8">
            <p className="text-base">Filtra Ricavi</p>
            <div className="mt-4">
              <Input
                value={inputValue}
                onChange={(e) => {
                  const value = e.target.value === "" ? 0 : e.target.value;
                  setInputValue(value);
                }}
                style={{ width: "100px", height: "30px" }}
                type="number"
                onClear={() => setInputValue(0)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5 mt-2">
        <div className="w-full border-t border-gray-300" />
      </div>

      <div className="flex justify-between items-center align-middle">
        <div className="flex flex-row items-center text-sm -mt-7">
          <span className="mr-2 mt-2">
            Visualizza dettaglio {toggle ? "Ore" : "Ricavi"}
          </span>
          <Toggle checked={toggle} onChange={() => setToggle(!toggle)} />
        </div>

        {clientMarginality.length > 0 && (
          <div className="grid grid-cols-2 gap-x-1 -mt-3 mb-3">
            {clientMarginality.map((item) => (
              <div
                key={item.client}
                className="flex flex-col w-64  border-2 rounded-lg items-start px-5 py-2 shadow-md justify-center my-3"
              >
                <p className="text-base font-bold">{item.client}</p>
                <p className="text-sm">
                  Ricavi: {intl.formatNumber(item.totRicavi, currencyStyleEur)}
                </p>
                <p className="text-sm">
                  Costi Esterni:{" "}
                  {intl.formatNumber(
                    Math.abs(item.totCostiEsterni),
                    currencyStyleEur
                  )}
                </p>
                <p className="text-sm">
                  Marginalità:{" "}
                  {intl.formatNumber(item.marginality, {
                    style: "percent",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-col items-center border-2 rounded-lg shadow-md justify-center mb-3 pr-7 pl-3 -mt-1">
          <p className="text-sm font-semibold pl-3">Filtra Stato Commessa</p>
          <CheckboxGroup
            options={[
              {
                name: "In Corso",
                value: "on_going",
              },
              {
                name: "Terminate",
                value: "terminated",
              },
            ]}
            values={selectedCommessaStatus}
            onChange={(value) => {
              if (selectedCommessaStatus.indexOf(value) >= 0) {
                setSelectedCommessaStatus([
                  ...selectedCommessaStatus.filter((item) => item !== value),
                ]);
              } else {
                setSelectedCommessaStatus([...selectedCommessaStatus, value]);
              }
            }}
            horizontal
          />
        </div>
      </div>
      <div>
      <table className="divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {statusFilteredData && (
            <tr className="font-bold bg-cyan-900">
              <th></th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white"
              >
                CLIENTE
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("Commessa");
                  sortTable(statusFilteredData, "Commessa");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  COMMESSA {renderArrow("Commessa")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("Ricavi");
                  sortTable(statusFilteredData, "Ricavi");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  RICAVI {renderArrow("Ricavi")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("val_costi_esterni");
                  sortTable(statusFilteredData, "val_costi_esterni");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  COSTI ESTERNI {renderArrow("val_costi_esterni")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("Marginalità_Sviluppo");
                  sortTable(statusFilteredData, "Marginalità_Sviluppo");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  MARGINALITÀ DI SVILUPPO {renderArrow("Marginalità_Sviluppo")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("Marginalità_Ricorrente");
                  sortTable(statusFilteredData, "Marginalità_Ricorrente");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  MARGINALITÀ RICORRENTE {renderArrow("Marginalità_Ricorrente")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left leading-4  text-white cursor-pointer"
                onClick={() => {
                  setSort("Marginalità_Totale");
                  sortTable(statusFilteredData, "Marginalità_Totale");
                }}
              >
                <div className="flex flex-row items-center">
                  {" "}
                  MARGINALITÀ TOTALE {renderArrow("Marginalità_Totale")}
                </div>
              </th>
            </tr>
          )}
        </thead>
        <tbody>
          {statusFilteredData &&
            Object.keys(statusFilteredData).map((item, i) => (
              <Fragment key={i}>
                <tr
                  key={item}
                  className="odd:bg-white even:bg-gray-200 font-bold"
                >
                  <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                    <button
                      onClick={() =>
                        setShowDetail({ ...showDetail, [i]: !showDetail[i] })
                      }
                    >
                      {showDetail[i] ? (
                        <MinusIcon
                          className="h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      ) : (
                        <PlusIcon
                          className="h-5 w-5 text-black"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {statusFilteredData[item].des_cliente}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {statusFilteredData[item].cod_commessa +
                      " - " +
                      statusFilteredData[item].des_commessa}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {intl.formatNumber(
                      Object.keys(
                        statusFilteredData[item].dettaglio_ricavi
                      ).reduce(
                        (acc, conto) =>
                          acc +
                          statusFilteredData[item].dettaglio_ricavi[conto]
                            .val_ricavi,
                        0
                      ),
                      currencyStyleEur
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {intl.formatNumber(
                      Math.abs(statusFilteredData[item].val_costi_esterni),
                      currencyStyleEur
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {formatMarginalitySviluppo(statusFilteredData[item])}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {formatMarginalityRicorrente(statusFilteredData[item])}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                    {parseFloat(formatMarginality(statusFilteredData[item])) >
                    75 ? (
                      <div className="flex items-center justify-between">
                        {formatMarginality(statusFilteredData[item])}
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-500 ml-1"
                          aria-hidden="true"
                        />
                      </div>
                    ) : parseFloat(
                        formatMarginality(statusFilteredData[item])
                      ) < 65 ? (
                      <div className="flex items-center justify-between">
                        {formatMarginality(statusFilteredData[item])}
                        <XCircleIcon
                          className="h-6 w-6 text-red-500 ml-1"
                          aria-hidden="true"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-between">
                        {formatMarginality(statusFilteredData[item])}
                        <MinusCircleIcon
                          className="h-6 w-6 text-yellow-500 ml-1"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                  </td>
                </tr>
                {showDetail[i] && renderDetail(item)}
              </Fragment>
            ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}
