import React, {useEffect, useState, useContext} from "react";


import {NotificationsContext} from "../../../ui-components/Notifications";
import apiRequest from "../../../services/apiRequest";
import {useIntl} from "react-intl";


import Placeholder from "../../../ui-components/Placeholder";
import {PageHeading} from "../../../ui-components/Container";
import Tabs from "../../../ui-components/Tabs";
import {RevenueTotalView} from "./TotalView";
import Select from "../../../ui-components/Select";
import {RevenueDetailView} from "./DetailView";
import {DataEntryView} from "./DataEntry";


function DashboardRevenue() {

  const intl = useIntl();
  const {push} = useContext(NotificationsContext);

  const [tabIndex, setTabIndex] = useState(0);

  const currentYear = (new Date()).getFullYear()
  const currentMonth = (new Date()).getMonth()
  const [selectedYear, setSelectedYear] = useState(currentYear)

  // Total view radio button
  const [selectedView, setSelectedView] = useState('absolute');
  const [extraEsolver, setExtraEsolver] = useState(true);

  // Detail view filter
  const [selectedMark, setSelectedMark] = useState(['Prestazioni di servizi', 'Delta (Prestazioni)']);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState([]);

  const [data, setData] = useState({total: [], total_py: [], detail: [], entry: []});
  const [pmList, setPmList] = useState([]);
  const [rischiAnticipi, setRischiAnticipi] = useState();

  const fetchData = () => {
    apiRequest
      .get(`/economics/revenue/${selectedYear}`)
      .then((result) => {
        setPmList([...new Set(result.detail.map(i => i.cod_pm))].sort());
        setData(result);
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
  }

  const fetchRischiAnticipi = () => {
    apiRequest.get("/economics/rischi-anticipi")
      .then((res) => {
        setRischiAnticipi(res.reduce((acc, i) => {
          if (!acc[i.cod_commessa]) {acc[i.cod_commessa] = {}}
          if (!acc[i.cod_commessa][i.des_conto]) {acc[i.cod_commessa][i.des_conto] = {}}
          acc[i.cod_commessa][i.des_conto][i.month] = i.status
          return acc
        }, {}))
      })
      .catch(() => push({title: "Errore del server", type: "error"}))
  }

  useEffect(() => {
    fetchRischiAnticipi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedYear) {
      setData({total: [], total_py: [], detail: [], entry: []});
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])


  return (
    <div className="flex flex-col ">
      <PageHeading
        title="Dashboard Ricavi"
        contentActions={
          <Select
            options={[currentYear - 1, currentYear, currentYear + 1].map(i => ({value: i, name: i}))}
            value={selectedYear}
            onChange={(e) => {setSelectedYear(e.target.value)}}
            label="Anno"
          />
      }
      />
      {data.total.length > 0 ?
        <div className="-mt-10">
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={[
              {name: "Vista aggregata"},
              {name: "Dettaglio Commesse"},
              {name: "Gestione delta e ricorrenti", notifications: data.entry.filter(i => i.status === 'ESOLVER' && !i.num_mesi_ripartizione).length}
            ]}
          />
          {tabIndex === 0 &&
            <RevenueTotalView
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              extraEsolver={extraEsolver}
              setExtraEsolver={setExtraEsolver}
              currentYear={currentYear}
              currentMonth={currentMonth}
              selectedYear={selectedYear}
              totalData={data.total}
              pyData={data.total_py}
              intl={intl}
            />
          }
          {tabIndex === 1 &&
            <RevenueDetailView
              intl={intl}
              data={data.detail}
              selectedYear={selectedYear}
              currentMonth={currentMonth}
              pmList={pmList}
              selectedMark={selectedMark}
              setSelectedMark={setSelectedMark}
              selectedCustomers={selectedCustomers}
              setSelectedCustomers={setSelectedCustomers}
              selectedDeal={selectedDeal}
              setSelectedDeal={setSelectedDeal}
              rischiAnticipi={rischiAnticipi}
              fetchRischiAnticipi={fetchRischiAnticipi}
            />
          }
          {tabIndex === 2 &&
            <DataEntryView
              push={push}
              intl={intl}
              data={data}
              pmList={pmList}
              setData={setData}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          }
        </div>
        :
        <div className="flex flex-col w-full mb-4 gap-y-5">
          <Placeholder height="h-12" classNames="rounded-lg"/>
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-80" classNames="rounded-lg"/>
          </div>
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-80" classNames="rounded-lg"/>
          </div>
        </div>
      }
    </div>);
}

export default DashboardRevenue;