import React, {useContext, useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "../../ui-components/Notifications";

import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import {currencyStyleEur} from "../../utils/constants";

function EditDealStatusDialog({toggleOpen, intl, editStatus, selectedYear, rischiAnticipi, fetchRischiAnticipi}) {

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const [detail, m] = editStatus;
  const month = `${selectedYear}-${(m + 1).toString().padStart(2, '0')}-01`
  const [selectedStatus, setSelectedStatus] = useState(((rischiAnticipi[detail.cod_commessa] || {})[detail.des_conto] || {})[month] || 'REGOLARE');
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (
      (selectedStatus === 'REGOLARE' && !((rischiAnticipi[detail.cod_commessa] || {})[detail.des_conto] || {})[month]) ||
      ((rischiAnticipi[detail.cod_commessa] || {})[detail.des_conto] || {})[month] === selectedStatus
    ) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus])

  const _onSubmit = () =>
    new Promise((resolve, reject) => {
      setLoading(true);
      if (selectedStatus === 'REGOLARE') {
        apiRequest.delete(`/economics/rischi-anticipi/${detail.cod_commessa}/${detail.des_conto}/${month}`)
          .then(() => {
            fetchRischiAnticipi()
            push({title: "Dati salvati correttamente", type: "success"});
            resolve();
            toggleOpen();
          })
          .catch(() => {
            push({title: "Errore imprevisto", type: "error"})
            reject();
          })
          .finally(() => setLoading(false))
      } else {
        const dealStatus = {
          cod_commessa: detail.cod_commessa,
          des_conto: detail.des_conto,
          month: month,
          status: selectedStatus
        }
        apiRequest.post(`/economics/rischi-anticipi`, dealStatus)
          .then(() => {
            fetchRischiAnticipi()
            toggleOpen();
            push({title: "Dati salvati correttamente", type: "success"});
            resolve();
          })
          .catch(() => {
            push({title: "Errore imprevisto", type: "error"})
            reject();
          })
          .finally(() => setLoading(false))
      }
    });

  const basicStyle = 'h-20 flex items-center justify-center border rounded-xl font-bold text-sm cursor-pointer'

  const getBorder = (status) => {
    return status === selectedStatus ? 'border-am-700 border-2' : ''
  }

  return (
    <div>
      <Modal opened={true} onExit={toggleOpen} maxWidth="sm:max-w-xl">
        <div className="m-8">
          <h3 className="text-center text-lg font-extrabold text-gray-900 mb-5">
            Vuoi taggare questa tranche di fatturazione come rischio o anticipo?
          </h3>
          <div className="flex flex-col">
            <p><b>Cliente: </b> {detail.des_cliente}</p>
            <p><b>Commessa: </b> {detail.cod_commessa} - {detail.des_commessa}</p>
            <p><b>Tipologia: </b> {detail.des_conto}</p>
            <p><b>Mese: </b> {intl.formatDate(new Date(selectedYear, m, 1), {month: "short", year: "2-digit"})}</p>
            <p><b>Importo: </b> {intl.formatNumber(detail['month_detail'][m], currencyStyleEur)}</p>
          </div>
          <div className="mt-5 grid grid-cols-3 gap-3">
            <div
              className={`${basicStyle} text-green-600 ${getBorder('ANTICIPO')}`}
              onClick={() => setSelectedStatus('ANTICIPO')}
            >
              Anticipo
            </div>
            <div
              className={`${basicStyle} text-gray-600 ${getBorder('REGOLARE')}`}
              onClick={() => setSelectedStatus('REGOLARE')}
            >
              <p className="text-center">Fatturazione regolare</p>
            </div>
            <div
              className={`${basicStyle} text-red-600 ${getBorder('RISCHIO')}`}
              onClick={() => setSelectedStatus('RISCHIO')}
            >
              Rischio
            </div>
          </div>
        </div>
        <div className="mr-8 flex items-end justify-end" onClick={_onSubmit}>
          <Button submitting={loading} type="submit" disabled={isDisable}>
            Salva
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default EditDealStatusDialog;
