import apiRequest from "../../../services/apiRequest";

export const OPTIONS = {
  filtering: false,
  toolbar: true,
  paging: false,
  sorting: true,
  search: false,
  headerStyle: {fontWeight: "bold", fontSize: 15, padding: 12},
  filterCellStyle: {padding: 12},
  cellStyle: {padding: 12},
}

export const getLocalization = (intl) => ({
  header: {actions: ""},
  body: {
    addTooltip: intl.formatMessage({id: "add"}),
    editTooltip: intl.formatMessage({id: "edit"}),
    deleteTooltip: intl.formatMessage({id: "delete"}),
    editRow: {
      deleteText: intl.formatMessage({id: "delete_element"}),
      saveTooltip: intl.formatMessage({id: "save"}),
      cancelTooltip: intl.formatMessage({id: "cancel"}),
    },
    emptyDataSourceMessage: intl.formatMessage({id: "missing_data"})
  },
})


export const genericOnRowUpdate = (newData, oldData, item, cod, setData, data, intl, push) =>
  new Promise((resolve, reject) => {
    let isEqual = true;
    Object.entries(newData).forEach(([key, value]) => {
      if (oldData[key] !== value) {
        isEqual = false;
      }
    });
    if (isEqual) {
      resolve();
    } else {
      const itemPath = item !== 'skill' ? `/${item}` : ''
      apiRequest
        .post(`/skills${itemPath}/${oldData[cod]}`, newData)
        .then((result) => {
          setData({...data, [result[cod]]: result});
          push({title: intl.formatMessage({id: "item_edited"}), type: "success"});
          resolve();
        })
        .catch(() => {
          push({title: intl.formatMessage({id: "server_error"}), type: "error"});
          reject();
        });
    }
  });

export const  genericOnRowAdd = (newData, item, cod, setData, data, intl, push) =>
  new Promise((resolve, reject) => {
    const itemPath = item !== 'skill' ? `/${item}` : ''
    const payload = item !== 'skill' ? newData : {id_topic: newData.topic.id_topic, des_skill: newData.des_skill}
    apiRequest
      .post(`/skills${itemPath}`, payload)
      .then((result) => {
        setData({...data, [result[cod]]: result});
        push({title: intl.formatMessage({id: "item_added"}), type: "success"});
        resolve();
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "server_error"}), type: "error"});
        reject();
      });
  });

export const genericOnRowDelete = (oldData, item, cod, setData, data, intl, push) =>
  new Promise((resolve, reject) => {
    const itemPath = item !== 'skill' ? `/${item}` : ''
    apiRequest
      .delete(`/skills${itemPath}/${oldData[cod]}`)
      .then(() => {
        const {[oldData[cod]]: tmp, ...rest} = data;
        setData({...rest});
        push({title: intl.formatMessage({id: "item_deleted"}), type: "success"});
        resolve();
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "server_error"}), type: "error"});
        reject();
      });
  });