import React, { useEffect, useState } from "react";

// Services
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components
import { PageSection } from "../../ui-components/Container";
import RadarChart from "../../components/Charts/RadarChart";
import DoubleGaugeChart from "@components/Charts/DoubleGaugeChart";

// Ui
import { AdjustmentsVerticalIcon, AcademicCapIcon } from "@heroicons/react/24/outline"
import Breadcrumb from "../../ui-components/Breadcrumb";
import Placeholder from "@ui-components/Placeholder";


function PersonalHome({intl, push, dataContext, userData}) {

  const [evaluatedSkills, setEvaluatedSkills] = useState();
  const [level, setLevel] = useState(0);
  const [graphData, setGraphData] = useState();
  const [expertData, setExpertData] = useState();
  const [trainingDetail, setTrainingDetail] = useState();

  const [breadcrumbItems, setBreadcrumbItems] = useState([{
    title: <AdjustmentsVerticalIcon className="h-5 w-5" />,
    level: 0,
    onClick: () => {
      setLevel(0)
      setBreadcrumbItems(breadcrumbItems.filter(i => i.level < 1))
    }
  }]);

  const fetchMySkills = () => {
    apiRequest
      .get("/evaluation-suite/tree")
      .then((result) => {
        setEvaluatedSkills(result);
      })
      .catch(() => {
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
      })
  }

  const fetchMyTrainingHours = () => {
    apiRequest
      .post("/course-suite/training-hours", { id_user: userData.id, year: new Date().getFullYear() })
      .then((result) => {
        setTrainingDetail(result);
      })
      .catch(() => {
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
      })
  }


  useEffect(() => {
    fetchMySkills();
    fetchMyTrainingHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (evaluatedSkills) {
      let area = null
      let type = null
      let topic = null
      if (breadcrumbItems[1]) {
        area = breadcrumbItems[1]['title'];
      }
      if (breadcrumbItems[2]) {
        type = breadcrumbItems[2]['title'];
      }
      if (breadcrumbItems[3]) {
        topic = breadcrumbItems[3]['title'];
      }
      area ? apiRequest.post(
        "/evaluation-suite/max_expert/",
        {
          area: area,
          type: type,
          topic: topic,
        }
      ).then((result) => {setExpertData(result)}) : setExpertData(null)

      setGraphData(
        () => {
          let filtered = _.cloneDeep(evaluatedSkills || []);
          let level = 'des_area';
          if (breadcrumbItems[1]) {
            filtered = filtered.filter(skill => skill.des_area === breadcrumbItems[1]['title']);
            level = 'des_type';
          }
          if (breadcrumbItems[2]) {
            filtered = filtered.filter(skill => skill.des_type === breadcrumbItems[2]['title']);
            level = 'des_topic';
          }
          if (breadcrumbItems[3]) {
            filtered = filtered.filter(skill => skill.des_topic === breadcrumbItems[3]['title']);
            level = 'des_skill';
          }

          return Object.entries(filtered.reduce(
            (accumulator, currVal) => {
              if (!accumulator[currVal[level]]) {
                accumulator[currVal[level]] = {
                  'superior': {
                    'sum': 0,
                    'count': 0
                  },
                  'self': {
                    'sum': 0,
                    'count': 0
                  }
                };
              }
              accumulator[currVal[level]][currVal['evaluator']]['sum'] += currVal.id_grade;
              accumulator[currVal[level]][currVal['evaluator']]['count'] += 1;
              return accumulator;
            }, {}
          )).map(([key, value]) => {
            return {
              'level': key,
              'id_grade': (value['self']['count'] > 0) ? value['self']['sum'] / value['self']['count'] : null,
              'avg_superior': (value['superior']['count'] > 0) ? value['superior']['sum'] / value['superior']['count'] : null,
            }
          });
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluatedSkills, level])




  return (
    <div className="PersonalHomeContainer mt-10">
      <div className="flex justify-between flex-col sm:flex-row">
        <div className="flex flex-col sm:w-4/6">
          <PageSection title={intl.formatMessage({ id: "my_skills" })}>
            {(graphData && ((graphData || []).length > 0)) ? (
              <div>
                <div >
                  {breadcrumbItems.length > 1 ? (
                    <Breadcrumb items={breadcrumbItems} classNames="flex-col sm:flex-row " />
                  ) : (
                    <p className="italic text-gray-400">{intl.formatMessage({ id: "click_to_drill_down" })}</p>
                  )}
                </div>
                <RadarChart
                  id="Radar Home"
                  data={graphData}
                  useLegend
                  setter={(e) => {
                    if (e && breadcrumbItems.length < 4) {
                      const newLevel = level + 1
                      const oldItems = breadcrumbItems.map(i => ({
                        ...i, onClick: () => {
                          setBreadcrumbItems(breadcrumbItems.filter(x => x.level <= i.level))
                          setLevel(level)
                        }
                      }))
                      setBreadcrumbItems([...oldItems, { title: e, level: newLevel }])
                      setLevel(newLevel);
                    }
                  }}
                />
              </div>
            ) : (
              <div className="Placeholder flex opacity-40 mt-10 sm:mb-20">
                <AdjustmentsVerticalIcon className="h-16 w-16" />
                <div className="PlaceholderText flex font-bold m-5">
                  <p>
                    {intl.formatMessage({ id: "no_evaluations_message" })} <a href={"/eval"}>Evaluation!</a>
                  </p>
                </div>
              </div>
            )
            }
          </PageSection>
        </div>
        <div className="Sidebar flex flex-col sm:w-2/6 sm:pl-10">
          <PageSection title={intl.formatMessage({ id: "area_experts" })}>
            {expertData && (expertData || []).length ?
              <ul className="m-4 list-disc list-outside">
                {
                  (expertData.slice(0, 5) || []).map((ed) =>
                  <li key={ed['id']} className="my-4">
                    <span className="text-bold">{ed['full_name']} </span>
                    <span className="text-xs italic">{ed['expertise']}</span>
                    </li>
                  )
                }
              </ul> :
              <div className="Placeholder flex opacity-40 mt-10">
                <AcademicCapIcon className="h-16 w-16" />
                <div className="PlaceholderText flex font-bold m-5">
                  {intl.formatMessage({ id: "select_area" })}
                </div>
              </div>
            }
          </PageSection>
          <PageSection title={intl.formatMessage({ id: "training_hours"})}>
            {trainingDetail ? (
            <>
              <DoubleGaugeChart
                id="Gauge Home"
                data={trainingDetail}
              />
              <p className="text-center">{intl.formatMessage({ id: "available_hours" })}: {Math.max(Math.round(trainingDetail['available_training_hours'] - trainingDetail['total_training_hours']), 0)} </p>
            </>
            ) : (
              <Placeholder classNames="h-240px"/>
            )
            }
          </PageSection>
        </div>
      </div>
    </div>
  );
}

export default PersonalHome;
