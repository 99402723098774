import React, {useContext, useEffect, useState} from "react";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext/DataContext";
import apiRequest from "../../services/apiRequest";
import {useIntl} from "react-intl";
import _ from "lodash";

// Components
import ProjectTable from "../../components/Tables/ProjectTable";
import ProjectDetail from "./ProjectDetail";

// UI
import Placeholder from "../../ui-components/Placeholder";
import {PageHeading} from "../../ui-components/Container";
import Tabs from "../../ui-components/Tabs";
import Toggle from "../../ui-components/Toggle";
import Button from "../../ui-components/Button";
import AddNewProject from "../../components/Dialogs/AddNewProject";
import useAlmagammaRoles from "../../ui-components/pages/Users/hooks/useAlmagammaRoles";
import useOrganizationChart from "../../ui-components/pages/Users/hooks/useOrganizationChart";


function Project() {

  const intl = useIntl();
  const userData = AuthenticationService.getUserData();
  const scopes = AuthenticationService.getUserScopes();
  const almagammaRoles = useAlmagammaRoles();
  const organizationChart = useOrganizationChart();
  const [tabIndex, setTabIndex] = useState(0);
  const [showOldProjects, setShowOldProjects] = useState(false);
  const [showAddProject, setShowAddProject] = useState(false);
  const [tableData, setTableData] = useState();

  const [selectedProject, setSelectedProject] = useState(null);
  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    if (dataContext.projectList && dataContext.myProjects) {
      let tmp = showOldProjects
        ? _.cloneDeep(dataContext.projectList)
        : dataContext.projectList.filter(i => new Date(i['dat_finish']) >= new Date())

      if (tabIndex === 1) {
        setTableData(tmp)
      } else {
        const myProjects = showOldProjects
          ? dataContext.myProjects
          : dataContext.myProjects.filter(i => new Date(i['dat_fine']) >= new Date())
        const myIds = myProjects.map(i => i['id_proj'])
        setTableData(tmp.filter(i => myIds.includes(i['id_proj'])))
      }
    }
  }, [dataContext.projectList, dataContext.myProjects, showOldProjects, tabIndex])


  const fetchAllCustomers = () => {
    apiRequest
      .get("/projects/customers")
      .then((result) => {dataContext.setCustomerList(result)})
      .catch(() => {push({title: intl.formatMessage({id: "error_loading"}), type: "error"})})
  }

  const fetchAllProjects = () => {
    apiRequest
      .get("/projects")
      .then((result) => {dataContext.setProjectList(result)})
      .catch(() => {push({title: intl.formatMessage({id: "error_loading"}), type: "error"})})
  }

  const fetchAllUsers = () => {
    apiRequest
      .get("/users")
      .then((result) => {dataContext.setUserList(result)})
      .catch(() => {push({title: intl.formatMessage({id: "error_loading"}), type: "error"})})
  }

  const fetchAllSkill = () => {
    apiRequest
      .get("/skills")
      .then((result) => {dataContext.setSkillList(result)})
      .catch(() => {push({title: intl.formatMessage({id: "error_loading"}), type: "error"})})
  }

  const fetchMyProjects = () => {
    apiRequest
      .get("/projects/working_on/list_projects_by_user/" + userData["id"])
      .then((result) => {dataContext.setMyProjects(result)})
      .catch(() => {push({title: intl.formatMessage({id: "error_loading"}), type: "error"})})
  }

  useEffect(() => {
    if (!dataContext.customerList) {
      fetchAllCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.customerList])

  useEffect(() => {
    if (!dataContext.projectList) {
      fetchAllProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.projectList])

  useEffect(() => {
    if (!dataContext.userList) {
      fetchAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.userList])

  useEffect(() => {
    if (!dataContext.skillList) {
      fetchAllSkill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.skillList])

  useEffect(() => {
    if (!dataContext.myProjects) {
      fetchMyProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.myProjects])

  return (
    <div className="pb-10">
      {!selectedProject && (<>
        <PageHeading title="Progetti (WIP)"/>
        <div className="flex flex-col sm:flex-row justify-center -mt-8 mb-5 gap-x-24">
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={[
              {name: intl.formatMessage({id: "my_projects"})},
              {name: intl.formatMessage({id: "all_projects"})}
            ]}
          />
          <div className="sm:w-1/4">
            <Toggle
              label={intl.formatMessage({id: "show_past_projects"})}
              checked={showOldProjects}
              onChange={() => setShowOldProjects(!showOldProjects)}
            />
          </div>
          {scopes.includes('project:create') && dataContext.customerList &&
            <div className="flex items-center justify-end">
              <Button
                onClick={() => setShowAddProject(true)}
              >
                {intl.formatMessage({id: "add_project"})}
              </Button>
            </div>
          }
        </div>
        <div className="flex flex-col w-full">
          <div>
            {tableData ?
              <ProjectTable
                data={tableData}
                customers={dataContext.customerList}
                setSelectedProject={setSelectedProject}
              /> :
              <div>
                <Placeholder width="w-full" height="h-64"/>
                <Placeholder width="w-full" height="h-64"/>
              </div>
            }
          </div>
        </div>
      </>)}
      {selectedProject &&
        <ProjectDetail
          project={selectedProject}
          scopes={scopes}
          setSelectedProject={setSelectedProject}
          dataContext={dataContext}
          almagammaRoles={almagammaRoles}
          organizationChart={organizationChart}
        />
      }
      {showAddProject &&
        <AddNewProject
          open={true}
          toggleOpen={() => setShowAddProject(false)}
          intl={intl}
          dataContext={dataContext}
        />
      }
    </div>
  );
}

export default Project;