import {CardListItemType} from "@components/CardList/CardList.type";
import {AppSchema} from "@pages/Monitoring/Monitoring.types";
import {
  CalendarIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon, PhoneIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import {ArrowRightIcon} from "@heroicons/react/24/solid";
import {FormatNumberOptions, IntlShape} from "react-intl";
import {currencyStyleEur} from "@utils/constants";
import React from "react";


export const getListItems = (
  ccApps: AppSchema[] | undefined,
  orderBy: 'id' | 'scadenza' | 'ultimo_contatto',
  intl: IntlShape,
  setAddEditApp: React.Dispatch<React.SetStateAction<AppSchema | undefined>>
): CardListItemType[] | [] => {

  const getDate = (date: string | undefined) => {
    if (!date) {
      return '-'
    }
    return intl.formatDate(new Date(date), {day: "numeric", month: "short", year: "2-digit"})
  }

  const getFlgIcon = (flg: boolean | undefined) => {
    return flg
      ? <CheckCircleIcon className="w-4 text-green-600"/>
      : flg === false
        ? <XCircleIcon className="w-4 text-red-700"/>
        : <QuestionMarkCircleIcon className="w-4 text-gray-600"/>
  }
  if (!ccApps || ccApps.length === 0) {
    return []
  }

  let sorted

  if (orderBy === 'id') {
      sorted = ccApps.sort((a, b) => b.id - a.id)
    } else {
      sorted = ccApps.sort((a, b) => (a[orderBy] || 'z').localeCompare(b[orderBy] || 'z'))
    }

  return (
    sorted.map((app: AppSchema, index): CardListItemType => {

      return ({
        rowId: `spp-${index}`,
        rowOnClick: () => setAddEditApp(app),
        rowElement: <>
          <dt className="uppercase text-gray-900 flex flex-row items-center gap-2 justify-between sm:col-span-2">
            <div className="flex flex-col whitespace-nowrap text-sm">
              <span className="text-am-700">{app.des_customer_name}</span>
              <span className="font-bold text-am-700">{app.des_app_name}</span>
              <div className="flex flex-row items-center gap-1 text-xs">
                <span className="text-gray-900">{app.cod_commessa_madre || 'c. madre'}</span>
                <ArrowRightIcon className="w-3"/>
                <span className="font-bold">{app.cod_commessa_attuale || 'c. attuale'}</span>
              </div>
              <span>{app.company_size || '-'}</span>
            </div>
          </dt>
          <dd className="mt-2 sm:mt-0 flex flex-col justify-between text-sm sm:col-span-2">
            <span className="text-gray-900">{app.categoria || '-'}</span>
            <span className="text-gray-900">
              Stato: <i>{app.stato_caring || '-'}</i>
            </span>
            <span className="text-gray-900">
              PY: {intl.formatNumber(app.fatturato_py || 0, currencyStyleEur as FormatNumberOptions)}
            </span>
            <span className="font-bold">
              Canone: {intl.formatNumber(app.canone_attuale || 0, currencyStyleEur as FormatNumberOptions)}
            </span>
          </dd>
          <dd className="mt-2 sm:mt-0 flex flex-col text-xs sm:col-span-2 justify-between">
            <span className="flex flex-row gap-1 text-sm italic items-center font-bold">
              <CalendarIcon className="w-4 -mt-0.5"/>{getDate(app.scadenza)}
            </span>
            <span className="flex flex-row gap-1 text-sm italic items-center">
              <small>HS</small> {getDate(app.scadenza_hs)}
            </span>
            <span className="flex flex-row gap-1 text-sm italic items-center">
              <PhoneIcon className="w-3.5 -mt-0.5"/>{getDate(app.ultimo_contatto)}
            </span>
            <span>Deal: {app.deal || '-'}</span>
          </dd>
          <dd className="mt-2 sm:mt-0 flex flex-col whitespace-nowrap text-xs uppercase sm:col-span-2 justify-between">
            <span className="font-bold flex flex-row items-center gap-1 text-sm">
              <UserIcon className="w-4"/>{app.owner_customer_care ? app.owner_customer_care.split('.')[1] : 'TBD'}
            </span>
            <span>PM: {app.pm ? app.pm.split('.')[1] : '-'}</span>
            <span>TO1: {app.ticket_1 ? app.ticket_1.split('.')[1] : '-'}</span>
            <span>TO2: {app.ticket_2 ? app.ticket_2.split('.')[1] : '-'}</span>
          </dd>
          <dd className="mt-2 sm:mt-0 flex flex-row sm:flex-col text-xs justify-between">
            {app.des_prod_link ?
              <span className="text-xs normal-case">
                  <a href={"https://" + app.des_prod_link} target="blank">Link</a>
                </span>
              : null
            }
            <div className="flex flex-row justify-between gap-1">
              <span>T.APP:</span>
              {getFlgIcon(app.flg_template_app)}
            </div>
            <div className="flex flex-row justify-between gap-1">
              <span>Ticket:</span>
              {getFlgIcon(app.flg_ticketing)}
            </div>
            <div className="flex flex-row justify-between gap-1">
              <span>HS:</span>
              {getFlgIcon(app.flg_aggiornato_hubspot)}
            </div>
          </dd>
          {app.note ?
            <dd className="mt-2 sm:mt-0 flex flex-col justify-between text-sm sm:col-span-9">
              <div className="flex flex-row gap-1 text-xs italic items-center w-full">
                <ClipboardDocumentIcon className="w-3"/>
                <span className="text-ellipsis overflow-hidden w-full whitespace-nowrap">{app.note}</span>
              </div>
            </dd> : null
          }
        </>
      })
    })
  )
}