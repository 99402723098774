import React, {useState} from "react";
import {useIntl} from "react-intl";
import Label from "../../ui-components/Label";
import {CalendarIcon, ExclamationCircleIcon} from "@heroicons/react/24/solid";


function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validator = (type, value) => {
  switch (type) {
    case "email":
      return validateEmail(value);

    default:
      return true;
  }
};


const InputMultiLine = ({
                          value,
                          label,
                          name,
                          type = "text",
                          validation,
                          required,
                          errorMessage,
                          showCalendar,
                          numberOptions = {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          },
                          ...rest
                        }) => {
  const [valid, setValid] = useState(true);
  const [focus, setFocus] = useState(false);
  const intl = useIntl();
  let inputValue = value || "";

  if (inputValue && type === "number" && !focus) {
    inputValue = intl.formatNumber(inputValue, numberOptions);
  }

  return (
    <div className="mb-1">
      {label ? <Label>{label}</Label> : null}
      <div className="mt-1 relative rounded-md shadow-sm">
        {typeof showCalendar !== "undefined" ? (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <CalendarIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        ) : null}
        <textarea
          onFocus={() => {
            if (type === "number") {
              setFocus(true);
            }
            setValid(true);
          }}
          onBlur={(e) => {
            if (type === "number") {
              setFocus(false);
            }
            if (required) {
              if (!e.target.value) {
                setValid(false);
                return;
              }

              if (validation) {
                setValid(validation(e.target.value));
                return;
              }

              setValid(validator(type, e.target.value));
            }
          }}
          type={type === "number" ? (focus ? type : "text") : type}
          name={type}
          className={`px-2 py-2 pr-10 border shadow-sm focus:ring-am-500 focus:border-am-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50 ${
            typeof showCalendar !== "undefined" ? "pl-10" : ""
          }`}
          value={inputValue}
          readOnly={type === "number" && !focus}

          {...rest}
        />
        {!valid ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {showCalendar ? null : !valid && errorMessage ? (
        <p className="mt-1 text-xs text-red-600" id="email-error">
          {errorMessage}
        </p>
      ) : (
        <div className="mt-2 h-2"/>
      )}
    </div>
  );
};

export default InputMultiLine;