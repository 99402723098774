import React, {useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components
import SubOkrForm from "../../components/Okr/SubOkrForm";
import Okr from "../../components/Okr/Okr";

// UI
import SearchSelect from "../../ui-components/SearchSelect";
import {PageSection} from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";
import Button from "../../ui-components/Button";
import Modal from "../../ui-components/Modal";


function compareDates(a, b) {
  const dateA = new Date(a.dat_end);
  const dateB = new Date(b.dat_end);

  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;
  return 0;
}



function PersonalOkr({userData, push, intl, today, toggleHistory}) {

  const [userSelected, setUserSelected] = useState();
  const [listPeople, setListPeople] = useState([]);
  const [createOkr, setCreateOkr] = useState(false);
  const [okr, setOkr] = useState({current: undefined, historical: undefined});

  useEffect(() => {
    setUserSelected({label: userData.full_name, value: userData.id});
    fetchAllEmployeesName();
    fetchOkrData(userData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAllEmployeesName = () => {
    apiRequest
      .get("/users/list_employees/username")
      .then((result) => {
        setListPeople(result)
      })
  }

  const fetchOkrData = (value) => {
    apiRequest
      .get(`/okrs/employee_okrs/${value}`)
      .then((result) => {
        const okr = (result || []).map((x) => {
          const dat_end = new Date(x.dat_end);
          return ({...x, flg_current: dat_end >= today})
        })
        setOkr({
          current: okr.filter(i => i.flg_current),
          historical: okr.filter(i => !i.flg_current),
        });
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }

  return (
    <div className="mb-10">
      <div className="pt-3 gap-5 flex flex-column justify-between items-center">
        <div className="select-user">
          <SearchSelect
            options={_.sortBy([...new Set(Object.values(listPeople))].map(i => ({
              label: i.name,
              value: i.id_user
            })), i => i.label)}
            value={userSelected}
            onChange={(value) => {
              setOkr({current: undefined, historical: undefined});
              fetchOkrData(value.value);
              setUserSelected(value);
            }}
          />
        </div>
        <div className="create-okr-button justify-between">
          {userSelected && userData.id === userSelected.value &&
            <Button onClick={() => {
              setCreateOkr(!createOkr)
            }}>
              {intl.formatMessage({id: "create_new_okr"})}
            </Button>
          }
        </div>
      </div>
      <div>
        {okr.current ?
          <div className="pt-3 flex flex-col w-full gap-5">
            {(okr.current || []).length ?
              (okr.current || []).map((e, i) => {
                return (
                  <div key={i}>
                    <Okr
                      okr={e}
                      renderTasks={true}
                      renderProgress={true}
                      currentUserId={userData.id}
                      fetchOkrData={() => fetchOkrData(userData.id)}
                    />
                  </div>
                )
              }) :
              <div className="PlaceholderText flex font-bold m-5">
                {intl.formatMessage({id: "no_okr"})}
              </div>
            }
          </div>
          : <div className="mt-10 flex flex-col gap-5">
            <Placeholder width="w-full" height="h-40"/>
            <Placeholder width="w-full" height="h-40"/>
            <Placeholder width="w-full" height="h-40"/>
          </div>
        }
        {okr.historical && toggleHistory && (okr.historical || []).length ?
          <>
            <PageSection title={<b className="text-xl">{intl.formatMessage({id: "historic_okr"})}</b>}>
              <div className="pt-3 flex flex-col w-full gap-5">
                {(okr.historical.sort(compareDates) || []).map((e, i) => {
                  return (
                    <div key={i}>
                      <Okr
                        okr={e}
                        renderTasks={true}
                        renderProgress={true}
                        currentUserId={userData.id}
                        fetchOkrData={() => fetchOkrData(userData.id)}
                      />
                    </div>
                  )
                })
                }
              </div>
            </PageSection>
          </> : null
        }
      </div>
      {createOkr ?
        <Modal
          opened={createOkr}
          onExit={() => {
            setCreateOkr(!createOkr)
          }}>
          <SubOkrForm closeForm={setCreateOkr} fetchOkrData={() => fetchOkrData(userData.id)}/>
        </Modal> : null
      }
    </div>
  )
}

export default PersonalOkr;
