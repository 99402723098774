import React from "react";
import PointRating from "../../PointRating";


export function getColumns(type) {

  const typeList = type && [...new Set(type.map(i => i['id_type']))].sort().reduce((o, key) => ({
    ...o,
    [key]: key
  }), {})
  return [
    {
      title: "Codice",
      field: 'id_course',
      width: "5%"
    },
    {
      title: "Nome Corso",
      field: "name_course",
      type: "string",
      width: "45%",
      render: rowData => <a href={"/course/".concat("", rowData["id_course"])}>{rowData["name_course"]}</a>
    },
    {
      title: "Durata",
      field: "val_duration",
      width: "10%",
      render: rowData => <p>{rowData["val_duration"] + ' h'}</p>
    },
    {
      title: "Tipo",
      field: "id_type",
      type: "string",
      width: "15%",
      lookup: typeList
    },
    {
      title: "Stato",
      field: "flg_active_course",
      type: "boolean",
      width: "10%",
    },
    {
      title: "Valutazione",
      field: "eval",
      width: "15%",
      filtering: false,
      render: rowData => <PointRating evaluation={rowData["eval"]}/>
    }
  ]
}

export const OPTIONS = {
  filtering: true,
  toolbar: true,
  pageSize: 10,
  sorting: true,
  searchFieldStyle: {width: "100%"},
  showTitle: false,
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {paddingBottom: 5},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} of {count} rows",
    labelRowsSelect: "Rows",
  }
};
