import React, {useCallback, useEffect, useState} from "react";

// Services
import {compactSessionData, getInitSessionData} from "./utils";
import apiRequest from "../../services/apiRequest";
import _ from "lodash"

// UI
import RadioGroupBasic from "../../ui-components/RadioGroup";
import Button from "../../ui-components/Button";
import Modal from "../../ui-components/Modal";
import {RadioGroup} from '@headlessui/react'
import Tabs from "../../ui-components/Tabs";
import {
  ArrowPathIcon,
  ChartBarIcon,
  CommandLineIcon,
  ComputerDesktopIcon,
  InformationCircleIcon,
  UserCircleIcon
} from '@heroicons/react/24/outline'
import RadarChart from "../../components/Charts/RadarChart";

const gradesOptions = [-1, 0, 1, 2, 3, 4, 5]
const gradesNames = gradesOptions.reduce((obj, i) => _.set(obj, i, i === -1 ? 'na' : i), {})

const icons = {
  'CS': CommandLineIcon,
  'DS': ComputerDesktopIcon,
  'DAV': ChartBarIcon,
  'PM': UserCircleIcon
}


function EvaluationDetail({intl, push, rating, skills, evaluations, setEvaluations, selectedDate, evaluateUserId, isSelfEvaluation}) {

  const areas = _.orderBy(_.uniqBy(skills.map(i => ({
    cod_area: i['topic']['area']['cod_area'],
    des_area: i['topic']['area']['des_area'],
  })), 'cod_area'), 'cod_area')

  const areaOptions = areas.reduce((obj, i) => _.set(obj, [i.cod_area], ({...i, icon: icons[i.cod_area]})), {})
  const [selectedArea, setSelectedArea] = useState(areas.length && areas[0].cod_area);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedType, setSelectedType] = useState();
  const [data, setData] = useState();

  const [showLegend, setShowLegend] = useState(false);
  const [viewMode, setViewMode] = useState('edit');

  useEffect(() => {
    if (skills && rating && !data) {
      setData(getInitSessionData(skills, rating))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skills, rating, data])

  useEffect(() => {
    if (data) {
      setSelectedType(Object.keys(data[selectedArea])[tabIndex])
    }
  }, [data, selectedArea, tabIndex])

  const submit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        setLoading(true)
        const newEvaluations = compactSessionData(data, selectedDate, evaluateUserId)
        const payload = {
          id_user_valutato: evaluateUserId,
          dat_evaluation: selectedDate,
          evaluations: newEvaluations
        }
        await apiRequest.post("/evaluation-suite/bulk", payload)
          .then(() => {
            push({title: intl.formatMessage({id: "thanks_for_eval"}), type: "success"})
            setEvaluations({...evaluations, [selectedDate]: newEvaluations})
          })
      } catch (err) {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      } finally {
        setLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, evaluateUserId, selectedDate]
  )

  const radarData = () => {
    const key = isSelfEvaluation ? 'id_grade' : 'avg_superior'
    return Object.keys(data[selectedArea][selectedType]['topics']).map(
      (topic) => {
        return {
          level: data[selectedArea][selectedType]['topics'][topic].des_topic,
          [key]: Object.keys(data[selectedArea][selectedType]['topics'][topic]['skills']).map((skill) => {
            if (data[selectedArea][selectedType]['topics'][topic]['skills'][skill]['id_grade'] === -1) {
              return 0
            } else {
              return data[selectedArea][selectedType]['topics'][topic]['skills'][skill]['id_grade']
            }
          }).reduce((previous, skill) => {
            return previous + skill
          }, 0) / Object.keys(data[selectedArea][selectedType]['topics'][topic]['skills']).length
        }
      });
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="p-2 bg-gray-100 rounded-xl mt-3">
      <div className="md:grid md:grid-cols-12 md:gap-x-2">
        <aside className="py-6 pl-2 sm:px-6 md:py-0 md:px-0 md:col-span-3 md:mt-1">
          <nav className="space-y-1">
            {Object.values(areaOptions).map((item) => (
              <span
                key={item.cod_area}
                className={classNames(
                  item.cod_area === selectedArea
                    ? 'bg-gray-50 text-am-600 hover:text-am-600 hover:bg-white'
                    : 'text-gray-600 hover:text-gray-600 hover:bg-white',
                  'group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer'
                )}
                aria-current={item.cod_area === selectedArea ? 'page' : undefined}
                onClick={() => {
                  if (item.cod_area !== selectedArea) {
                    setTabIndex(0)
                  }
                  setSelectedArea(item.cod_area)
                }}
              >
                <item.icon
                  className={classNames(
                    item.cod_area === selectedArea
                      ? 'text-am-600 group-hover:text-am-600'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.des_area}</span>
              </span>
            ))}
          </nav>
          <div className="mt-2 md:mt-6 ml-2">
            <Button styleType="empty" className="text-xxs w-32" onClick={() => setShowLegend(true)}>
              <InformationCircleIcon className="h-4 w-4 mr-2"/>
              {intl.formatMessage({id: "legend"})}
            </Button>
          </div>
          <div className="mt-8 ml-2">
            <RadioGroupBasic
              id="radar-eval"
              horizontal
              title={intl.formatMessage({id: "view_mode"})}
              options={[
                {value: "edit", name: "Edit"},
                {value: "radar", name: "Radar"}
              ]}
              currentValue={viewMode}
              onChange={(value) => {
                setViewMode(value);
              }}
            />
          </div>
          {viewMode === 'edit' &&
            <div className="mt-2 md:mt-6 ml-2">
              <Button className="text-sm w-32" onClick={submit} submitting={loading}>
                <  ArrowPathIcon className="h-4 w-4 mr-2"/>
                {intl.formatMessage({id: "save"})}
              </Button>
            </div>
          }
        </aside>
        <div className="space-y-6 md:col-span-9 bg-white rounded-xl m-1 p-4">
          {data && selectedType ?
            <div>
              <Tabs
                setActiveTab={setTabIndex}
                active={tabIndex}
                tabs={Object.values(data[selectedArea]).map(i => ({
                  name: i.des_type,
                  count: skills.filter(x => x['topic'].cod_area === selectedArea &&
                    x['topic'].cod_type === i.cod_type).length
                }))}
              />
              {data && data[selectedArea][selectedType] ? (
                viewMode === 'edit' ? (
                  <div className="mt-3 h-65vh overflow-scroll">
                    {data[selectedArea][selectedType] && Object.values(data[selectedArea][selectedType]['topics']).map((t) => (
                      <div key={t.id_topic}>
                        <p className="mt-3 mb-2 font-bold text-am-700">
                          {t.des_topic}
                        </p>
                        {Object.values(t.skills).map((s) => (
                          <div key={s.id_skill} className="md:grid md:grid-cols-5 md:gap-x-5 my-1">
                            <div className="md:col-span-3 text-gray-600 flex items-center">
                              {s.des_skill}
                            </div>
                            <div className="md:col-span-2 mt-2 mb-3 md:my-1">
                              <RadioGroup
                                value={s.id_grade}
                                onChange={(e) => {
                                  let tmp = _.cloneDeep(data)
                                  tmp[selectedArea][selectedType].topics[t.id_topic].skills[s.id_skill].id_grade = e
                                  setData(tmp)
                                }}
                              >
                                <div className="grid gap-x-2 grid-cols-7">
                                  {gradesOptions.map((option) => (
                                      <RadioGroup.Option
                                        key={option}
                                        value={option}
                                        className={({checked}) =>
                                          classNames(
                                            checked
                                              ? 'bg-am-600 border-transparent text-white hover:bg-am-700'
                                              : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-100',
                                            'border rounded-lg py-1 px-3 flex items-center justify-center text-xs uppercase cursor-pointer'
                                          )
                                        }
                                      >
                                        <RadioGroup.Label as="span">{gradesNames[option]}</RadioGroup.Label>
                                      </RadioGroup.Option>
                                    )
                                  )}
                                </div>
                              </RadioGroup>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>) : (
                  <RadarChart
                    id="Radar evaluation"
                    data={radarData()}
                    useLegend={false}
                  />
                )
              ) : null}
            </div>
            : null
          }
        </div>
      </div>
      {showLegend ? (
        <Modal
          title={intl.formatMessage({id: "legend"})}
          description={
            <div>
              {gradesOptions.map(i => (
                <div key={i} className="border-b border-gray-200 py-2 grid grid-cols-7">
                  <div className="font-bold uppercase">
                    {gradesNames[i]}
                  </div>
                  <div className="col-span-6 text-left">
                    {intl.formatMessage({id: `legend_grade_${gradesNames[i]}`})}
                  </div>
                </div>
              ))}
            </div>
          }
          onExit={() => setShowLegend(false)}
        />
      ) : null}
    </div>
  )
}

export default EvaluationDetail;