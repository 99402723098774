import React from "react";
import { format } from "date-fns";
import Button from "../../ui-components/Button";
import {CalendarIcon} from "@heroicons/react/24/outline";

const SelectEvaluationDate = ({intl, evaluations, setEvaluations, setSelectedDate, setNavigationLevel}) => {

  const today = format(new Date(), "yyyy-MM-dd")
  return (
    <div className="m-10 sm:m-20 flex-col">
      <div className="mb-10">
        <Button
          styleType="empty"
          disabled={Object.keys(evaluations || {}).includes(today)}
          onClick={() => {
            setEvaluations({...evaluations, [today]: []})
            setSelectedDate(today)
            setNavigationLevel(4)
          }}
        >
          {intl.formatMessage({id: "create_new_evaluation"})}
        </Button>
      </div>
      <div className="flow-root">
        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
        <ul role="list">
          {Object.keys(evaluations || {}).sort().reverse().map((date, index) => (
            <li key={index}>
              <div className="relative pb-8">
                {index !== Object.keys(evaluations || {}).length - 1 ? (
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-am-600" aria-hidden="true"/>
                ) : null}
                <div
                  className="relative flex space-x-3 cursor-pointer group"
                  onClick={() => {
                    setSelectedDate(date)
                    setNavigationLevel(4)
                  }}
                >
                  <div>
                    <span className='h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-am-600 group-hover:bg-gray-400'>
                      <CalendarIcon className="h-5 w-5 text-white" aria-hidden="true"/>
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-am-700 font-medium group-hover:text-gray-400">
                        {intl.formatDate(date)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}


export default SelectEvaluationDate