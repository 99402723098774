import React, { useCallback, useContext, useState } from "react";
import OkrSub from "./OkrSub";
import Button from "../../ui-components/Button";
import DatePicker from "../../ui-components/DatePicker";
import Input from "../../ui-components/Input";
import InputMultiLine from "../InputMultiLine";
import { NotificationsContext } from "../../ui-components/Notifications";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { useIntl } from "react-intl";
import apiRequest from "../../services/apiRequest";
import moment from "moment";
import Badge from "../../ui-components/Badge";
import Modal from "../../ui-components/Modal";
import RadioGroup from "../../ui-components/RadioGroup";

function Okr({ okr, fetchOkrData, renderTasks, renderProgress, currentUserId }) {

  const { push } = useContext(NotificationsContext);
  const [editMode, setEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataEnd, setDataEnd] = useState(new Date(okr.dat_end));
  const [note, setNote] = useState(okr.note);
  const [name, setName] = useState(okr.okr_name);
  const [visibilityRadio, setVisibilityRadio] = useState(okr.visibility);
  const [originalVisibility, setOriginalVisibility] = useState(visibilityRadio);
  const [originalName, setOriginalName] = useState(name);
  const [originalNote, setOriginalNote] = useState(note);
  const [originalDataEnd, setOriginalDataEnd] = useState(dataEnd);
  const [tasks, setTasks] = useState(okr.tasks || []);
  const [originalTasks, setOriginalTasks] = useState(okr.tasks || []);
  const [toBeRemovedTasks, setToBeRemovedTasks] = useState([]);
  const intl = useIntl();


  const editable = currentUserId === okr["id_user"];

  let tasksProgressTotal = 0;
  tasks.forEach(element => {
    tasksProgressTotal += element["val_progress"];
  });
  const tasksProgressAverage = tasks.length === 0 ? 100 : tasksProgressTotal / tasks.length;

  const fetchOkrTasks = () => {
    apiRequest
      .get(`/okrs/task_by_id/${okr["id_okr"]}`)
      .then((result) => {
        setTasks(result);
        setOriginalTasks(result);
      })
      .catch(() => {
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
      })
  }

  const updateTasks = useCallback(
    async () => {
      const newTasks = tasks.filter(i => i.flg_new);
      apiRequest
        .post(`okrs/create_task_list/${okr['id_okr']}`, newTasks)
        .then(() => {
          setTasks(tasks.map(t => ({...t, flg_new: false})))
          tasks.forEach(task => {
            if (!task.flg_new) {
              apiRequest
                .post(`okrs/update_task/${task["id_task"]}`, task)
                .then(() => {
                  if (toBeRemovedTasks && toBeRemovedTasks.length > 0) {
                    toBeRemovedTasks.forEach((task) => {
                      apiRequest
                        .delete(`okrs/delete_tasks/${task["id_task"]}`)
                        .then(() => {
                          fetchOkrTasks();
                        })
                        .catch((error) => {
                          console.log(error);
                          push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
                        });
                    });
                    setToBeRemovedTasks([]);
                  } else {
                    fetchOkrTasks();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  push({ title: intl.formatMessage({ id: "update_task_prog_error" }), type: "error" });
                })
            }
          });
        })
        .catch((error) => {
          console.log(error);
          push({ title: intl.formatMessage({ id: "error_updating_task_list" }) });
        });
    }, [okr, tasks, toBeRemovedTasks, intl, push] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const removeOkr = useCallback(async () => {
    apiRequest
      .delete(`okrs/${okr.id_okr}`)
      .then(() => {
        fetchOkrData();
      })
      .catch((error) => {
        console.log(error);
        push({ title: intl.formatMessage({ id: "error_updating_okr" }) });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateOkr = useCallback(
    async () => {
      try {
        if (!dataEnd) {
          push({
            title: intl.formatMessage({ id: "data_end_does_not_exist" })
          })
        }
        await apiRequest.post(`okrs/${okr['id_okr']}`, {
          'okr_name': name,
          'dat_end': moment(dataEnd).format("YYYY-MM-DD"),
          'note': note,
          'visibility': visibilityRadio
        })
      } catch (error) {
        console.log(error)
        push({ title: intl.formatMessage({ id: "error_updating_okr" }) })
      }
    }, [intl, name, dataEnd, note, push, okr, visibilityRadio]
  )

  return (
    <div className="bg-gray-100 border border-neutral-600 rounded-2xl p-4">
      {
        (!editMode) ? (
          <div>
            <div className="flex justify-between gap-1">
              <h1 className="okr-title: text-xl font-semibold w-3/4">{name}</h1>
              {
                renderProgress ? (
                  <div className="relative w-1/4 bg-gray-300 rounded-lg text-center h-6">
                    <div style={{ width: `${tasksProgressAverage}%` }} className="bg-green-500 h-full rounded-lg"></div>
                    <div className="absolute inset-0 z-10 w-full">{tasksProgressAverage.toFixed(0)}%</div>
                  </div>
                ) : <></>
              }
            </div>
            <div className="OkrInfo flex gap-3 my-3">
              {visibilityRadio === "private" ? (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                  </svg>
                  <p>{intl.formatMessage({ id: "okr_visibility_private" })}</p>
                </>
              ) : (
                visibilityRadio === "team" ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    <p>{intl.formatMessage({ id: "okr_visibility_team" })}</p>
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>
                    <p>{intl.formatMessage({ id: "okr_visibility_public" })}</p>
                  </>
                )
              )}
              <Badge
                className="BadgeDate"
                text={((new Date(okr.dat_end) - new Date()) / (1000 * 60 * 60 * 24)).toString() <= 0 ? intl.formatMessage({ id: "okr_status_expired" }) :
                  ((new Date(okr.dat_end) - new Date()) / (1000 * 60 * 60 * 24)).toString() <= 7 ? intl.formatMessage({ id: "okr_status_expiring" }) : intl.formatMessage({ id: "okr_status_active" })}
                type={((new Date(okr.dat_end) - new Date()) / (1000 * 60 * 60 * 24)).toString() <= 0 ? "error" :
                  ((new Date(okr.dat_end) - new Date()) / (1000 * 60 * 60 * 24)).toString() <= 7 ? "warning" : "success"} />
            </div>
            <p className="italic">{note}</p>
            {renderTasks ?
              <div className="flex flex-col gap-1 my-2">
                {tasks.sort((a, b) => a.id_task - b.id_task).map((e) => {
                  return (
                    <div key={e['id_task']}><OkrSub task={e} editMode={false} /></div>
                  )
                })}
              </div> : <></>}
            <div className="flex flex-row justify-between my-4 b-0 h-15">
              {renderTasks ?
                <>
                  <p className="text-center text-sm text-gray-500">{intl.formatMessage({ id: "start_date" })}: {new Date(okr.dat_start).toLocaleDateString("it-IT")}</p>
                  <p className="text-center text-sm text-gray-500">{intl.formatMessage({ id: "estimated_deadline" })}: {new Date(dataEnd? dataEnd : okr.dat_end).toLocaleDateString("it-IT")}</p>
                </> : <></>
              }
            </div>
            {
              editable ? (
                <div className="flex justify-end">
                  <Button
                    styleType={"secondary"}
                    onClick={() => {
                      setEditMode(!editMode)
                    }}
                  > {intl.formatMessage({ id: "edit_okr" })}
                  </Button>
                </div>
              ) : (<></>)
            }
          </div>
        ) : (
          <div>
            <div className="flex flex-col w-full">
              <Input
                value={name}
                type="text"
                onChange={(e) => { setName(e.target.value); }}
                placeholder={intl.formatMessage({ id: "okr_hint_name" })}
              />
            </div>
            <InputMultiLine
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
              placeholder={intl.formatMessage({ id: "okr_hint_note" })}
            />
            {tasks.sort((a, b) => a.id_task - b.id_task).map((key, index) => {
              return (
                <div key={index} className="Task flex justify-between items-center gap-2">
                  <div className="grid grid-cols-[2fr,1fr] flex-1">
                    <Input
                      placeholder={intl.formatMessage({ id: "task" })}
                      value={tasks[index]["name"]}
                      onChange={(e) => {
                        setTasks((prevTasks) => {
                          const result = [...prevTasks];
                          result[index]["name"] = e.target.value;
                          return result;
                        });
                      }}
                      errorMessage={intl.formatMessage({ id: "task_error" })}
                      required
                    />
                  </div>
                  <div className="flex flex-col w-1/12">
                    <Input
                      placeholder="%"
                      value={tasks[index]["val_progress"]}
                      type="number"
                      min="0"
                      max="100"
                      step="10"
                      onChange={(e) => {
                        setTasks((prevTasks) => {
                          if (e.target.value < 0 || e.target.value > 100) {
                            push({ title: intl.formatMessage({ id: "okr_error_task_progress" }) });
                            return prevTasks;
                          }
                          const result = [...prevTasks];
                          result[index]["val_progress"] = e.target.value;
                          return result;
                        });
                      }}
                    />
                  </div>
                  <div className="Button-remove flex flex-col justify-center items-center w-1/12 -mt-4">
                    <Button styleType={"danger"} onClick={
                      () => {
                        setTasks([...tasks.slice(0, index), ...tasks.slice(index + 1, tasks.length)]);
                        setToBeRemovedTasks([...toBeRemovedTasks, tasks[index]]);
                      }
                    }>
                      <MinusCircleIcon className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              )
            })}
            <Button
              styleType={"secondary"}
              onClick={() => {
                setTasks([...tasks, {
                  id_okr: okr["id_okr"],
                  name: "",
                  val_progress: 0,
                  id_task: Math.max(...tasks.map(i => i.id_task)) + 1,
                  flg_new: true
                }])
              }}
              className="my-2">
              <PlusCircleIcon className="h-5 w-5" />{intl.formatMessage({ id: "okr_task_add" })}
            </Button>
            <div className="flex flex-row justify-between mt-1">
              <div className="w-5/12 ml-1">
                <DatePicker
                  value={dataEnd}
                  onChange={setDataEnd}
                  errorMessage={intl.formatMessage({ id: "estimated_deadline" })}
                  label={intl.formatMessage({ id: "estimated_deadline" })}
                />
              </div>
              <div className="w-5/12">
                <RadioGroup
                  id="radioGroup"
                  horizontal
                  title={intl.formatMessage({ id: "okr_visibility_title" })}
                  description={intl.formatMessage({ id: "okr_visibility_message" })}
                  options={[
                    { value: "public", name: intl.formatMessage({ id: "okr_visibility_public" }) },
                    { value: "team", name: intl.formatMessage({ id: "okr_visibility_team" }) },
                    { value: "private", name: intl.formatMessage({ id: "okr_visibility_private" }) },
                  ]}
                  currentValue={visibilityRadio}
                  onChange={(value) => {
                    setVisibilityRadio(value);
                  }}
                />
              </div>
            </div>
            {openModal &&
              <Modal
                title={intl.formatMessage({ id: "okr_elimination_confirmation_title" })}
                description={intl.formatMessage({ id: "okr_elimination_confirmation_message" })}
                onExit={() => setOpenModal(false)}
                onDismiss={() => { setOpenModal(false) }}
                onConfirm={() => {
                  removeOkr();
                  setEditMode(!editMode);
                  setOpenModal(false);
                }}
                confirmText={intl.formatMessage({ id: "okr_elimination_confirmation_delete" })}
                dismissText={intl.formatMessage({ id: "okr_elimination_confirmation_cancel" })}>
              </Modal>
            }
            <div className="flex flex-row justify-between">
              <div className="flex my-2">
                <Button
                  styleType={"danger"}
                  onClick={() => {
                    setOpenModal(true);
                  }}>
                  {intl.formatMessage({ id: "delete" })}</Button>
              </div>
              <div className="flex justify-end gap-2 my-2">
                <Button
                  styleType={"secondary"}
                  onClick={() => {
                    setEditMode(!editMode);
                    setName(originalName);
                    setNote(originalNote);
                    setDataEnd(originalDataEnd);
                    setVisibilityRadio(originalVisibility);
                    setTasks(originalTasks);
                  }}
                >{intl.formatMessage({ id: "undo_update" })}</Button>
                <Button
                  disabled={(tasks || []).length === 0}
                  styleType={"default"}
                  onClick={() => {
                    setEditMode(!editMode);
                    setOriginalName(name);
                    setOriginalNote(note);
                    setOriginalDataEnd(dataEnd);
                    setOriginalTasks(tasks);
                    setOriginalVisibility(visibilityRadio);
                    updateOkr();
                    updateTasks();
                  }}
                >{intl.formatMessage({ id: "submit_update" })}</Button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Okr;
