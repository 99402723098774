import {useContext, useEffect, useState} from "react";
import apiRequest from "../../../../services/apiRequest";
import AuthenticationService from "../../../../services/AuthenticationService";
import {NotificationsContext} from "../../../Notifications";

const useOrganizationChart = () => {
  const [userScopes] = useState(AuthenticationService.getUserScopes());
  const [organizationChart, setOrganizationChart] = useState(null);
  const {push} = useContext(NotificationsContext);

  useEffect(() => {
    let closed = false;
    const fetchOrganizationChart = async () => {
      try {
        const data = await apiRequest.get("/roles-almagamma/organization-chart");

        if (typeof data !== "object") {
          throw new Error("not object");
        }

        if (!closed) {
          setOrganizationChart(data);
        }
      } catch (error) {
        if (!closed) {
          push({
            title: "Errore durante il caricamento",
            type: "error",
          });
        }
      }
    };

    if (organizationChart === null) {
      if (userScopes.indexOf("organization_chart:read") >= 0) {
        fetchOrganizationChart();
      } else {
        setOrganizationChart(false);
        push({
          title: "Scope organization_chart:read non trovato",
          type: "error",
          fixed: true,
        });
      }
    }

    return () => {
      closed = true;
    };
  }, [userScopes, organizationChart, push]);

  return organizationChart;
};

export default useOrganizationChart;
