import React, {useContext, useEffect, useState} from "react";

import {NotificationsContext} from "../../../ui-components/Notifications";
import apiRequest from "../../../services/apiRequest";
import _ from "lodash";

import StaffTeamDonutChart from "../../Home/staffTeamDonutChart";
import CustomerLollipopChart from "./CustomerLollipopChart";
import RadioGroup from "../../../ui-components/RadioGroup";
import PeopleDetailTable from "./PeopleDetailTable";
import Select from "../../../ui-components/Select";


const periodLabels = {
  'month': 'Mese',
  'quarter': 'Quarter',
  'year_to_month': 'Year to Month',
  'year': 'Anno',
}


function PeriodDetail({intl, periodOptions, selectedTeams, hoTl, categories}) {

  const {push} = useContext(NotificationsContext);
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [selectedPeriodDetail, setSelectedPeriodDetail] = useState();
  const [hqFte, setHqFte] = useState('fte');

  const [data, setData] = useState({people: [], composition: [], project: []});

  const [composition, setComposition] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totOre, setTotOre] = useState(0);
  const [totOrePy, setTotOrePy] = useState(0);

  const kpis = [
    {
      key: 'headcount',
      title: 'Headcount',
      period: composition.reduce((acc, i) => acc + i.num_resources, 0),
      previous: composition.reduce((acc, i) => acc + i.num_resources_py, 0)
    },
    {
      key: 'fte',
      title: 'FTE',
      period: composition.reduce((acc, i) => acc + i.fte, 0),
      period_txt: composition.reduce((acc, i) => acc + i.fte, 0).toFixed(1),
      previous: composition.reduce((acc, i) => acc + i.fte_py, 0),
      previous_txt: composition.reduce((acc, i) => acc + i.fte_py, 0).toFixed(1)
    },
    {
      key: 'ore_totali',
      title: 'Ore totali',
      period: totOre,
      period_txt: intl.formatNumber(totOre, {maximumFractionDigits: 0}),
      previous: totOrePy,
      previous_txt: intl.formatNumber(totOrePy, {maximumFractionDigits: 0})
    },
    {
      key: 'ore_vendute',
      title: 'Ore vendute',
      period: tableData.reduce((acc, i) => acc + (i.ore_vendute || 0), 0),
      period_txt: intl.formatNumber(tableData.reduce((acc, i) => acc + (i.ore_vendute || 0), 0).toFixed(0), {maximumFractionDigits: 0}),
      period_perc: (tableData.reduce((acc, i) => acc + (i.ore_vendute || 0), 0) / totOre * 100).toFixed(0),
      previous: tableData.reduce((acc, i) => acc + (i.py_ore_vendute || 0), 0),
      previous_txt: intl.formatNumber(tableData.reduce((acc, i) => acc + (i.py_ore_vendute || 0), 0).toFixed(0), {maximumFractionDigits: 0}),
      previous_perc: (tableData.reduce((acc, i) => acc + (i.py_ore_vendute || 0), 0) / totOrePy * 100).toFixed(0),
    },
    {
      key: 'ore_vendute_fte',
      title: 'Ore vendute per FTE',
      period: (tableData.reduce((acc, i) => acc + (i.ore_vendute || 0), 0) / composition.reduce((acc, i) => acc + i.fte, 0)),
      period_txt: (tableData.reduce((acc, i) => acc + (i.ore_vendute || 0), 0) / composition.reduce((acc, i) => acc + i.fte, 0)).toFixed(1),
      previous: (tableData.reduce((acc, i) => acc + (i.py_ore_vendute || 0), 0) / composition.reduce((acc, i) => acc + i.fte_py, 0)),
      previous_txt: (tableData.reduce((acc, i) => acc + (i.py_ore_vendute || 0), 0) / composition.reduce((acc, i) => acc + i.fte_py, 0)).toFixed(1)
    },
    {
      key: 'progetti_lavorati',
      title: 'Progetti lavorati (8h+)',
      period: projects.filter(i => i.val_ore > 8).length,
      previous: projects.filter(i => i.val_ore_py > 8).length
    }
  ]

  const fetchData = () => {
    apiRequest
      .get(`/economics/gescli/detail?period=${selectedPeriod}&detail=${selectedPeriodDetail}`)
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
  }

  useEffect(() => {
    if (selectedPeriodDetail) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriodDetail])

  useEffect(() => {
    if (periodOptions.month) {
      setSelectedPeriodDetail(periodOptions[selectedPeriod][1])
    }
  }, [periodOptions, selectedPeriod])

  useEffect(() => {
    if (data.composition) {

      let _data = data.people.filter(i => selectedTeams.includes(i.cod_team))
      let _composition = data.composition.filter(i => selectedTeams.includes(i.cod_team))
      let _prj = data.project.filter(i => selectedTeams.includes(i.cod_team))


      if (!hoTl.includes('HO')) {
        _data = _data.filter(i => !i.flg_ho)
        _composition = _composition.filter(i => !i.flg_ho)
        _prj = _prj.filter(i => !i.flg_ho)
      }
      if (!hoTl.includes('TL')) {
        _data = _data.filter(i => !i.flg_tl)
        _composition = _composition.filter(i => !i.flg_tl)
        _prj = _prj.filter(i => !i.flg_tl)
      }

      setComposition(_(_composition)
        .groupBy('cod_team')
        .map((items, t) => {
          const fteRisorsaPeriodo = _.uniqBy(items, e => e.cod_risorsa)
          const fteRisorsa = _(fteRisorsaPeriodo).groupBy('cod_risorsa').map((rows, r) => {
            const ore_periodo = rows.reduce((acc, i) => acc + i.ore_totali_periodo, 0)
            const ore_periodo_py = rows.reduce((acc, i) => acc + i.ore_totali_periodo_py, 0)
            return ({
              risorsa: r,
              fte: ore_periodo ? rows.reduce((acc, i) => acc + i.ore_lavorabili_risorsa, 0) / ore_periodo : 0,
              fte_py: ore_periodo_py ? rows.reduce((acc, i) => acc + i.ore_lavorabili_risorsa_py, 0) / ore_periodo_py : 0
            })
          }).value()

          return ({
            value: t,
            name: items[0].des_team,
            num_resources: [...new Set(items.filter(i => i.ore_lavorabili_risorsa > 0).map(i => i.cod_risorsa))].length,
            num_resources_py: [...new Set(items.filter(i => i.ore_lavorabili_risorsa_py > 0).map(i => i.cod_risorsa))].length,
            fte: Math.round(fteRisorsa.reduce((acc, i) => acc + i.fte, 0) * 10) / 10,
            fte_py: Math.round(fteRisorsa.reduce((acc, i) => acc + i.fte_py, 0) * 10) / 10,
          })
        }).value())

      setTotOre(_data.reduce((acc, i) => acc + i.val_ore, 0))
      setTotOrePy(_data.reduce((acc, i) => acc + i.val_ore_py, 0))

      let groupedCustomer = _(_prj.filter(i => i.des_cliente !== 'Ammagamma'))
        .groupBy('des_cliente')
        .map((items, c) => {
          return ({
            customer: c,
            val_ore: _.sumBy(items, 'val_ore'),
            val_ore_py: _.sumBy(items, 'val_ore_py'),
          })
        }).value()

      setCustomers(groupedCustomer)

      setProjects(_(_prj.filter(i => i.des_cliente !== 'Ammagamma'))
        .groupBy('cod_commessa')
        .map((items, c) => {
          return ({
            commessa: c,
            val_ore: _.sumBy(items, 'val_ore'),
            val_ore_py: _.sumBy(items, 'val_ore_py'),
          })
        }).value().filter(i => i.val_ore > 8 || i.val_ore_py > 8))

      let _td = []
      categories.forEach(c => {
        const tmp = _(_data.filter(i => i.cod_categoria === c))
          .groupBy('cod_risorsa')
          .map((items, r) => ({
            risorsa: r.replace('.', ' '),
            [`ore_${c}`]: _.sumBy(items, 'val_ore'),
            [`py_ore_${c}`]: _.sumBy(items, 'val_ore_py'),
          })).value()
        _td = _.values(_.merge(_.keyBy(_td, 'risorsa'), _.keyBy(tmp, 'risorsa')));
      })
      _td = _td.map(i => ({
        ...i,
        val_ore: Object.entries(i).filter(i => i[0].startsWith('ore')).reduce((acc, i) => acc + i[1], 0),
        val_ore_py: Object.entries(i).filter(i => i[0].startsWith('py_ore')).reduce((acc, i) => acc + i[1], 0)
      }))
      setTableData(_.sortBy(_td, i => i.risorsa))

    }
  }, [selectedTeams, hoTl, data.composition]) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className="w-full mt-4">
      <div className="w-full flex flex-col sm:flex-row gap-4">
        <div className="whitespace-nowrap font-bold mr-10 mt-2">Periodo di riferimento:</div>
        <div className="w-full -mt-2">
          <RadioGroup
            id="choose_period"
            horizontal
            options={Object.keys(periodOptions).map(i => ({value: i, name: periodLabels[i]}))}
            currentValue={selectedPeriod}
            onChange={(value) => {
              setSelectedPeriod(value)
            }}
          />
        </div>
        <div className="w-full">
          <Select
            value={selectedPeriodDetail}
            options={periodOptions[selectedPeriod].map(i => {
              if (selectedPeriod === 'month') {
                const _date = new Date(i)
                return {value: i, name: intl.formatDate(_date, {month: "short", year: "numeric"})}
              } else {
                return {value: i, name: i}
              }
            })}
            onChange={(e) => setSelectedPeriodDetail(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 w-full flex gap-4">
        <div className="flex w-full sm:w-2/3 flex-col gap-4">
          <div className="w-full flex flex-col sm:flex-row items-center">
            <div className="w-full border border-neutral-600 rounded-2xl flex flex-col p-4">
              <div className="flex justify-between italic text-xs mb-3">
                <div className="w-full">KPI</div>
                <div className="w-full text-center">Period</div>
                <div className="w-full text-center">PY</div>
                <div className="w-full text-center">Delta</div>
              </div>
              {kpis.map(k => (
                <div className="flex justify-between" key={k.key}>
                  <div className="w-full">{k.title}:</div>
                  <div className="w-full font-bold text-center">{k.period_txt || k.period} {k.period_perc && `(${k.period_perc}%)`}</div>
                  <div className="w-full text-center">{k.previous_txt || k.previous} {k.previous_perc && `(${k.previous_perc}%)`}</div>
                  <div className="w-full text-center italic">
                    {k.period > k.previous ? '+' : null}{((k.period - k.previous) / (k.previous + 0.0001) * 100).toFixed(0)}% {k.period_perc && `(${Number(k.period_perc) > Number(k.previous_perc) ? '+' : ''}${Number(k.period_perc) - Number(k.previous_perc)} pp)`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 mb-10">
            <PeopleDetailTable categories={categories} data={tableData.filter(i => i.val_ore > 0)}/>
          </div>
        </div>
        <div className="flex w-full sm:w-1/3 flex-col items-center">
          <div>
            <RadioGroup
              id="choose_hq_fte"
              horizontal
              options={[
                {value: "fte", name: "FTE"},
                {value: "num_resources", name: "Headcount"}
              ]}
              currentValue={hqFte}
              onChange={(value) => {
                setHqFte(value)
              }}
            />
          </div>
          <div className="w-full">
            <StaffTeamDonutChart id="composition-team-donut-chart" data={composition} measure={hqFte}/>
          </div>
          <div className="w-full">
            <CustomerLollipopChart id="project-bar-chart" data={[... _.orderBy(customers, 'val_ore', 'desc').slice(0,20)].reverse()}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PeriodDetail, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.periodOptions, nextProps.periodOptions) && _.isEqual(props.selectedTeams, nextProps.selectedTeams) && _.isEqual(props.hoTl, nextProps.hoTl);
});