import React, {useState, useContext} from "react";

import {NotificationsContext} from "../../ui-components/Notifications";
import apiRequest from "../../services/apiRequest";
import {useIntl} from "react-intl";
import _ from "lodash"

import ProjectSkill from "./ProjectSkill";
import ProjectStaffing from "./ProjectStaffing";

import {ChevronLeftIcon} from "@heroicons/react/24/outline";
import Button from "../../ui-components/Button";
import InputMultiLine from "../../ui-components/InputMultiLine";
import DatePicker from "../../ui-components/DatePicker";
import {formatDate} from "../../utils";
import Modal from "../../ui-components/Modal";
import Select from "../../ui-components/Select";


function ProjectDetail({project, scopes, setSelectedProject, dataContext, almagammaRoles, organizationChart}) {
  const intl = useIntl();
  const {push} = useContext(NotificationsContext);
  const [editMode, setEditMode] = useState(false);
  const [modifiedProject, setModifiedProject] = useState(_.cloneDeep(project));
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const updateProjDetail = () => {
    const payload = {
      ...modifiedProject,
      dat_init: formatDate(new Date(modifiedProject.dat_init)),
      dat_finish: formatDate(new Date(modifiedProject.dat_finish)),
    }
    apiRequest
      .post(`/projects/${project.id_proj}`, payload)
      .then((res) => {
        dataContext.setProjectList([...dataContext.projectList.filter(i => i.id_proj !== res['id_proj']), res])
        push({title: intl.formatMessage({id: "project_updated"}), type: "success"});
        setEditMode(false);
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
      })
  }

  const deleteProject = () => {
    apiRequest
      .delete(`/projects/${project.id_proj}`)
      .then(() => {
        dataContext.setProjectList(dataContext.projectList.filter(i => i.id_proj !== project.id_proj));
        push({title: intl.formatMessage({id: "project_deleted"}), type: "success"});
        setSelectedProject(null);
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
      })
  }

  const selectCustomer = () => {
    return (
      <div className="flex flex-col sm:flex-row p-4 border-b border-black items-center">
        <div className="w-full sm:w-1/4">
          <b>{intl.formatMessage({id: "customer"})}</b>
        </div>
        <div className={`w-full sm:w-3/4 uppercase`}>
          {editMode ?
            <div className="-mb-2 -mt-2">
              <Select
                options={[{value: undefined, name: ""}]
                  .concat(dataContext.customerList
                    .map(i => ({value: i['cod_customer'], name: i['des_customer']})))}
                value={modifiedProject.cod_customer}
                onChange={(e) => {
                  setModifiedProject({...modifiedProject,
                  cod_customer: e.target.value,
                  customer: {
                    cod_customer: e.target.value,
                    des_customer: dataContext.customerList.find(x => x.cod_customer === e.target.value)['des_customer']}
                })}}
              />
            </div>
            : <span>{modifiedProject['customer']['des_customer']}</span>
          }
        </div>
      </div>
    )
  }

  const textAttribute = (label, key) => {
    const textSmall = label === 'description' ? 'text-xs italic' : 'uppercase'
    return (
      <div className="flex flex-col sm:flex-row p-4 border-b border-black items-center">
        <div className="w-full sm:w-1/4">
          <b>{intl.formatMessage({id: label})}</b>
        </div>
        <div className={`w-full sm:w-3/4 ${textSmall}`}>
          {editMode ?
            <div className="-mb-6 -mt-2">
              <InputMultiLine
                rows={['description', 'name'].includes(label) ? 2 : 1}
                type="text"
                value={modifiedProject[key]}
                onChange={(e) => setModifiedProject({...modifiedProject, [key]: e.target.value})}
              />
            </div>
            : <span>{modifiedProject[key]}</span>
          }
        </div>
      </div>
    )
  }

  const dateAttribute = (label, key, last = false) => {
    const border = last ? '' : 'border-b'
    return (
      <div className={`flex flex-col sm:flex-row  p-4 ${border} border-black items-center`}>
        <div className="w-full sm:w-1/4">
          <b>{intl.formatMessage({id: label})}</b>
        </div>
        <div className="w-full sm:w-3/4">
          {editMode ?
            <div className="-mb-6 -mt-2">
              <DatePicker
                minDate={null}
                value={new Date(modifiedProject[key])}
                onChange={(e) => setModifiedProject({
                  ...modifiedProject,
                  [key]: e
                })}
              />
            </div>
            : <span>{(new Date(modifiedProject[key])).toLocaleDateString('it-IT')}</span>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row gap-x-2 items-center justify-between pt-8 pb-10">
        <div className="flex flex-row gap-x-2 items-center w-full sm:w-10/12">
          <div>
            <ChevronLeftIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => {
                setSelectedProject(null)
              }}
            />
          </div>
          <div className="text-lg font-extrabold leading-7 uppercase">
            {modifiedProject.project_number} - {modifiedProject.project_name}
          </div>
        </div>
        <div className="w-full sm:w-2/12 flex justify-end">
          {scopes.includes('project:update') &&
            <Button onClick={() => {
              if (editMode) {
                setModifiedProject(_.cloneDeep(project))
              }
              setEditMode(!editMode)
            }}>
              {editMode ? intl.formatMessage({id: "cancel"}) : intl.formatMessage({id: "edit"})}
            </Button>
          }
        </div>
      </div>
      <div className="w-full flex flex-col sm:flex-row gap-x-10">
        <div className="w-full sm:w-7/12">
          <div className="border border-black rounded-3xl">
            {selectCustomer()}
            {textAttribute("commessa", "project_number")}
            {textAttribute("name", "project_name")}
            {textAttribute("description", "des_project")}
            {dateAttribute("date_init", "dat_init")}
            {dateAttribute("date_finish", "dat_finish", true)}
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="py-5 sm:mr-5">
              <Button
                onClick={updateProjDetail}
                className={editMode ? "visible" : "invisible"}
                styleType="secondary"
              >
                {intl.formatMessage({id: "submit_update"})}
              </Button>
            </div>
            {scopes.includes('project:delete') &&
              <div className="py-5">
                <Button
                  styleType="danger"
                  onClick={() => setShowConfirmDelete(!showConfirmDelete)}
                  className={editMode ? "visible" : "invisible"}
                >
                  {intl.formatMessage({id: "delete_project"})}
                </Button>
              </div>
            }
          </div>
        </div>
        <div className="w-full sm:w-5/12 flex flex-col">
          <ProjectSkill
            id_proj={project.id_proj}
            editMode={editMode}
            intl={intl}
          />
          <ProjectStaffing
            project={project}
            editMode={editMode}
            almagammaRoles={almagammaRoles}
            organizationChart={organizationChart}
            dataContext={dataContext}
          />
        </div>
      </div>
      {showConfirmDelete &&
        <Modal
          title={intl.formatMessage({id: "confirm_delete_project"})}
          description={intl.formatMessage({id: "you_will_lose_data"})}
          onExit={() => setShowConfirmDelete(false)}
          onDismiss={() => setShowConfirmDelete(false)}
          onConfirm={deleteProject}
          confirmText={intl.formatMessage({id: "delete"})}
          dismissText={intl.formatMessage({id: "cancel"})}
        />
      }
    </>
  );
}

export default ProjectDetail;