import React, {useEffect, useState} from "react";
import {currencyStyleEur} from "@utils/constants";
import Select from "../../../ui-components/Select";


export const BestWorstCase = ({intl, detail, rischiAnticipi, selectedYear, currentMonth}) => {

  const commesse = Object.values(detail || {}).reduce((acc, i) => acc.concat(i.commesse), [])

  const [selectedMonth, setSelectedMonth] = useState(currentMonth)
  const [selectedQuarter, setSelectedQuarter] = useState(Math.floor(currentMonth / 3) + 1)
  const [mese, setMese] = useState({best: 0, regular: 0, worst: 0})
  const [quarter, setQuarter] = useState({best: 0, regular: 0, worst: 0})

  useEffect(() => {
    let _mese = {best: 0, regular: 0, worst: 0}
    commesse.forEach(c => {
      const trancheMese = c.month_detail[selectedMonth] || 0
      const getStatus = (m) => ((rischiAnticipi[c.cod_commessa] || {})[c.des_conto] || {})[m]
      const month = `${selectedYear}-${(selectedMonth + 1).toString().padStart(2, '0')}-01`
      const flgRischio = getStatus(month) === 'RISCHIO'
      _mese.regular += trancheMese
      _mese.best += trancheMese
      _mese.worst += flgRischio ? 0 : trancheMese
      if (selectedMonth < 11) {
        const nextMonth = `${selectedYear}-${(selectedMonth + 2).toString().padStart(2, '0')}-01`
        const flgAnticipo = getStatus(nextMonth) === 'ANTICIPO'
        _mese.best += flgAnticipo ? (c.month_detail[selectedMonth + 1] || 0) : 0
      }
    })
    setMese(_mese)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, selectedMonth, rischiAnticipi]);

  useEffect(() => {
    let _quarter = {best: 0, regular: 0, worst: 0}
    const idFirstMonthOfQuarter = (selectedQuarter - 1) * 3
    commesse.forEach(c => {

      // Tranche mesi dei quarter
      const tranche1 = c.month_detail[idFirstMonthOfQuarter] || 0
      const tranche2 = c.month_detail[idFirstMonthOfQuarter + 1] || 0
      const tranche3 = c.month_detail[idFirstMonthOfQuarter + 2] || 0

      const getStatus = (m) => ((rischiAnticipi[c.cod_commessa] || {})[c.des_conto] || {})[m]

      const q3 = `${selectedYear}-${(idFirstMonthOfQuarter + 3).toString().padStart(2, '0')}-01`
      const flgRischio3 = getStatus(q3) === 'RISCHIO'

      _quarter.regular += tranche1 + tranche2 + tranche3
      _quarter.best += tranche1 + tranche2 + tranche3
      _quarter.worst += tranche1 + tranche2 + (flgRischio3 ? 0 : tranche3)

      if (selectedQuarter < 4) {
        const firstMonthNextQuarter = `${selectedYear}-${(idFirstMonthOfQuarter + 4 - (idFirstMonthOfQuarter % 3)).toString().padStart(2, '0')}-01`
        const flgAnticipo = getStatus(firstMonthNextQuarter) === 'ANTICIPO'
        _quarter.best += flgAnticipo ? (c.month_detail[idFirstMonthOfQuarter + (3 - (idFirstMonthOfQuarter % 3))] || 0) : 0
      }

    })
    setQuarter(_quarter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, selectedQuarter, rischiAnticipi]);


  const kpiBox = ({kpi}) => (
    <div className="w-2/5 flex flex-col gap-2">
      <p className="flex w-full text-xs font-bold text-green-600">
        Best case: {intl.formatNumber(kpi.best, currencyStyleEur)}
      </p>
      <p className="flex w-full text-xs font-bold text-gray-600">
        Previsto: {intl.formatNumber(kpi.regular, currencyStyleEur)}
      </p>
      <p className="flex w-full text-xs font-bold text-red-600">
        Worst case: {intl.formatNumber(kpi.worst, currencyStyleEur)}
      </p>
    </div>
  )

  return (
    <div className="flex flex-col sm:flex-row gap-5">
      <div className="flex flex-row border rounded-xl p-2 w-full">
        <div className="w-3/5 flex flex-col gap-2 items-center">
          <p className="flex w-full justify-center capitalize text-sm font-bold">
            Scenario mensile
          </p>
          <div className="w-64">
            <Select
              options={[...Array(12).keys()].map(i => ({
                value: i,
                name: intl.formatDate(new Date(Number(selectedYear), i, 1), {month: "long", year: "2-digit"})
              }))}
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(Number(e.target.value))}
            />
          </div>
        </div>
        {kpiBox({kpi: mese})}
      </div>
      <div className="flex flex-row border rounded-xl p-2 w-full">
        <div className="w-3/5 flex flex-col gap-2">
          <p className="flex w-full justify-center capitalize text-sm font-bold">
            Scenario quarter
          </p>
          <div className="w-64">
            <Select
              options={[...Array(4).keys()].map(i => ({
                value: i + 1,
                name: `Q${i + 1} ${selectedYear}`
              }))}
              value={selectedQuarter}
              onChange={(e) => setSelectedQuarter(Number(e.target.value))}
            />
          </div>
        </div>
        {kpiBox({kpi: quarter})}
      </div>
    </div>
  );
}
