import React, {useContext, useState, useEffect} from "react";


import {PageHeading} from "../../../ui-components/Container";
import {NotificationsContext} from "../../../ui-components/Notifications";
import apiRequest from "../../../services/apiRequest";
import {useIntl} from "react-intl";
import AreasTypesTable from "./AreasTypesTable";
import Placeholder from "../../../ui-components/Placeholder";
import _ from "lodash";
import TopicsTable from "./TopicsTable";
import SkillsTable from "./SkillsTable";


function Taxonomy() {

  const intl = useIntl();

  const {push} = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState();
  const [types, setTypes] = useState();
  const [topics, setTopics] = useState();
  const [skills, setSkills] = useState();

  useEffect(() => {
    let getAreas = apiRequest.get('/skills/area');
    let getTypes = apiRequest.get('/skills/type');
    let getTopics = apiRequest.get('/skills/topic');
    let getSkills = apiRequest.get('/skills');

    return Promise.all([getAreas, getTypes, getTopics, getSkills])
      .then((res) => {
        setAreas((res[0] || []).reduce((obj, row) => _.set(obj, [row.cod_area], row), {}));
        setTypes((res[1] || []).reduce((obj, row) => _.set(obj, [row.cod_type], row), {}));
        setTopics((res[2] || []).reduce((obj, row) => _.set(obj, [row.id_topic], row), {}));
        setSkills((res[3] || []).reduce((obj, row) => _.set(obj, [row.id_skill], row), {}));
      })
      .catch(() => {
        push({title: "server_error", type: "error"})
      })
      .finally(() => setLoading(false))

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col">
      <PageHeading title="Settings"/>
      {skills && !loading ? (
        <div className="flex flex-col gap-10">
          <div className="flex flex-col md:flex-row gap-10">
            <div className="w-full">
              <AreasTypesTable
                intl={intl}
                push={push}
                data={areas}
                setData={setAreas}
                item="area"
              />
            </div>
            <div className="w-full">
              <AreasTypesTable
                intl={intl}
                push={push}
                data={types}
                setData={setTypes}
                item="type"
              />
            </div>
          </div>
          <div className="w-full">
            <TopicsTable
              intl={intl}
              push={push}
              data={topics}
              setData={setTopics}
              item="topic"
              areas={areas}
              types={types}
            />
          </div>
          <div className="w-full mb-20">
            <SkillsTable
              intl={intl}
              push={push}
              data={skills}
              setData={setSkills}
              item="skill"
              areas={areas}
              types={types}
              topics={topics}
            />
          </div>
        </div>
      ) : (
        <div>
          <Placeholder width="w-full" height="h-8"/>
          <Placeholder width="w-full" height="h-32"/>
          <Placeholder width="w-full" height="h-64"/>
        </div>
      )}
    </div>);
}

export default Taxonomy;