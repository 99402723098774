import React from "react";
import Button from "../../ui-components/Button";

const ActionButton = ({intl, onClick, title, description, disabled=false}) => (
  <div className="w-full sm:w-3/4 sm:mb-8">
    <Button
      styleType="empty"
      className="w-full py-4 my-4 border-2 "
      onClick={onClick}
      disabled={disabled}
    >
      <div className="sm:py-2">
        <p>{intl.formatMessage({id: title})}</p>
        <p className="text-xs mt-2 italic font-normal">
          {intl.formatMessage({id: description})}
        </p>
      </div>
    </Button>
  </div>
)

export default ActionButton