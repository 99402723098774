import { PageSection } from "@ui-components/Container";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import SearchSelect from "@ui-components/SearchSelect";
import DatePicker from "@ui-components/DatePicker";
import Tabs from "@ui-components/Tabs";
import RadioGroup from "@ui-components/RadioGroup";
import ProductivityTable from "./ProductivityTable";
import ProductivityTrendChart from "./ProductivityTrendChart";
import { CALENDAR_MODE } from "@utils/constants";
import { useEffect, useState } from "react";
import { formatDate } from "./index";
import { api } from "@services/apiRequest";
import moment from "moment";
import RicaviFteChart from "./RicaviFteChart";
import RicaviGiornataChart from "./RicaviGiornataChart";

export default function DashboardDettaglio({
  teams,
  selectedTeams,
  setSelectedTeams,
  monthStartDatePicker,
  setMonthStartDatePicker,
  monthEndDatePicker,
  setMonthEndDatePicker,
  minDate,
  maxDate,
  clients,
  selectedClient,
  setSelectedClient,
  commesse,
  selectedCommessa,
  setSelectedCommessa,
  data,
  chartData,
  tableData,
  intl,
  push,
}) {
  const [tabIndexChart, setTabIndexChart] = useState(0);
  const [chartDataRicaviFte, setChartDataRicaviFte] = useState();
  const [chartDataRicaviGiornata, setChartDataRicaviGiornata] = useState();
  const [selectedViewRicaviFte, setSelectedViewRicaviFte] =
    useState("cumulative");
  const [selectedViewRicaviGiornata, setSelectedViewRicaviGiornata] =
    useState("cumulative");
  const [chartFte, setChartFte] = useState();
  const [chartFteRolling, setChartFteRolling] = useState();
  const monthsNumber = Math.round(
    moment(monthEndDatePicker).diff(
      moment(monthStartDatePicker),
      "months",
      true
    ) + 1
  );

  useEffect(
    () => {
      const monthStart = formatDate(monthStartDatePicker);
      const monthEnd = formatDate(monthEndDatePicker);
      const payload = {
        teams: selectedTeams,
        month_start: monthStart,
        month_end: monthEnd,
        clients: selectedClient.map((c) => c.value),
        commesse: selectedCommessa.map((c) => c.value),
      };
      api
        .post(`/economics/productivity/chart-data-fte`, payload)
        .then(({ data: result }) => {
          setChartFte(result);
        })
        .catch(() => {
          push({ title: "Errore del server", type: "error" });
        });

      api
        .post(`/economics/productivity/chart-data-fte-rolling`, payload)
        .then(({ data: result }) => {
          setChartFteRolling(result);
        })
        .catch(() => {
          push({ title: "Errore del server", type: "error" });
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      monthStartDatePicker,
      monthEndDatePicker,
      selectedTeams,
      selectedClient,
      selectedCommessa,
    ]
  );

  const getFteCumulato = (data) => {
    if (chartFte) {
      let fteData = {};
      const months = [];
      let monthStart = moment(monthStartDatePicker);
      let monthEnd = moment(monthEndDatePicker);
      while (monthStart.isBefore(monthEnd)) {
        months.push(formatDate(monthStart));
        monthStart.add(1, "month");
      }
      months.push(formatDate(monthEnd));
      let ricavi = 0;
      for (const item of data) {
        ricavi += item.val_ricavi_totali;
        fteData[item.dat_month] = {
          ricavi: ricavi,
        };
      }

      months.forEach((month) => {
        const tmp = chartFte.filter((d) => d.dat_month === month);
        const fte = tmp.reduce((acc, current) => {
          return acc + current.ore_lavorabili / current.ore_totali;
        }, 0);

        fteData[month] = {
          ...fteData[month],
          fte: fte,
          dat_month: new Date(month),
        };
      });

      let ftePeriodo = 0;
      for (let i = 0; i < months.length; i++) {
        ftePeriodo += fteData[months[i]].fte;
        fteData[months[i]] = {
          ...fteData[months[i]],
          fte_periodo: ftePeriodo / (i + 1),
          kpi: fteData[months[i]].ricavi / (ftePeriodo / (i + 1)),
        };
      }
      fteData = Object.keys(fteData).map((key) => fteData[key]);
      return fteData;
    }
  };

  const getFteRolling = () => {
    if (chartFteRolling) {
      let fteData = {};
      const months = [];
      let monthStart = moment(monthStartDatePicker);
      let monthEnd = moment(monthEndDatePicker);

      while (monthStart.isBefore(monthEnd)) {
        months.push(formatDate(monthStart));
        monthStart.add(1, "month");
      }
      months.push(formatDate(monthEnd));
      const rollingMonths = {};

      months.forEach((month) => {
        const tmp = [];
        const currentMonth = moment(month);
        let prev12Month = moment(month).subtract(11, "months");
        const minDate = moment("2022-01-01");
        if (prev12Month.isBefore(minDate)) prev12Month = minDate;

        while (prev12Month.isBefore(currentMonth)) {
          tmp.push(formatDate(prev12Month));
          prev12Month.add(1, "month");
        }
        tmp.push(formatDate(currentMonth));
        rollingMonths[month] = tmp;
      });

      Object.keys(rollingMonths).forEach((month) => {
        let ricavi = 0;
        let fte = 0;
        ricavi += chartFteRolling.trend
          .filter((d) => rollingMonths[month].includes(d.dat_month))
          .reduce((acc, current) => {
            return acc + current.val_ricavi_totali;
          }, 0);

        fte += chartFteRolling.fte
          .filter((d) => rollingMonths[month].includes(d.dat_month))
          .reduce((acc, current) => {
            return acc + current.ore_lavorabili / current.ore_totali;
          }, 0);

        fteData[month] = {
          dat_month: month,
          ricavi: ricavi,
          fte: fte,
          fte_periodo: fte / rollingMonths[month].length,
          kpi: ricavi / (fte / rollingMonths[month].length),
        };
      });
      fteData = Object.keys(fteData).map((key) => fteData[key]);
      return fteData;
    }
  };

  const getRicaviGiornataCumulato = (data) => {
    let ricaviData = [];
    let ricavi = 0;
    let giorni = 0;
    for (const item of data) {
      ricavi += item.val_ricavi_totali;
      giorni += item.val_ore_vendute_totali / 8;
      ricaviData.push({
        ricavi: ricavi,
        giorni: giorni,
        kpi: ricavi / giorni,
        dat_month: item.dat_month,
      });
    }
    return ricaviData;
  };

  const getRicaviGiornataRolling = (data) => {
    let ricaviData = [];
    const months = [];
    let monthStart = moment(monthStartDatePicker);
    let monthEnd = moment(monthEndDatePicker);
    while (monthStart.isBefore(monthEnd)) {
      months.push(formatDate(monthStart));
      monthStart.add(1, "month");
    }
    months.push(formatDate(monthEnd));
    const rollingMonths = {};

    months.forEach((month) => {
      const tmp = [];
      const currentMonth = moment(month);
      let prev12Month = moment(month).subtract(11, "months");
      const minDate = moment("2022-01-01");
      if (prev12Month.isBefore(minDate)) prev12Month = minDate;

      while (prev12Month.isBefore(currentMonth)) {
        tmp.push(formatDate(prev12Month));
        prev12Month.add(1, "month");
      }
      tmp.push(formatDate(currentMonth));
      rollingMonths[month] = tmp;
    });

    Object.keys(rollingMonths).forEach((month) => {
      let ricavi = 0;
      let giorni = 0;
      ricavi += data
        .filter((d) => rollingMonths[month].includes(d.dat_month))
        .reduce((acc, current) => {
          return acc + current.val_ricavi_totali;
        }, 0);

      giorni += data
        .filter((d) => rollingMonths[month].includes(d.dat_month))
        .reduce((acc, current) => {
          return acc + current.val_ore_vendute_totali / 8;
        }, 0);

      ricaviData.push({
        ricavi: ricavi,
        giorni: giorni,
        kpi: ricavi / giorni,
        dat_month: month,
      });
    });
    return ricaviData;
  };

  const getRicaviGiornataMensile = (data) => {
    let ricaviData = [];
    for (const item of data) {
      ricaviData.push({
        ricavi: item.val_ricavi_totali,
        giorni: item.val_ore_vendute_totali / 8,
        kpi: item.val_ricavi_totali / (item.val_ore_vendute_totali / 8),
        dat_month: item.dat_month,
      });
    }
    return ricaviData;
  };

  useEffect(() => {
    const fteCumulato = getFteCumulato(data.trend);
    const fteRolling = getFteRolling();

    if (fteCumulato && fteRolling) {
      setChartDataRicaviFte({
        cumulato: fteCumulato,
        rolling: fteRolling,
      });
    }
  }, [chartFte, data, chartFteRolling]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const RicaviGiornataCumulato = getRicaviGiornataCumulato(data.trend);
    const RicaviGiornataRolling = chartFteRolling
      ? getRicaviGiornataRolling(chartFteRolling.trend)
      : null;
    const RicaviGiornataMensile = getRicaviGiornataMensile(data.trend);

    if (
      RicaviGiornataCumulato &&
      RicaviGiornataRolling &&
      RicaviGiornataMensile
    ) {
      setChartDataRicaviGiornata({
        cumulato: RicaviGiornataCumulato,
        rolling: RicaviGiornataRolling,
        monthly: RicaviGiornataMensile,
      });
    }
  }, [data, chartFteRolling]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="mt-5">
        <PageSection title={"Filtra per Team"}>
          <div className="flex flex-col items-center">
            <CheckboxGroup
              horizontal
              options={teams}
              values={selectedTeams}
              onChange={(value) => {
                if (selectedTeams.indexOf(value) >= 0) {
                  setSelectedTeams([
                    ...selectedTeams.filter((v) => v !== value),
                  ]);
                } else {
                  setSelectedTeams([...selectedTeams, value]);
                }
              }}
            />
          </div>
        </PageSection>
      </div>
      <div className="-mt-10">
        <PageSection title={"Periodo di Riferimento"}>
          <div className="grid grid-cols-2 gap-y-1 gap-x-10 -mt-4">
            <p className="text-lg font-medium ">Da</p>
            <p className="text-lg font-medium ml-1">A</p>

            <DatePicker
              value={monthStartDatePicker}
              onChange={(value) => setMonthStartDatePicker(value)}
              placeholder="Seleziona una data"
              errorMessage="Seleziona mese"
              mode={CALENDAR_MODE.MONTHLY}
              minDate={minDate}
              maxDate={maxDate}
            />
            <DatePicker
              value={monthEndDatePicker}
              onChange={(value) => setMonthEndDatePicker(value)}
              placeholder="Seleziona una data"
              errorMessage="Seleziona mese"
              mode={CALENDAR_MODE.MONTHLY}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <div className="grid grid-cols-2 mt-3 gap-y-1 gap-x-10 ">
            <p className="font-medium">Filtra per Cliente</p>
            <p className="font-medium">Filtra per Commessa</p>
            <SearchSelect
              isMulti
              options={clients.map((c) => ({
                label: c.des_cliente,
                value: c.cod_cliente,
              }))}
              value={selectedClient}
              onChange={(value) => setSelectedClient(value)}
            />
            <SearchSelect
              isMulti
              options={commesse.map((c) => ({
                label: c.cod_commessa + " - " + c.des_commessa,
                value: c.cod_commessa,
              }))}
              value={selectedCommessa}
              onChange={(value) => setSelectedCommessa(value)}
            />
          </div>
        </PageSection>
      </div>
      <div className="mb-4 -mt-7">
        <Tabs
          tabs={[
            {
              name: "Ricavi per FTE",
              value: 0,
            },
            {
              name: "Ricavi per giornata",
              value: 1,
            },
            {
              name: "Valori assoluti",
              value: 2,
            },
          ]}
          active={tabIndexChart}
          setActiveTab={setTabIndexChart}
        />
      </div>

      <div className="flex flex-col gap-y-4">
        {/*lg:flex-row lg:gap-x-4*/}
        {tabIndexChart === 0 ? (
          <div className="flex flex-col items-center">
            <RadioGroup
              options={[
                { value: "cumulative", name: "Cumulato" },
                { value: "rolling", name: "Rolling" },
              ]}
              currentValue={selectedViewRicaviFte}
              onChange={(value) => setSelectedViewRicaviFte(value)}
              horizontal
            />
            {chartDataRicaviFte && (
              <RicaviFteChart
                id={"ricavi-fte-chart"}
                data={
                  selectedViewRicaviFte === "cumulative"
                    ? chartDataRicaviFte.cumulato
                    : chartDataRicaviFte.rolling
                }
              />
            )}
          </div>
        ) : tabIndexChart === 1 ? (
          <div className="flex flex-col items-center">
            <RadioGroup
              options={[
                { value: "cumulative", name: "Cumulato" },
                { value: "rolling", name: "Rolling" },
                { value: "monthly", name: "Mensile" },
              ]}
              currentValue={selectedViewRicaviGiornata}
              onChange={(value) => setSelectedViewRicaviGiornata(value)}
              horizontal
            />
            {chartDataRicaviGiornata && (
              <RicaviGiornataChart
                id={"ricavi-giornata-chart"}
                data={
                  selectedViewRicaviGiornata === "cumulative"
                    ? chartDataRicaviGiornata.cumulato
                    : selectedViewRicaviGiornata === "rolling"
                    ? chartDataRicaviGiornata.rolling
                    : chartDataRicaviGiornata.monthly
                }
              />
            )}
          </div>
        ) : (
          <div className="flex flex-col w-full items-center">
            <div className="flex flex-col w-64 h-24 border-2 rounded-lg items-start px-5 py-2 shadow-md justify-center my-3">
              <p className="font-semibold">KPI Totali:</p>
              {data && chartData && (
                <>
                  <p>
                    Giorni lavorati:{" "}
                    {intl.formatNumber(
                      data.trend.reduce((acc, current) => {
                        return acc + current.val_ore_vendute_totali;
                      }, 0) / 8,
                      { maximumFractionDigits: 1 }
                    )}{" "}
                    gg
                  </p>
                  <p>
                    Totale Ricavi:{" "}
                    {intl.formatNumber(
                      data.trend.reduce((acc, current) => {
                        return acc + current.val_ricavi_totali;
                      }, 0)
                    )}{" "}
                    €
                  </p>
                </>
              )}
            </div>
            {chartData && (
              <ProductivityTrendChart
                id={"productivity-absolute-chart"}
                data={chartData}
              />
            )}
          </div>
        )}
      </div>
      <div className="mt-8">
        {tableData && (
          <ProductivityTable
            intl={intl}
            data={tableData}
            months={monthsNumber}
          />
        )}
      </div>
    </>
  );
}
