import React from "react";

// Styles
import Slider from "../../ui-components/Slider";

function CourseQuestion({question, courseQuestion, setCourseQuestion}) {

  let marks = [
    {value: 0, label: '0'},
    {value: 1, label: '1'},
    {value: 2, label: '2'},
    {value: 3, label: '3'},
    {value: 4, label: '4'},
    {value: 5, label: '5'}
  ];

  return (
    <div className="grid grid-cols-3 gap-5">
      <div className="col-span-2 mt-2">
        {question['des_question']}
      </div>
      <div className="col-span-1">
        <Slider
          min={0}
          max={5}
          step={1}
          value={question.eval}
          marks={marks}
          onChange={(e, val) => {
            const temp = courseQuestion.map((q) => {
              if (q['id_question'] === question['id_question']) {q.eval = val}
              return q;
            })
            setCourseQuestion(temp);
          }}
        >
        </Slider>
      </div>
    </div>);
}

export default CourseQuestion;
