import React, {useEffect, useState} from "react";
import _ from "lodash";

import CheckboxGroup from "@ui-components/CheckboxGroup";
import {RevenueDetailTable} from "./RevenueDetailTable";
import SearchSelect from "@ui-components/SearchSelect";
import {BestWorstCase} from "./BestWorstCase";
import {contoMark} from "@utils/constants";


export const RevenueDetailView = ({
                                    intl,
                                    data,
                                    selectedYear,
                                    currentMonth,
                                    pmList,
                                    selectedMark,
                                    setSelectedMark,
                                    selectedCustomers,
                                    setSelectedCustomers,
                                    selectedDeal,
                                    setSelectedDeal,
                                    rischiAnticipi,
                                    fetchRischiAnticipi
                                  }) => {

  const pms = pmList.concat(['Totale'])
  const [detail, setDetail] = useState();
  const months = [...Array(12).keys()]
  const optionCustomers = [...new Set(data.map(i => i.des_cliente))].sort().map(i => ({label: i, value: i}))
  const optionDeals = [...new Set(data.map(i => i.cod_commessa + ' - ' + i.des_commessa))].sort().map(i => ({
    label: i,
    value: i.split(' - ')[0]
  }))

  const getCommesseDetail = (df) => {
    let commesse = [...new Set(df.map(i => i.des_cliente + i.cod_commessa + i.des_conto))]
      .sort().reduce((obj, i) => _.set(obj, [i], {month_detail: {}}), {})

    df.forEach(i => {
      const k = i.des_cliente + i.cod_commessa + i.des_conto
      const cols = ['cod_commessa', 'des_commessa', 'des_cliente', 'des_conto']
      cols.forEach(c => {
        commesse[k][c] = i[c]
      })
      Object.entries(i.month_detail).forEach(([mString, value]) => {
        const m = Number(mString.substring(5, 7)) - 1
        commesse[k]['month_detail'][m] = (commesse[k]['month_detail'][m] || 0) + value
      })
    })
    return Object.values(commesse)
  }

  const isDealStatus = (c, d, m, s) => ((rischiAnticipi[c] || {})[d] || {})[m] === s

  useEffect(() => {
    let tmp = pms.reduce((obj, i) => _.set(obj, [i], {}), {})
    pms.forEach(pm => {
      let filteredData = selectedMark.length > 0 ? data.filter(i => selectedMark.includes(i.des_conto)) : data
      if (selectedCustomers.length > 0) {
        filteredData = filteredData.filter(i => selectedCustomers.map(c => c.value).includes(i.des_cliente))
      }
      if (selectedDeal.length > 0) {
        filteredData = filteredData.filter(i => selectedDeal.map(c => c.value).includes(i.cod_commessa))
      }
      const dataPm = pm === 'Totale' ? filteredData : filteredData.filter(i => i.cod_pm === pm)
      tmp[pm]['commesse'] = pm === 'Totale' ? [] : getCommesseDetail(dataPm)
      months.forEach(m => {
        const k = `${selectedYear}-${(m + 1).toString().padStart(2, '0')}-01`
        const amt = dataPm.reduce((acc, i) => acc + (i.month_detail[k] || 0), 0)
        tmp[pm][m] = {
          total: amt,
          cumulative: (tmp[pm][m - 1]?.cumulative || 0) + amt,
          rischi: dataPm.reduce((acc, i) => acc + (isDealStatus(i.cod_commessa, i.des_conto, k, 'RISCHIO') ? i.month_detail[k] || 0 : 0), 0),
          anticipi: dataPm.reduce((acc, i) => acc + (isDealStatus(i.cod_commessa, i.des_conto, k, 'ANTICIPO') ? i.month_detail[k] || 0 : 0), 0)
        }
      })
    })
    setDetail(tmp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedMark, selectedCustomers, selectedDeal, rischiAnticipi])


  return (
    <div className="mt-6 flex flex-col mb-10 gap-4">
      <div className="w-full flex justify-center gap-5">
        <div className="w-full">
          <SearchSelect
            label="Filtra per cliente"
            isMulti
            value={selectedCustomers}
            options={optionCustomers}
            onChange={(e) => setSelectedCustomers(e)}
          />
        </div>
        <div className="w-full">
          <SearchSelect
            label="Filtra per commessa"
            isMulti
            value={selectedDeal}
            options={optionDeals}
            onChange={(e) => setSelectedDeal(e)}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <CheckboxGroup
          horizontal
          options={Object.keys(contoMark).map(i => ({value: i, name: i}))}
          values={selectedMark}
          onChange={(value) => {
            if (selectedMark.indexOf(value) >= 0) {
              setSelectedMark([...selectedMark.filter((v) => v !== value)]);
            } else {
              setSelectedMark([...selectedMark, value]);
            }
          }}
        />
      </div>
      <BestWorstCase
        intl={intl}
        detail={detail}
        rischiAnticipi={rischiAnticipi}
        currentMonth={currentMonth}
        selectedYear={selectedYear}
      />
      {detail && data.length ?
        <RevenueDetailTable
          pms={pms}
          intl={intl}
          months={months}
          detail={detail}
          selectedYear={selectedYear}
          currentMonth={currentMonth}
          rischiAnticipi={rischiAnticipi}
          fetchRischiAnticipi={fetchRischiAnticipi}
        />
        : null
      }
    </div>);
}
