import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, CLOUD_OU_COLORS, getXYChart} from "../../../components/Charts/commons";


function CloudTrendBarChart({id, data, measure, organizationalUnits}) {

  useLayoutEffect(
    () => {

      if (!data)
        return

      addLicense();
      let root = am5.Root.new(id);
      root.setThemes([am5themes_Animated.new(root)])
      root.locale = am5locales_it_IT;
      root.numberFormatter.set("numberFormat", measure === 'amt' ? "#,###.$" : "#,###.");
      root.dateFormatter.set("dateFormat", "MMM yyyy")

      // Create chart
      let chart = getXYChart(root, {layout: root.verticalLayout})

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 10,
        height: 10
      });

      legend.labels.template.setAll({
        fontSize: 10,
        fontWeight: "300"
      });

      // Create axes
      // CategoryAxis: is the only axis type that requires its own data as well as data field set up.
      let xAxis = chart.xAxes.push(
        chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {timeUnit: 'month', count: 1},
            gridIntervals: [{timeUnit: "month", count: 1}],
            renderer: am5xy.AxisRendererX.new(root, {}),
          })
        ))
      xAxis.data.setAll(data);
      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.set("fontSize", 10);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.1,
        renderer: am5xy.AxisRendererY.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        calculateTotals: true
      }))
      let yRenderer = yAxis.get("renderer");
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.labels.template.set("forceHidden", true);


      // Add series
      function makeSeries(name, forecasting = false) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          fill: CLOUD_OU_COLORS[name],
          valueYField: forecasting ? `forecast_${name}` : `${measure}_${name}`,
          valueXField: "month",
          stacked: true,
          legendLabelText: forecasting ? 'Forecast' : name
        }))

        series.columns.template.setAll({
          width: am5.percent(60)
        });

        series.columns.template.setAll({
          tooltipText: `{valueX.formatDate()}\n[bold]${forecasting ? 'Forecasting' : ''} {name}: {valueY}[/]`,
          tooltipY: am5.percent(10)
        });

        if (forecasting) {
          series.columns.template.set("fillPattern", am5.LinePattern.new(root, {
            color: am5.color(0xffffff),
            fillOpacity: 0.5,
            rotation: 45,
            width: 200,
            height: 200
          }));
        }

        if (name === organizationalUnits[organizationalUnits.length -1] && (measure !== 'amt' || forecasting)) {
          series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: "[bold]{valueYTotal}[/]",
                rotation: measure === 'num' ? 0 : -70,
                centerX: measure === 'num' ? am5.p50 : am5.p0,
                centerY: am5.percent(90),
                populateText: true,
                fontSize: 10
              })
            });
          });
        }

        series.data.processor = am5.DataProcessor.new(root, {
          dateFields: ['month'],
          dateFormat: "dd/MM/yyyy"
        })

        if (!forecasting || legend.data.length < organizationalUnits.length + 1) {
          legend.data.push(series);
        }
        series.data.setAll(data);
        series.appear(1000);

        return series
      }

      let allSeries = {measure: {}, forecast: {}}
      organizationalUnits.forEach(ou => {
        allSeries.measure[ou] = makeSeries(ou)
      })
      if (measure === 'amt') {
        organizationalUnits.forEach(ou => {
          allSeries.forecast[ou] = makeSeries(ou, true)
          allSeries.measure[ou].on("visible", function (visible) {
            if (visible) {
              allSeries.forecast[ou].show()
            } else {
              allSeries.forecast[ou].hide()
            }
          })
        })

        allSeries.forecast[organizationalUnits[0]].on("visible", function (visible) {
          if (visible) {
            organizationalUnits.forEach(i => {
              allSeries.forecast[i].show()
            })
          } else {
            organizationalUnits.forEach(i => {
              allSeries.forecast[i].hide()
            })
          }
        })
      }

      return () => {
        root.dispose()
      }
    },
    [id, data, organizationalUnits] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const height = measure === 'num' ? 'h-64' : 'h-96'

  return <div id={id} className={`-mt-5 w-full ${height}`}/>
}

export default React.memo(CloudTrendBarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.measure, nextProps.measure)
})
