import React, {useContext, useEffect, useState} from "react";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext/DataContext";
import apiRequest from "../../services/apiRequest";
import {useIntl} from "react-intl";
// Components
import {PageSection} from "../../ui-components/Container";
import Okr from "../../components/Okr/Okr";

// Ui
import {LifebuoyIcon} from "@heroicons/react/24/outline"
import PersonalHome from "./personalHome";
import StaffHome from "./staffHome";


function Home() {

  const intl = useIntl();
  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);
  const userData = AuthenticationService.getUserData();

  // OKR DATA UTILITY

  const [okr, setOkr] = useState();

  const fetchMyOkrs = () => {
    apiRequest
      .get("/okrs/task-user")
      .then((result) => {
        setOkr(result);
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }

  useEffect(() => {
    fetchMyOkrs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="HomePageContainer">
      {userData.role_name === 'base'
        ? <PersonalHome intl={intl} push={push} dataContext={dataContext} userData={userData} />
        : <StaffHome intl={intl} push={push} userData={userData} />
      }
      <div>
        <PageSection title={intl.formatMessage({id: "my_okr"})}>
          <div className="pt-3 grid grid-cols-3 w-full gap-5">
            {okr && (okr || []).length ?
              ((okr || []).map((singleOkrSet, i) => {
                return (
                  <Okr key={i} okr={singleOkrSet} renderTasks={false} renderProgress={true}
                       visibility={singleOkrSet["visibility"]}/>
                )
              })) :
              <div className="Placeholder flex opacity-40 mt-10">
                <LifebuoyIcon className="h-16 w-16"/>
                <div className="PlaceholderText flex font-bold m-5">
                  {intl.formatMessage({id: "no_okr_message"})} <a href={"/okr"} className="ml-1 ">OKR!</a>
                </div>
              </div>
            }
          </div>
        </PageSection>
      </div>
    </div>
  );
}

export default Home;
