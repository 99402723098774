// Api URL based on env
export const API_URL = "/api";

export const CALENDAR_MODE = {
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly"
}

export const currencyStyleUsd = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 0
}

export const currencyStyleEur = {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 0
}

export const contoMark = {
  'Prestazioni di servizi': '',
  'Delta (Prestazioni)': '',
  'Canoni': '(C)',
  'Delta (Canoni)': '(C)',
}