import React,{ useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { addLicense, getXYChart } from "../../../components/Charts/commons";
import _ from "lodash";

function ProductivityTrendChart({ id, data }) {
  useLayoutEffect(() => {
    if (!data) return;
    
    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root.locale = am5locales_it_IT;
    root.numberFormatter.set("numberFormat", "#,###.");
    root.dateFormatter.set("dateFormat", "MMM yyyy");

    let chart = getXYChart(root, { layout: root.verticalLayout });
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.markers.template.setAll({
      width: 12,
      height: 12,
    });

    legend.labels.template.setAll({
      fontSize: 13,
      fontWeight: "400",
    });

    let xAxis = chart.xAxes.push(
      chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: "month", count: 1 },
          gridIntervals: [{ timeUnit: "month", count: 1 }],
          renderer: am5xy.AxisRendererX.new(root, {}),
        })
      )
    );

    xAxis.data.setAll(data);
    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.set("fontSize", 12);

    let yAxis = chart.yAxes.push(
      chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      )
    );
    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.set("fontSize", 12);
    yRenderer.labels.template.set("fontWeight", "100");
    yRenderer.grid.template.set("forceHidden", true);

    yAxis.children.unshift(
      am5.Label.new(root, {
        text: "Giorni",
        textAlign: "center",
        y: am5.p50,
        rotation: -90,
        fontWeight: "bold",
      })
    );

    let yAxis2 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        synchWithAxis: yAxis,
        renderer: am5xy.AxisRendererY.new(root, { opposite: true }),
      })
    );

    let yRenderer2 = yAxis2.get("renderer");
    yRenderer2.labels.template.set("fontSize", 12);
    yRenderer2.grid.template.set("forceHidden", true);

    yAxis2.children.push(
      am5.Label.new(root, {
        text: "Ricavi",
        textAlign: "center",
        y: am5.p50,
        rotation: 90,
        fontWeight: "bold",
      })
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "val_giorni",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "val_giorni",
        valueXField: "date",
        legendLabelText: "Giorni lavorati",
        fill: am5.color("rgb(222,164,54)"),
        stroke: am5.color("rgb(222,164,54)"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "{valueX.formatDate()}\n[bold]Giorni: {valueY}",
          tooltipY: am5.percent(10),
          fontSize: 8,
        }),
      })
    );

    series.columns.template.setAll({
      fillOpacity: 0.9,
      strokeOpacity: 0,
    });

    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["date"],
      dateFormat: "yyyy/MM/dd",
    });

    let lineSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "val_ricavi",
        xAxis: xAxis,
        yAxis: yAxis2,
        valueYField: "val_ricavi",
        valueXField: "date",
        legendLabelText: "Ricavi",
        fill: chart.get("colors").getIndex(15),
        stroke: chart.get("colors").getIndex(15),
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]Ricavi: {valueY}",
          tooltipY: am5.percent(10),
          fontSize: 8,
        }),
      })
    );

    lineSeries.strokes.template.set("strokeWidth", 2);

    lineSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        locationX: undefined,
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: lineSeries.get("fill"),
        }),
      });
    });

    legend.data.setAll(chart.series.values);

    series.data.setAll(data);
    lineSeries.data.setAll(data);
    series.appear(1000);
    lineSeries.appear(1000);

    return () => {
      root.dispose();
    };
  }, [id, data]);

  return <div id={id} className="w-full h-96" />;
}

export default React.memo(ProductivityTrendChart, (prev, next) => {
  return _.isEqual(prev.data, next.data);
})
