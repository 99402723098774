import React, {useState} from "react";
import moment from 'moment';
import DatePicker from "../../../ui-components/DatePicker";

export const CustomEditDatePicker = ({ value, onChange }) => {
  return <div className="w-32">
    <DatePicker
      value={value ? new Date(value) : new Date()}
      minDate={null}
      onChange={(e) => onChange(moment(e).format("YYYY-MM-DD"))}
      showErrorDialog={false}
      materialTable={true}
    />
  </div>
}

export const CustomFilterDatePicker = (props) => {
  const [date, setDate] = useState(null);
  return <div className="w-32">
    <DatePicker
      value={date}
      minDate={null}
      onChange={(event) => {
        setDate(event);
        props.onFilterChanged(props.columnDef.tableData.id, event);
      }}
      onClear={()=> {
        setDate(null);
        props.onFilterChanged(props.columnDef.tableData.id, null);
      }}
      showErrorDialog={false}
      materialTable={true}
    />
  </div>
}