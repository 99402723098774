import React, {useContext, useEffect, useState} from "react";
import Badge from "../../ui-components/Badge";
import {PageSection} from "../../ui-components/Container";
import apiRequest from "../../services/apiRequest";
import {DataContext} from "../../services/DataContext/DataContext";
import {NotificationsContext} from "../../ui-components/Notifications";
import SearchSelect from "../../ui-components/SearchSelect";
import Button from "../../ui-components/Button";

function ProjectSkill({id_proj, editMode, intl}) {
  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);

  const [projSkill, setProjSkill] = useState([]);
  const [multipleSkill, setMultipleSkill] = useState([]);

  const options = (dataContext.skillList || []).map(i => ({value: i.id_skill, label: i.des_skill}))

  const insert_skill = () => {
    const newList = [...new Set(multipleSkill.map(i => i.value).concat(projSkill))]
    apiRequest
      .post(`/projects/explain/${id_proj}`, newList.map(i => ({id_skill: i})))
      .then(() => {
        push({title: intl.formatMessage({id: "skills_added"}), type: "success"})
        setProjSkill(newList)
        setMultipleSkill([])
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
      })
  }

  const remove_skill = () => {

    const toDelete = multipleSkill
      .filter((e) => (projSkill.includes(e.value)))
      .map(i => i.value);

    apiRequest
      .post(`/projects/explain/remove_skills/${id_proj}`, toDelete.map(i => ({id_skill: i})))
      .then(() => {
        push({title: intl.formatMessage({id: "skills_deleted"}), type: "success"})
        setProjSkill(projSkill.filter(i => !toDelete.includes(i)))
        setMultipleSkill([])
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
      })
  }


  // Get skill associated to a project
  const fetchProjectSkill = () => {
    apiRequest
      .get(`/projects/explain/${id_proj}`)
      .then((result) => {
        const skill = (result || []).map((x) => x.id_skill);
        setProjSkill(skill);
      })
      .catch(() => {
        console.log("ERROR")
      })

  }

  // Check if an object has undefined value
  const checkUndefinedValue = (state) => {
    if (state == null) {
      return true;
    }
    Object.keys(state).forEach((key) => {
      if (state[key] == null) {
        return true;
      }
    })
    return false;
  }

  useEffect(() => {
    if (id_proj) {
      fetchProjectSkill();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_proj])

  return (
    <div className="">
      {!checkUndefinedValue(projSkill) && (
        <PageSection title="Skills (WIP, don't use it)">
          <div className="grid-cols-2 gap-x-0.5">
            {projSkill.map((e) => {
              // console.log(e)
              return (<Badge text={e} type="success" classNames="mx-1 my-1"/>)
            })}
          </div>
          {editMode && (
            <div className="flex flex-col w-full gap-2 pt-2">
              {/*TODO: we must change this select, maybe a table in a modal where to click all skills, grouped by area, topic, ect*/}
              <SearchSelect
                isMulti
                value={multipleSkill}
                onChange={(value) => {
                  setMultipleSkill(value)
                }
                }
                options={options}/>
              <div className="flex flex-row justify-between p-2">
                <Button
                  label="Add skill"
                  styleType="secondary"
                  onClick={insert_skill}
                >
                  {intl.formatMessage({id: "add_skill"})}
                </Button>
                <Button
                  label="Remove skill"
                  styleType="secondary"
                  onClick={remove_skill}
                >
                  {intl.formatMessage({id: "remove_skill"})}
                </Button>
              </div>
            </div>
          )
          }
        </PageSection>
      )}
    </div>
  );
}

export default ProjectSkill;