import {
  AdjustmentsVerticalIcon,
  ArchiveBoxIcon,
  CogIcon,
  HomeIcon,
  BuildingLibraryIcon,
  CurrencyEuroIcon,
  LifebuoyIcon,
  EyeIcon
  } from "@heroicons/react/24/outline";

import Home from "../pages/Home";
import Project from "../pages/Project";
import Evaluation from "../pages/Evaluation";
import OkrPage from "../pages/Okr";
import Courses from "../pages/Courses";
import OrganizationChart from "../pages/Settings/OrganizationChart";
import AttendanceReport from "../pages/Settings/AttendanceReport";
import Taxonomy from "../pages/Settings/Taxonomy";
import CloudCostAnalysis from "../pages/Economics/Cloud";
import DashboardRevenue from "../pages/Economics/Revenue";
import DashboardGescli from "../pages/Economics/Gescli";
import DashboardProductivity from "../pages/Economics/Productivity";
import {Monitoring} from "@pages/Monitoring";
//import FrequentElements from "../pages/FrequentElements";
//import TableExamples from "../pages/TableExamples";

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home
  },
  {
    name: "monitoring",
    href: "/monitoring",
    Icon: EyeIcon,
    component: Monitoring
  },
  {
    name: "Economics",
    Icon: CurrencyEuroIcon,
    dropdown: [
      {
        scopes: ["revenue:read"],
        href: "/economics/revenue",
        name: "revenue",
        component: DashboardRevenue,
      },
      {
        scopes: ["gescli:read"],
        href: "/economics/gescli",
        name: "gescli",
        component: DashboardGescli,
      },
      {
        scopes: ["cloud:read"],
        href: "/economics/cloud",
        name: "cloud",
        component: CloudCostAnalysis,
      },
      {
        scopes: ["productivity:read"],
        href: "/economics/productivity",
        name: "productivity",
        component: DashboardProductivity,
      }
    ],
  },
  {
    name: "Courses",
    href: "/course",
    Icon: ArchiveBoxIcon,
    component: Courses
  },
  {
    name: "OKRs",
    href: "/okr",
    Icon: LifebuoyIcon,
    component: OkrPage
  },
  {
    name: "Evaluation",
    href: "/eval",
    scopes: ["evaluation:read"],
    Icon: AdjustmentsVerticalIcon,
    component: Evaluation
  },
  {
    name: "Projects",
    href: "/project",
    Icon: BuildingLibraryIcon,
    component: Project
  },
  {
    name: "Settings",
    Icon: CogIcon,
    dropdown: [
      {
        scopes: ["organization_chart:read"],
        href: "/settings/organization-chart",
        name: "organization_chart",
        component: OrganizationChart,
      },
      {
        scopes: ["attendance_report:read"],
        href: "/settings/attendance-report",
        name: "attendance_report",
        component: AttendanceReport,
      },
      {
        scopes: ["skills:create"],
        href: "/settings/skills",
        name: "taxonomy",
        component: Taxonomy,
      }
    ],
  },
  /*{
    name: "Frequent Elements",
    href: "/frequent-elements",
    Icon: BuildingLibraryIcon,
    component: FrequentElements
  },
  {
    name: "Tables",
    href: "/tables",
    Icon: BuildingLibraryIcon,
    component: TableExamples
  },*/
];

export default routes;
