export const COLUMNS = [{

    title: "Area", field: "id_area", type: "string", width: "10%",
}, {
    title: "Type", field: "id_type", type: "string", width: "12%",
}, {
    title: "Topic", field: "id_topic", type: "string", width: "25%",
}, {
    title: "Skill", field: "id_skill", type: "string", width: "27%",
}]

export const OPTIONS = {
    filtering: true,
    toolbar: true,
    paging: true,
    pageSize: 5,
    sorting: true,
    searchFieldStyle: {width: "100%"},
    showTitle: false,
    filterCellStyle: {paddingTop: 8, maxWidth: 200},
    cellStyle: {paddingBottom: 5},
    actionsColumnIndex: -1
};

export const LOCALIZATION = {
    header: {
        actions: ""
    },
    pagination: {
        labelDisplayedRows: "{from}-{to} of {count} rows",
        labelRowsSelect: "Rows",
    }
};
