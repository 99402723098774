import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import { currencyStyleEur } from "@utils/constants";
import SearchSelect from "@ui-components/SearchSelect";
import { useState, Fragment, useEffect } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/solid";
import Toggle from "@ui-components/Toggle";

const arrowSortDesc = () => (
  <div className="text-white">
    <ArrowDownIcon width="12" height="12" />
  </div>
);
const arrowSortAsc = () => (
  <div className="text-white">
    <ArrowUpIcon width="12" height="12" />
  </div>
);
function ProductivityTable({ intl, data, months }) {
  const [showDetail, setShowDetail] = useState(
    new Array(data.length).fill(false)
  );

  const [selectedResource, setSelectedResource] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [options, setOptions] = useState([]);
  const [sort, setSort] = useState("");
  const [sortDirection, setSortDirection] = useState({
    cod_team: "asc",
    cod_risorsa: "asc",
    val_giorni: "asc",
    val_ricavi: "asc",
    val_ricavi_giorno: "asc",
    val_ricavi_fte: "asc",
  });
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setOptions(
      data.map((d) => ({
        label: d.cod_risorsa.split(".").join(" "),
        value: d,
      }))
    );
  }, [data]);

  useEffect(() => {
    selectedResource.length > 0
      ? setTableData(
          data.filter((d) => {
            const resources = selectedResource.map((r) => r.value.cod_risorsa);
            return resources.includes(d.cod_risorsa);
          })
        )
      : setTableData(data);
  }, [selectedResource, data]);

  const sortTable = (data, column) => {
    let sortedData = [...data];
    let direction = sortDirection[column] === "asc" ? "desc" : "asc";
    setSortDirection({ ...sortDirection, [column]: direction });
    switch (column) {
      case "cod_team":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.cod_team.localeCompare(b.cod_team)
            : b.cod_team.localeCompare(a.cod_team)
        );
        break;
      case "val_giorni":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.val_ore_vendute - b.val_ore_vendute
            : b.val_ore_vendute - a.val_ore_vendute
        );
        break;
      case "val_ricavi":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.val_ricavi - b.val_ricavi
            : b.val_ricavi - a.val_ricavi
        );
        break;
      case "cod_risorsa":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.cod_risorsa.localeCompare(b.cod_risorsa)
            : b.cod_risorsa.localeCompare(a.cod_risorsa)
        );
        break;
      case "val_ricavi_giorno":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.val_ricavi / (a.val_ore_vendute / 8) -
              b.val_ricavi / (b.val_ore_vendute / 8)
            : b.val_ricavi / (b.val_ore_vendute / 8) -
              a.val_ricavi / (a.val_ore_vendute / 8)
        );
        break;
      case "val_ricavi_fte":
        sortedData.sort((a, b) =>
          direction === "asc"
            ? a.val_ricavi /
                Object.keys(a.detail).reduce((acc, current) => {
                  return acc + a.detail[current].fte / months;
                }, 0) -
              b.val_ricavi /
                Object.keys(b.detail).reduce((acc, current) => {
                  return acc + b.detail[current].fte / months;
                }, 0)
            : b.val_ricavi /
                Object.keys(b.detail).reduce((acc, current) => {
                  return acc + b.detail[current].fte / months;
                }, 0) -
              a.val_ricavi /
                Object.keys(a.detail).reduce((acc, current) => {
                  return acc + a.detail[current].fte / months;
                }, 0)
        );
        break;
      default:
        break;
    }
    setTableData(sortedData);
  };

  const renderArrow = (column) => {
    if (sort === column) {
      return sortDirection[column] === "asc" ? arrowSortAsc() : arrowSortDesc();
    }
    return null;
  };

  const monthDetail = (d) =>
    Object.keys(d.detail)
      .sort()
      .map((date) => (
        <tr key={date} className="odd:bg-white even:bg-gray-200 font-bold">
          <td></td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            FTE: {d.detail[date].fte}
          </td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            {intl.formatDate(new Date(date), {
              month: "long",
              year: "2-digit",
            })}
          </td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            {intl.formatNumber(d.detail[date].val_ore_vendute_mese / 8, {
              maximumFractionDigits: 1,
            })}
          </td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            {intl.formatNumber(
              d.detail[date].val_ricavi_mese,
              currencyStyleEur
            )}
          </td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            {intl.formatNumber(
              d.detail[date].val_ricavi_mese /
                (d.detail[date].val_ore_vendute_mese / 8),
              currencyStyleEur
            )}
          </td>
          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 uppercase">
            {intl.formatNumber(
              d.detail[date].val_ricavi_mese / d.detail[date].fte,
              currencyStyleEur
            )}
          </td>
        </tr>
      ));

  const commessaDetail = (d) =>
    Object.keys(d.detail_commesse).map((commessa) => (
      <tr key={commessa} className="odd:bg-white even:bg-gray-200">
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900"></td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {commessa}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          <div className="flex items-center">
            {d.detail_commesse[commessa].des_cliente}{" "}
            <ArrowRightIcon className="h-3 w-3 mx-1" />{" "}
            {d.detail_commesse[commessa].des_commessa}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {intl.formatNumber(
            d.detail_commesse[commessa].val_ore_vendute_commessa / 8,
            { maximumFractionDigits: 1 }
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {intl.formatNumber(
            d.detail_commesse[commessa].val_ricavi_commessa,
            currencyStyleEur
          )}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
          {intl.formatNumber(
            d.detail_commesse[commessa].val_ricavi_commessa /
              (d.detail_commesse[commessa].val_ore_vendute_commessa / 8),
            currencyStyleEur
          )}
        </td>
        <td></td>
      </tr>
    ));

  const renderDetail = (d) => {
    return toggle ? commessaDetail(d) : monthDetail(d);
  };

  return (
    <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 flex flex-col h-70">
      <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 flex-grow overflow-auto">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <span className="text-xl font-medium -mb-4">Dettaglio Risorse</span>
            <div className="flex flex-row items-center text-sm">
              <span className="mr-2 mt-2">Mostra dettaglio per commesse</span>
              <Toggle checked={toggle} onChange={() => setToggle(!toggle)} />
            </div>
          </div>

          <div className="flex flex-row items-center">
            <p className="mx-2 text-base">Filtra Risorsa</p>
            <SearchSelect
              options={options}
              value={selectedResource}
              onChange={(value) => setSelectedResource(value)}
              isMulti
            />
          </div>
        </div>
        <div className="w-full border-t border-gray-300 -mt-1 mb-3" />

        <table className="min-w-full">
          <thead>
            {tableData && (
              <tr className="font-bold">
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase"
                ></th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("cod_team");
                    sortTable(tableData, "cod_team");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    team {renderArrow("cod_team")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("cod_risorsa");
                    sortTable(tableData, "cod_risorsa");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    risorsa {renderArrow("cod_risorsa")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("val_giorni");
                    sortTable(tableData, "val_giorni");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    giorni lavorati {renderArrow("val_giorni")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("val_ricavi");
                    sortTable(tableData, "val_ricavi");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    ricavi {renderArrow("val_ricavi")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("val_ricavi_giorno");
                    sortTable(tableData, "val_ricavi_giorno");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    ricavi per giornata {renderArrow("val_ricavi_giorno")}
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3  bg-cyan-900 text-left text-xs leading-4  text-white uppercase cursor-pointer"
                  onClick={() => {
                    setSort("val_ricavi_fte");
                    sortTable(tableData, "val_ricavi_fte");
                  }}
                >
                  <div className="flex flex-row items-center">
                    {" "}
                    ricavi per fte {renderArrow("val_ricavi_fte")}
                  </div>
                </th>
              </tr>
            )}
          </thead>
          <tbody>
            {tableData &&
              tableData.map((d, i) => (
                <Fragment key={d.cod_risorsa + d.cod_team}>
                  <tr
                    key={i}
                    className="odd:bg-white even:bg-gray-200 font-bold"
                  >
                    <td className="px-6 py-4 w-1 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                      <button
                        onClick={() =>
                          setShowDetail({ ...showDetail, [i]: !showDetail[i] })
                        }
                      >
                        {showDetail[i] ? (
                          <MinusIcon
                            className="h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusIcon
                            className="h-5 w-5 text-black"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {d.cod_team}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {d.cod_risorsa.split(".").join(" ")}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {intl.formatNumber(d.val_ore_vendute / 8, {
                        maximumFractionDigits: 1,
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {intl.formatNumber(d.val_ricavi, currencyStyleEur)}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {intl.formatNumber(
                        d.val_ricavi / (d.val_ore_vendute / 8),
                        currencyStyleEur
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-black">
                      {intl.formatNumber(
                        d.val_ricavi /
                          Object.keys(d.detail).reduce((acc, current) => {
                            return acc + d.detail[current].fte / months;
                          }, 0),
                        currencyStyleEur
                      )}
                    </td>
                  </tr>
                  {showDetail[i] && renderDetail(d)}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export { arrowSortAsc, arrowSortDesc };
export default ProductivityTable;
