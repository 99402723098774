import { useEffect, useState } from "react";
import { api } from "@services/apiRequest";
import MaterialTable from "@material-table/core";
import TableStyle from "@ui-components/TableStyle";
import { tableIcons } from "@ui-components/TableIcons";

const columns = [
  {
    title: "ID",
    field: "id_role",
    type: "numeric",
    hidden: true,
  },
  {
    title: "Ruolo",
    field: "role_name",
    type: "string",
    editable: "never",
  },
  {
    title: "Descrizione",
    field: "des_role",
    type: "string",
    editable: "never",
  },
  {
    title: "Costo",
    field: "role_cost",
    type: "numeric",
    render: (rowData) => {
      return rowData.role_cost.toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      });
    },
    cellStyle: {
      textAlign: "left",
    },
  },
];

const OPTIONS = {
  pageSize: 10,
  searchFieldAlignment: "right",
  searchFieldStyle: {
    marginTop: "3px",
  },
  showTitle: true,
  toolbar: true,
};

export default function DashboardRoles({ push, fetchMarginalityData }) {
  const [rolesData, setRolesData] = useState(undefined);

  const fetchRolesData = () => {
    api
      .get(`/roles-almagamma/roles`)
      .then(({ data: result }) => {
        setRolesData(result.filter((item) => item.role_cost > 0));
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  useEffect(() => {
    if (!rolesData) {
      fetchRolesData();
    }
  });

  const onRowUpdate = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      let isEqual = true;
      Object.entries(newData).forEach(([key, value]) => {
        if (oldData[key] !== value) {
          isEqual = false;
        }
      });
      if (isEqual) {
        resolve();
      } else {
        api
          .post(`/roles-almagamma/roles/${oldData.role_name}`, newData)
          .then(({ data: result }) => {
            const updatedRolesData = rolesData.map((item) =>
              item.role_name === result.role_name ? result : item
            );
            setRolesData(updatedRolesData);
            push({ title: "Riga aggiornata con successo", type: "success" });
            resolve();
            fetchMarginalityData();
          })
          .catch(() => {
            push({
              title: "Errore durante l'aggiornamento della riga",
              type: "error",
            });
            reject();
          });
      }
    });
  };

  return (
    <div className="flex flex-col mt-5">
      {rolesData && (
        <TableStyle>
          <MaterialTable
            title={"Ruoli Almagamma"}
            columns={columns}
            data={rolesData}
            icons={tableIcons}
            options={OPTIONS}
            isLoading={rolesData === undefined}
            editable={{
              onRowUpdate: onRowUpdate,
            }}
            localization={{
              header: {
                actions: "Azioni",
              },
            }}
          />
        </TableStyle>
      )}
    </div>
  );
}
