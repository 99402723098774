import React, {Fragment, useEffect, useState} from "react";
import {currencyStyleEur} from "../../../utils/constants";
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/24/solid";


const headerStyle = 'whitespace-nowrap bg-gray-50 p-2 text-left text-sm font-semibold text-gray-900 sm:pl-3'
const rowStyle = 'p-2 text-left text-sm font-medium text-gray-500 sm:pl-3'

const labels = {
  contab: "Contabilizzato",
  fcst: "Previsto",
  delta: "Delta",
  committed: "Committed",
}

export const RevenueTotalTable = ({
                                    intl,
                                    months,
                                    categories,
                                    totalKpis,
                                    selectedYear,
                                    currentMonth,
                                    currentYear,
                                    selectedView
                                  }) => {

  const mainCats = categories.slice(0, 2).concat('Totale')
  const [canoniOpen, setCanoniOpen] = useState(false);
  const [shownCategories, setShownCategories] = useState(mainCats)

  useEffect(() => {
    if (canoniOpen) {
      setShownCategories(categories)
    } else {
      setShownCategories(mainCats)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canoniOpen])


  const headerTotalCell = (key, category, total, totalPy) => {
    const YoY = totalPy ? ((total - totalPy) / totalPy * 100) : undefined
    return (
      <th key={key} className={`${headerStyle} ${key === 'year' ? 'border-l-2 border-gray-900' : ''}`}>
        <div className={`flex flex-col ${mainCats.includes(category) ? '' : 'text-xs'}`}>
        <span>
          {intl.formatNumber(total, currencyStyleEur)}
        </span>
          <span className="text-xs italic text-gray-600">
          ({intl.formatNumber(totalPy, currencyStyleEur)})
        </span>
          <span className={`text-xs italic ${YoY ? YoY > 0 ? 'text-green-700' : 'text-red-700' : 'text-gray-600'}`}>
          {YoY ? `${YoY.toFixed(1)} %` : '-'}
        </span>
        </div>
      </th>
    )
  }

  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="min-w-full">
          <thead className="bg-white">
          <tr>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only">Category</span>
            </th>
            {selectedView !== 'quarterly'
              ? months.map(m => (
                <th key={m} scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap capitalize">
                  {intl.formatDate(new Date(selectedYear, m, 1), {month: "short", year: "2-digit"})}
                </th>
              ))
              : [1, 2, 3, 4].map(q => (
                <th key={q} scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap capitalize">
                  {`Q${q}`}
                </th>
              ))
            }
            {selectedView !== 'cumulative' &&
              <th scope="col"
                  className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900 border-l-2 border-gray-900">
                {selectedYear}
              </th>
            }
          </tr>
          </thead>
          <tbody className="bg-white">
          {shownCategories.map((category, index) => (
            <Fragment key={category}>
              <tr
                className={category === 'Totale' || index === 0 ? "border-t-2 border-gray-900" : "border-t border-gray-200"}>
                <th
                  className={`${headerStyle} ${category === 'Canoni' ? 'cursor-pointer' : ''}`}
                  onClick={category === 'Canoni' ? () => setCanoniOpen(!canoniOpen) : null}
                >
                  {category === 'Canoni'
                    ?
                    <div className="flex flex-row">
                      <span className="mr-1 -ml-1">
                        {canoniOpen
                          ? <ChevronDownIcon height="18" width="18"/>
                          : <ChevronRightIcon height="18" width="18"/>
                        }
                      </span>
                      <span>{category}</span>
                    </div>
                    : <div className={`${mainCats.includes(category) ? '' : 'text-xs pl-10'}`}>{category}</div>
                  }
                </th>
                {selectedView !== 'quarterly'
                  ? months.map(m => (
                    headerTotalCell(
                      m,
                      category,
                      totalKpis[category][selectedView][m]['total'],
                      totalKpis[category][selectedView][m]['totalPy']
                    )
                  ))
                  : [1, 2, 3, 4].map(q => (
                    headerTotalCell(
                      q,
                      category,
                      (totalKpis[category]['absolute'][(q - 1) * 3]['total'] || 0) + (totalKpis[category]['absolute'][(q - 1) * 3 + 1]['total'] || 0) + (totalKpis[category]['absolute'][(q - 1) * 3 + 2]['total'] || 0),
                      (totalKpis[category]['absolute'][(q - 1) * 3]['totalPy'] || 0) + (totalKpis[category]['absolute'][(q - 1) * 3 + 1]['totalPy'] || 0) + (totalKpis[category]['absolute'][(q - 1) * 3 + 2]['totalPy'] || 0),
                    )
                  ))
                }
                {selectedView !== 'cumulative' &&
                  headerTotalCell(
                    'year',
                    category,
                    totalKpis[category]['cumulative'][11]['total'],
                    totalKpis[category]['cumulative'][11]['totalPy']
                  )
                }
              </tr>
              {Object.keys(labels).map(det => (
                <Fragment key={det}>
                  {['fcst', 'delta', 'committed'].includes(det) &&
                    (selectedYear < currentYear || det !== 'fcst') &&
                  !Object.values(totalKpis[category]['absolute']).reduce((acc, i) => acc + i[det], 0)
                    ? null :
                    <tr key={category + det}>
                      <td className={rowStyle}>
                        <div className={`${mainCats.includes(category) ? 'pl-4' : 'pl-10 text-xs'}`}>
                          {det === 'delta' && ['Canoni', 'Manutenzione', 'Licenze', 'Hosting'].includes(category)
                            ? 'Delta (e rinnovi)' : labels[det]
                          }
                        </div>
                      </td>
                      {selectedView !== 'quarterly'
                        ? months.map(m => (
                          <td key={m} className={rowStyle}>
                            {['fcst', 'delta', 'committed'].includes(det) &&
                            (m < currentMonth || selectedYear < currentYear) &&
                            !totalKpis[category][selectedView][m][det]
                              ? null
                              : <div className={`${mainCats.includes(category) ? '' : 'text-xs'}`}>
                                {intl.formatNumber(totalKpis[category][selectedView][m][det], currencyStyleEur)}
                              </div>
                            }
                          </td>
                        ))
                        : [1, 2, 3, 4].map(q => (
                          <td key={q} className={rowStyle}>
                            {['fcst', 'delta', 'committed'].includes(det) &&
                            (((q - 1) * 3 + 2) < currentMonth || selectedYear < currentYear) &&
                            !totalKpis[category]['absolute'][(q - 1) * 3 + 2][det]
                              ? null
                              : <div className={`${mainCats.includes(category) ? '' : 'text-xs'}`}>
                                {intl.formatNumber(
                                  (totalKpis[category]['absolute'][(q - 1) * 3][det] || 0) +
                                  (totalKpis[category]['absolute'][(q - 1) * 3 + 1][det] || 0) +
                                  (totalKpis[category]['absolute'][(q - 1) * 3 + 2][det] || 0)
                                  , currencyStyleEur)}
                              </div>
                            }
                          </td>
                        ))
                      }
                      {selectedView !== 'cumulative' &&
                        <td className={`${rowStyle} border-l-2 border-gray-900`}>
                          <div className={`${mainCats.includes(category) ? '' : 'text-xs'}`}>
                            {intl.formatNumber(totalKpis[category]['cumulative'][11][det], currencyStyleEur)}
                          </div>
                        </td>
                      }
                    </tr>
                  }
                </Fragment>
              ))}
            </Fragment>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
