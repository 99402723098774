import React, { useState } from "react";
import IconButton from "./IconButton";

const Tooltip = ({ text, size, placement, iconName= "ExclamationCircleIcon", customStyle = "" }) => {
  const [isHovering, setIsHovering] = useState(false);

  let sizeWidth;

  switch (size) {
    case "extra-small":
      sizeWidth = 100;
      break;
    case "small":
      sizeWidth = 250;
      break;
    case "large":
      sizeWidth = 550;
      break;
    default:
      sizeWidth = 400;
      break;
  }

  let placementStyling;

  switch (placement) {
    case "top":
      placementStyling = {
        left: -sizeWidth / 2,
        bottom: 30
      };
      break;
    case "left":
      placementStyling = {
        left: -sizeWidth - 15,
        top: 0,
      };
      break;
    case "right":
      placementStyling = {
        left: 25,
        top: 0,
      };
      break;
    default:
      placementStyling = { left: -sizeWidth / 2 };
      break;
  }

  return (
    <div className={customStyle}>
      <div className="relative">
        {placement === "top" ? (
          <>
            {isHovering ? (
              <div
                style={{ width: sizeWidth, ...placementStyling }}
                className={
                  "absolute z-50 py-2 px-3 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-80"
                }
              >
                {text}
              </div>
            ) : null}
            <IconButton
              onMouseOver={() => setIsHovering(true)}
              onMouseOut={() => setIsHovering(false)}
              iconName={iconName}
              className="h-5 w-5"
            />
          </>
        ) : null}

        {placement !== "top" ? (
          <>
            <IconButton
              onMouseOver={() => setIsHovering(true)}
              onMouseOut={() => setIsHovering(false)}
              iconName={iconName}
              className="h-5 w-5"
            />
            {isHovering ? (
              <div
                style={{ width: sizeWidth, ...placementStyling }}
                className="absolute z-50 py-2 px-3 text-xs font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-80"
                dangerouslySetInnerHTML={{ __html: text}}
               />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Tooltip;
