import React, {Fragment, useState} from "react";
import {contoMark, currencyStyleEur} from "@utils/constants";
import _ from "lodash";
import {ChevronRightIcon, ChevronDownIcon, ArrowDownIcon} from "@heroicons/react/24/solid";
import EditDealStatusDialog from "../../../components/Dialogs/EditDealStatus";


const headerStyle = 'align-top whitespace-nowrap p-2 text-left text-sm font-semibold text-gray-900 sm:pl-3'
const rowStyle = 'p-2 text-left text-sm font-medium text-gray-500 sm:pl-3 border-b'


export const RevenueDetailTable = ({
                                     intl,
                                     months,
                                     pms,
                                     detail,
                                     selectedYear,
                                     currentMonth,
                                     rischiAnticipi,
                                     fetchRischiAnticipi
                                   }) => {

  const [showDetail, setShowDetail] = useState(pms.filter(i => i !== 'Totale')
    .reduce((obj, i) => _.set(obj, [i], false), {}))

  const [sort, setSort] = useState(currentMonth);
  const [editStatus, setEditStatus] = useState();

  const getSortedPms = () => {
    const _pms = pms.filter(i => i !== 'Totale' && detail[i][11]['cumulative'] > 0)
    switch (sort) {
      case selectedYear:
        return _pms.sort((a, b) => detail[b][11]['cumulative'] - detail[a][11]['cumulative']).concat(['Totale'])
      case "":
        return _pms.sort().concat(['Totale'])
      default:
        return _pms.sort((a, b) => detail[b][sort]['total'] - detail[a][sort]['total']).concat(['Totale'])
    }
  }

  const getSortedDeals = (deals) => {
    switch (sort) {
      case selectedYear:
        return deals.sort((a, b) => Object.values(b['month_detail']).reduce((acc, i) => acc + i, 0) - Object.values(a['month_detail']).reduce((acc, i) => acc + i, 0))
      case "":
        return deals.sort((a, b) => a.cod_commessa.localeCompare(b.cod_commessa))
      default:
        return deals.sort((a, b) => {
          if (!a['month_detail'][sort]) {
            return 1
          }
          if (!b['month_detail'][sort]) {
            return -1
          }
          return a['month_detail'][sort] < b['month_detail'][sort] ? 1 : -1
        })
    }
  }

  const arrowSort = <div className="text-white"><ArrowDownIcon width="12" height="12"/></div>

  const getStatus = (c, d, m) => {
    const month = `${selectedYear}-${(m + 1).toString().padStart(2, '0')}-01`
    if (!rischiAnticipi[c] || !rischiAnticipi[c][d] || !rischiAnticipi[c][d][month]) {
      return ''
    }
    return rischiAnticipi[c][d][month] === 'RISCHIO' ? 'text-red-600' : 'text-green-600'
  }

  return (
    <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 flex flex-col h-70vh">
      <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 flex-grow overflow-auto">
        <table className="min-w-full">
          <thead className="bg-am-400 sticky top-0">
          <tr>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 cursor-pointer" onClick={() => setSort("")}>
              <span className="sr-only">PM</span>
              <div className="flex items-center">
                {sort === "" && arrowSort}
              </div>
            </th>
            {months.map(m => (
              <th
                key={m} scope="col"
                className="pl-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap capitalize cursor-pointer"
                onClick={() => setSort(m)}
              >
                <div className="flex flex-row items-center gap-1">
                  {intl.formatDate(new Date(selectedYear, m, 1), {month: "short", year: "2-digit"})}
                  {m === sort && arrowSort}
                </div>
              </th>
            ))}
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900 cursor-pointer"
              onClick={() => setSort(selectedYear)}
            >
              <div className="flex flex-row items-center gap-1">
                {selectedYear}
                {selectedYear === sort && arrowSort}
              </div>
            </th>
          </tr>
          </thead>
          <tbody className="bg-white">
          {getSortedPms().map((pm, index) => (
            <Fragment key={pm}>
              <tr
                onClick={() => setShowDetail({...showDetail, [pm]: !showDetail[pm]})}
                className={`${pm === 'Totale' || index === 0 ? "border-t-2 border-gray-900 " : "border-t border-gray-200"} ${pm === 'Totale' ? 'bg-am-400' : 'bg-gray-50 hover:bg-gray-100 cursor-pointer'}`}
              >
                <th className={headerStyle}>
                  {pm === 'Totale'
                    ? <div>{pm}</div> :
                    <div className="flex flex-row">
                      <span className="mr-2">
                        {showDetail[pm]
                          ? <ChevronDownIcon height="18" width="18"/>
                          : <ChevronRightIcon height="18" width="18"/>
                        }
                      </span>
                      <span>{pm}</span>
                    </div>
                  }
                </th>
                {months.map(m => (
                  <th key={m} className={headerStyle}>
                    <div className="flex flex-col">
                      <span>
                        {intl.formatNumber(detail[pm][m]['total'], currencyStyleEur)}
                      </span>
                      <span className="text-xxs italic font-light">
                        {intl.formatNumber(detail[pm][m]['cumulative'], currencyStyleEur)}
                      </span>
                      {detail[pm][m]['rischi'] ?
                        <span className="text-xs text-red-600 bg-white p-1 rounded-lg mt-1 -ml-1">
                          {intl.formatNumber(detail[pm][m]['rischi'], currencyStyleEur)}
                        </span> : null
                      }
                      {detail[pm][m]['anticipi'] ?
                        <span className="text-xs text-green-600 bg-white p-1 rounded-lg mt-1 -ml-1">
                          {intl.formatNumber(detail[pm][m]['anticipi'], currencyStyleEur)}
                        </span> : null
                      }
                    </div>
                  </th>
                ))}
                <th className={headerStyle}>
                  {intl.formatNumber(detail[pm][11]['cumulative'], currencyStyleEur)}
                </th>
              </tr>
              {showDetail[pm] ? getSortedDeals(detail[pm]['commesse']).map(det => (
                <tr key={det.cod_commessa + det.des_conto}>
                  <td className={rowStyle}>
                    <div className="flex flex-col w-48 text-gray-900">
                      <span className="font-semibold">
                        {contoMark[det.des_conto] ? `${contoMark[det.des_conto]} ` : ''}
                        {det.cod_commessa}
                      </span>
                      <br/><span className="text-xxs truncate -mt-6">{det.des_commessa}</span>
                      <br/><span className="text-xxs truncate -mt-6 -mb-2 italic">{det.des_cliente}
                      </span>
                    </div>
                  </td>
                  {months.map(m => (
                    <td key={m} className={`${rowStyle} ${getStatus(det.cod_commessa, det.des_conto, m)}`}>
                      {det['month_detail'][m] &&
                        <span className="cursor-pointer" onClick={() => setEditStatus([det, m])}>
                          {intl.formatNumber(det['month_detail'][m], currencyStyleEur)}
                        </span>}
                    </td>
                  ))}
                  <td className={`${rowStyle} text-gray-900`}>
                    {intl.formatNumber(Object.values(det['month_detail']).reduce((acc, i) => acc + i, 0), currencyStyleEur)}
                  </td>
                </tr>
              )) : null}
            </Fragment>
          ))}
          </tbody>
        </table>
      </div>
      {editStatus && <EditDealStatusDialog
        toggleOpen={() => setEditStatus(undefined)}
        editStatus={editStatus}
        selectedYear={selectedYear}
        rischiAnticipi={rischiAnticipi}
        fetchRischiAnticipi={fetchRischiAnticipi}
        intl={intl}
      />}
    </div>
  );
}
