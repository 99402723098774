import React, { useState } from "react";

function OkrSub({ task, editMode }) {
  
  // eslint-disable-next-line
  const [perc, setPerc] = useState(task.val_progress);
  // eslint-disable-next-line
  const [name, setName] = useState(task.name);

  return (
    (!editMode) ? (
      <div key={task["id_task"]} className="flex flex-row justify-between bg-white p-2 border rounded-xl border-slate-300 grow">
        <p>{name}</p>
        <p>{perc}%</p>
      </div>
    ) : (<></>)
  )
}

export default OkrSub;