import React, {useEffect, useState, useContext} from "react";
import { format } from "date-fns";
import {useIntl} from "react-intl";

// Services
import AuthenticationService from "../../services/AuthenticationService";
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext/DataContext";
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components
import SelectEvaluationDate from "./selectEvaluationDate";
import EvaluationDetail from "./EvaluationDetail";
import ActionButton from "./actionButton";

// UI
import {PageHeading} from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";
import {AdjustmentsVerticalIcon} from "@heroicons/react/24/outline";
import Breadcrumb from "../../ui-components/Breadcrumb";
import Select from "../../ui-components/Select";


function Evaluation() {

  const intl = useIntl();
  const dataContext = useContext(DataContext);
  const {push} = useContext(NotificationsContext);
  const userData = AuthenticationService.getUserData();
  const [listSubordinates, setListSubordinates] = useState(null);

  const [navigationLevel, setNavigationLevel] = useState(0);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [action, setAction] = useState();
  const [evaluateUserId, setEvaluateUserId] = useState();
  const [evaluations, setEvaluations] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [maxDate, setMaxDate] = useState(null);
  const [baseRatingDate, setBaseRatingDate] = useState(null);

  const today = format(new Date(), "yyyy-MM-dd")

  useEffect(() => {
    if (evaluations) {
      if (Object.keys(evaluations).length !== 0 & maxDate === null){
        setMaxDate(format(new Date(Math.max(...Object.keys(evaluations).map(item => new Date(item)))), "yyyy-MM-dd"));
      }
    }
    
    if(selectedDate) {
      setBaseRatingDate((selectedDate === today & maxDate !== null) ? maxDate : selectedDate)
    }
  }, [evaluations, maxDate, selectedDate, today, baseRatingDate])



  // 4 navigations levels:

  // 0) we miss basic data: show placeholder

  // 1) chose action between 3 possibilities:
  //    - self-evaluation: me evaluates me
  //    - evaluate my team: me evaluates people under me (disabled if I don't have a team)
  //    - my evaluations: I wanna check (read-only) evaluations of myself by someone else

  // 2) chose the person:
  //    - no sense in first case: automatically set to myself
  //    - one of my team member
  //    - someone who already evaluated me

  // 3) chose the session (date of evaluation) or create a new one when is possibile

  // 4) edit/read the evaluation in the selected date


  const fetchAllSkill = () => {
    apiRequest
      .get("/skills").then((res) => {
      dataContext.setSkillList(res)
    })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"})
      })
  }

  const fetchAllMySubordinates = () => {
    apiRequest
      .get("/roles-almagamma/organizationChart/list_subordinates/username")
      .then((result) => {
        setListSubordinates([...new Set((result || [])
          .filter(i => i.id_user !== userData.id)
          .map(i => ({value: i.id_user, name: i.name})))
        ])
      })
      .catch((err) => console.log(err))
  }

  const getParams = () => {
    if (action === 'self_evaluation') {
      return '?self_evaluation=True'
    }
    return `?id_user_valutato=${evaluateUserId}&id_user_valutatore=${userData.id}`
  }

  const fetchAllSkillEvalByUser = () => {
    apiRequest
      .get(`/evaluation-suite${getParams()}`)
      .then((result) => {
        setEvaluations(_.groupBy(result, 'dat_evaluation'))
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }

  useEffect(() => {
    if (!dataContext.skillList) {
      fetchAllSkill();
    }
    if (!listSubordinates) {
      fetchAllMySubordinates();
    }
    if (listSubordinates && dataContext.skillList) {
      setNavigationLevel(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContext.skillList, listSubordinates]);


  useEffect(() => {
    let newItems = []
    if (navigationLevel > 1) {
      newItems = newItems.concat([{
        title: <AdjustmentsVerticalIcon className="h-5 w-5"/>,
        onClick: () => {
          setAction(undefined)
          setEvaluateUserId(undefined)
          setNavigationLevel(1)
        }
      }, {
        title: intl.formatMessage({id: action}),
        onClick: (navigationLevel === 2 || action === 'self_evaluation')
          ? undefined : () => {
            setEvaluateUserId(undefined);
            setNavigationLevel(2)
          }
      }])
    }
    if (navigationLevel > 2) {
      newItems = newItems.concat([{
        title: action === 'self_evaluation'
          ? userData.full_name
          : listSubordinates.find(x => x.value === Number(evaluateUserId))['name'],
        onClick: navigationLevel === 3 ? undefined : () => {
          setSelectedDate(undefined)
          setNavigationLevel(3)
        }
      }])
    }
    if (navigationLevel === 4) {
      newItems = newItems.concat([{
        title: `${intl.formatMessage({id: "session_of"})}  ${intl.formatDate(selectedDate)}`
      }])
    }
    setBreadcrumbItems(newItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationLevel])

  useEffect(() => {
    if (evaluateUserId) {
      fetchAllSkillEvalByUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluateUserId])

  return (
    <div>
      < PageHeading title={intl.formatMessage({id: "Evaluation"})}/>
      {navigationLevel === 0
        ? <div>
          <Placeholder width="w-full" height="h-8"/>
          <Placeholder width="w-full" height="h-32"/>
          <Placeholder width="w-full" height="h-64"/>
        </div>
        : null
      }
      {navigationLevel > 1
        ? <div className="-mt-8 mb-8">
          <Breadcrumb items={breadcrumbItems} classNames="flex-col sm:flex-row "/>
        </div>
        : null
      }
      {navigationLevel === 1
        ? <div className="flex flex-col justify-around items-center">
          <ActionButton
            intl={intl}
            title="self_evaluation"
            description="self_evaluation_description"
            onClick={() => {
              setAction('self_evaluation')
              setEvaluateUserId(userData.id)
              setNavigationLevel(3)
            }}
          />
          <ActionButton
            intl={intl}
            title="evaluate_your_team"
            description="evaluate_your_team_description"
            disabled={listSubordinates.length === 0}
            onClick={() => {
              setAction('evaluate_your_team')
              setNavigationLevel(2)
            }}
          />
          <ActionButton
            intl={intl}
            disabled={true}
            title="my_evaluations"
            description="my_evaluations_description"
            onClick={() => {
              setAction('my_evaluations')
              setNavigationLevel(2)
            }}
          />
        </div>
        : null
      }
      {navigationLevel === 2
        ? <div>
          <Select
            label={intl.formatMessage({id: "choose_person_evaluation"})}
            options={[{value: undefined, name: ""}].concat(listSubordinates)}
            onChange={(e) => {
              setEvaluateUserId(e.target.value)
              setNavigationLevel(3)
            }}
            value={evaluateUserId}
          />
        </div>
        : null
      }
      {navigationLevel === 3
        ? <SelectEvaluationDate
          intl={intl}
          evaluations={evaluations}
          setEvaluations={setEvaluations}
          setSelectedDate={setSelectedDate}
          setNavigationLevel={setNavigationLevel}
        />
        : null
      }
      {navigationLevel === 4 & baseRatingDate !== null
        ? <EvaluationDetail
          intl={intl}
          push={push}
          rating={evaluations[baseRatingDate].reduce((obj, i) => _.set(obj, [i.id_skill], i.id_grade), {})}
          skills={dataContext.skillList}
          evaluations={evaluations}
          setEvaluations={setEvaluations}
          selectedDate={selectedDate}
          evaluateUserId={evaluateUserId}
          isSelfEvaluation={action === 'self_evaluation'}
        />
        : null
      }
    </div>
  );
}

export default Evaluation;
