import React from "react";


function PointRating({evaluation}) {
  const evaluationFloor = Math.floor(evaluation);
  const evaluationCeil = Math.ceil(evaluation);
  const evaluationIntArray = [...Array(evaluationFloor).keys()];
  const remainder = ((evaluation - evaluationFloor) * 100).toFixed(0);
  const fivePoints = [...Array(5 - evaluationCeil).keys()];
  return (
    <div className={"flex flex-row gap-x-0.5"}>
      {evaluationIntArray.map((i) => {
        return <div key={i} className={"point-rating point-full-rating"}/>
      })}
      {remainder > 0 && (
        <div
          className={"point-rating"}
          style={{
            background: `linear-gradient(to right, var(--c-blue-ammagamma) 0%, var(--c-blue-ammagamma) ${remainder}%, 
            white ${remainder}%, white 100%)`
          }}
        >
        </div>
      )}
      {fivePoints.map((i) => {
        return <div key={i} className={"point-rating"}/>
      })}
    </div>
  );
}

export default PointRating;