import React, {useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {tableIcons} from "../../../ui-components/TableIcons";
import TableStyle from "../../../ui-components/TableStyle";
import {useIntl} from "react-intl";
import {currencyStyleUsd} from "../../../utils/constants";
import {ArrowRightIcon} from "@heroicons/react/20/solid";

const OPTIONS = {
  filtering: true,
  toolbar: false,
  paging: false,
  sorting: true,
  searchFieldStyle: {width: "100%"},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  headerStyle: {fontWeight: "bold", textAlign: "center", position: 'sticky', top: 0},
  maxBodyHeight: '70vh',
};

const OU_ORDERS = {
  'Root': 1,
  'Core': 2,
  'Shared': 3,
  'Interno': 4,
  'Clienti': 5,
  'Sospesi': 6
}

const renderMonthCell = (rowData, month, intl, currentMonth = false) => {
  const i = rowData[month]
  if (!i) {
    return null
  }
  const forecast = currentMonth ? i['amt_forecast'] : i['amt_billing']
  const variation = Math.round((forecast - i['amt_budget']) / i['amt_budget'] * 100)
  const customStyle = variation > 0 ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
  return <div className="flex flex-col -my-2 -mx-3">
    <div className="flex flex-row items-center gap-1 justify-center">
        <span className="font-medium">
          {intl.formatNumber(i['amt_billing'], currencyStyleUsd)}
        </span>
      {currentMonth ? <>
        <span className="text-gray-500"><ArrowRightIcon width="15" height="15"/></span>
        <span className="text-gray-500">{intl.formatNumber(i['amt_forecast'], currencyStyleUsd)}</span>
      </> : null
      }
    </div>
    <div className="flex flex-row items-center gap-2 justify-center">
        <span className="text-xs italic">
          B: {intl.formatNumber(i['amt_budget'], currencyStyleUsd)}
        </span>
      <span
        className={`inline-flex items-center px-2 rounded-full font-bold text-xxs h-5 ${customStyle}`}>
          {variation > 0 ? '+' : ''}{variation < 1000 ? variation : 'Inf'}%
        </span>
    </div>
  </div>
}

function getColumns(organizationalUnits, customers, months, intl) {
  const ous = organizationalUnits.reduce((o, key) => ({...o, [OU_ORDERS[key]]: key}), {})
  const cs = customers.reduce((o, key) => ({...o, [key]: key}), {})
  let cols = [
    {
      title: "OU",
      field: "ou_order",
      type: "string",
      width: "15%",
      lookup: ous,
    },
    {
      title: "Account",
      field: 'account_name_id',
      render: rowData => <div className="-my-2">
        <b>{rowData["account_name"]}</b><br/><small>{rowData['account_id']}</small></div>,
      width: "5%",
      cellStyle: {whiteSpace: "nowrap", paddingLeft: 10, position: 'sticky', left:0, zIndex: 998, backgroundColor: "#f5f5f5"},
      headerStyle: {paddingLeft: 10, position: 'sticky', left:0, zIndex: 999}
    },
    {
      title: "Cliente",
      field: 'customer',
      lookup: cs,
      width: "5%"
    },
  ]
  months.forEach(month => {
    cols.push({
      title: <div className="pl-5">{Intl.DateTimeFormat('it', {month: 'short', year: '2-digit'}).format(new Date(month))}</div>,
      field: `${month}.amt_billing`,
      render: rowData => renderMonthCell(rowData, month, intl, month === months[0]),
      filtering: false,
      cellStyle: {whiteSpace: "nowrap"},
      headerStyle: {whiteSpace: "nowrap"},
    })
  })
  return cols
}

function AccountTable({data, organizationalUnits}) {
  const intl = useIntl();
  const months = [...new Set(Object.values(data).map(i => i.month))].sort().reverse()
  const customers = [...new Set(Object.values(data).map(i => i.customer))].sort()

  const pivot = data.reduce((obj, i) => {
    if (!obj[i.account_name]) {
      obj[i.account_name] = _.pick(i, ['account_name', 'account_id', 'ou_name', 'status', 'dat_creation', 'customer'])
      obj[i.account_name]['account_name_id'] = i.account_name + i.account_id
      obj[i.account_name]['ou_order'] = OU_ORDERS[i.ou_name]
      obj[i.account_name]['id'] = i.account_id
      if (i.account_name === 'eliminati') {
        obj[i.account_name]['account_id'] = '-'
      }
    }
    obj[i.account_name][i.month] = _.pick(i, ['amt_billing', 'amt_budget', 'amt_forecast'])

    return obj
  }, {})

  const [columns] = useState(getColumns(organizationalUnits, customers, months, intl));

  return (
    <>
      <TableStyle>
        <MaterialTable
          title="Account"
          columns={columns}
          data={_.orderBy(Object.values(pivot), ['ou_order', 'account_name'])}
          icons={tableIcons}
          options={OPTIONS}
          // localization={LOCALIZATION}
        />
      </TableStyle>
    </>
  );
}

export default React.memo(AccountTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});


