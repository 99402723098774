import React, {useEffect, useState} from "react";

import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";

import Input from "@ui-components/Input";
import Button from "@ui-components/Button";
import {ArrowSmallRightIcon, PencilIcon} from "@heroicons/react/24/outline";
import {AppDetailSchema, AppSchema} from "@pages/Monitoring/Monitoring.types";
import {ServiceStatusDetail} from "@pages/Monitoring/Overview/Overview.lib";


function AppDetail({appData}: { appData: AppSchema }) {
  const intl = useIntl();
  const [codeOwner, setCodeOwner] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [appDetail, setAppDetail] = useState<AppDetailSchema | undefined>(undefined);
  const [appDetailDev, setAppDetailDev] = useState<AppDetailSchema | undefined>(undefined);

  useEffect(() => {
    if (appData) {
      const fetchAppDetail = () => {
        api
          .get<AppDetailSchema>(`/monitoring/appDetail/${appData.des_prod_link}`)
          .then(({data: result}) => {
            setAppDetail(result);
            setCodeOwner(result?.code_owner ?? "TBD")
          })
          .catch((error) => {
            console.log(error);
          });
      };
      if (!appDetail && appData.des_prod_link) {
        fetchAppDetail();
      }

      const fetchAppDetailDev = () => {
        api
          .get<AppDetailSchema>(`/monitoring/appDetail/${appData.des_dev_link}`)
          .then(({data: result}) => {
            setAppDetailDev(result);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      if (!appDetailDev && appData.des_dev_link) {
        fetchAppDetailDev();
      }
    }
  }, [appDetail, appData, appDetailDev]);

  const updateCodeOwner = (desHostName: string | undefined, codeOwner: string) => {
    const updateData = {
      des_host_name: desHostName,
      code_owner: codeOwner,
    };
    api
      .put("/monitoring/codeOwner", updateData)
      .then(({data: result}) => {
        if (result) {
          setAppDetail({...appDetail, code_owner: result.val_kpi});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col rounded-lg px-4 pt-1 pb-3 gap-2 ">
      {appData && (
        <>
          <div className="flex-[0.8] text-center">
            <span className="text-base">
              {appData.des_customer_name.toUpperCase()}
              <br/>
              <b>{appData.des_app_name}</b>
            </span>
          </div>
          {appDetail && appDetailDev ?
            <div>
              <ServiceStatusDetail
                intl={intl}
                title="Sviluppo"
                appDetail={appDetailDev}
                status={appData.cod_app_status_dev}
              />
              <ServiceStatusDetail
                intl={intl}
                title="Produzione"
                appDetail={appDetail}
                status={appData.cod_app_status_prod}
              />
            </div>
            : null
          }
          <div className="grid grid-cols-2 justify-items-center">
            {appDetail ?
              <>
                <div className="grid grid-cols-1 text-sm">
                  <span className="font-bold h-1">METADATI:</span>
                  <span className="h-fit">
                    Code Owner:{" "}
                    <b>{showInput ? null : codeOwner}</b>
                    {showInput ? null :
                      // @ts-ignore
                      <Button
                        styleType="white" onClick={() => setShowInput(!showInput)}
                        className="h-6 ml-3 w-10"
                      >
                        <div>
                          <PencilIcon className="w-4 h-4"/>
                        </div>
                      </Button>
                    }
                  </span>
                  {showInput && (
                    <div className="flex h-14 -mt-2 w-56">
                      {/*@ts-ignore*/}
                      <Input
                        placeholder={"Inserisci code owner"}
                        value={codeOwner}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCodeOwner(e.target.value)}
                      />
                      {/*@ts-ignore*/}
                      <Button
                        onClick={() => {
                          updateCodeOwner(appDetail?.des_host_name, codeOwner);
                          setShowInput(false);
                        }}
                        className="ml-2 mb-4"
                      >
                        <ArrowSmallRightIcon className="w-4 h-4 inline-block"/>
                      </Button>
                    </div>
                  )}
                  <span className="h-fit">
                    Data ultimo rilascio:{" "}
                    {new Intl.DateTimeFormat("it-IT", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(new Date(appDetail?.tms_last_release ?? ''))}
                  </span>
                  <span className="font-bold h-1">VERSIONI:</span>
                  <span className="h-1">
                    {intl.formatMessage({id: "app_version"})}: <strong>{appDetail.cod_app_version}</strong>
                  </span>
                  <span className="h-1">
                    {intl.formatMessage({id: "template_app_version"})}: <strong>{appDetail.cod_atemplateapp_version}</strong>
                  </span>
                  <span className="h-1">
                    {intl.formatMessage({id: "acli_version"})}: <strong>{appDetail.cod_acli_version}</strong>
                  </span>
                  <span className="h-1">
                    {intl.formatMessage({id: "cdk_version"})}: <strong>{appDetail.cod_cdk_version}</strong>
                  </span>
                </div>
                <div className="grid grid-cols-1 text-start text-sm ml-5">
                  <span className="font-bold">SERVIZI:</span>
                  <span className="">
                    {intl.formatMessage({id: "waf_enabled"})}: {appDetail.flg_waf_enabled ? "SI" : "NO"}
                  </span>
                  <span className="font-bold mt-2">
                    {intl.formatMessage({id: "audit_detail"})}:
                  </span>
                  <span>
                    {intl.formatMessage({id: "registered_users"})}: {appDetail.val_registered_users}
                  </span>
                  <span className="font-bold mt-2">{intl.formatMessage({id: "login_number"})}:</span>
                  <span>
                    {intl.formatMessage({id: "last_day_accesses"})}: {appDetail.val_logged_users_last_day}
                  </span>
                  <span>
                    {intl.formatMessage({id: "last_week_accesses"})}: {appDetail.val_logged_users_last_week}
                  </span>
                  <span>
                    {intl.formatMessage({id: "last_month_accesses"})}: {appDetail.val_logged_users_last_month}
                  </span>
                  <span className="font-bold mt-2">% Login:</span>
                  <span>
                    {intl.formatMessage({id: "last_day_accesses"})}: {appDetail.val_perc_logged_users_last_day ? appDetail.val_perc_logged_users_last_day.toFixed(1) + ' %' : "N/A"}
                  </span>
                  <span>
                    {intl.formatMessage({id: "last_week_accesses"})}: {appDetail.val_perc_logged_users_last_week ? appDetail.val_perc_logged_users_last_week.toFixed(1) + ' %' : "N/A"}
                  </span>
                  <span>
                    {intl.formatMessage({id: "last_month_accesses"})}: {appDetail.val_perc_logged_users_last_month ? appDetail.val_perc_logged_users_last_month.toFixed(1) + ' %' : "N/A"}
                  </span>
                </div>
              </>
              :
              <div className="col-span-2 mt-4">
                <strong>{intl.formatMessage({id: "monitoring_not_active"})}</strong>
              </div>}
          </div>
        </>
      )}
    </div>
  );
}

export default AppDetail;
