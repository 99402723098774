// maybe not the most efficient, but readable
export const getInitSessionData = (skills, rating) => {
  let init = {}
  skills.forEach(s => {
    const cod_area = s['topic']['cod_area']
    const cod_type = s['topic']['cod_type']
    const id_topic = s['id_topic']
    const id_skill = s['id_skill']
    if (!Object.keys(init).includes(cod_area)) {
      init[cod_area] = {}
    }
    if (!Object.keys(init[cod_area]).includes(cod_type)) {
      init[cod_area][cod_type] = {
        cod_type: cod_type,
        des_type: s['topic']['type']['des_type'],
        topics: {}
      }
    }
    if (!Object.keys(init[cod_area][cod_type]['topics']).includes(id_topic.toString())) {
      init[cod_area][cod_type]['topics'][id_topic] = {
        id_topic: id_topic,
        des_topic: s['topic']['des_topic'],
        skills: {}
      }
    }
    init[cod_area][cod_type]['topics'][id_topic]['skills'][id_skill] = {
      id_skill: s['id_skill'],
      des_skill: s['des_skill'],
      id_grade: rating[s['id_skill']] >= 0 ? rating[s['id_skill']] : -1
    }
  })
  return init
}


export const compactSessionData = (data, dat_evaluation, id_user_valutato) => {
  let result = []
  Object.values(data).forEach(area => {
    Object.values(area).forEach(type => {
      Object.values(type.topics).forEach(topic => {
        Object.values(topic.skills).forEach(skill => {
          if (skill.id_grade !== -1) {
            result.push({
              id_user_valutato: id_user_valutato,
              dat_evaluation: dat_evaluation,
              id_skill: skill.id_skill,
              id_grade: skill.id_grade
            })
          }
        })
      })
    })
  })
  return result
}