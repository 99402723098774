import React, {useContext, useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {OPTIONS, LOCALIZATION, getColumns} from "./Params/CourseParams";
import {tableIcons} from "../../ui-components/TableIcons";
import AddNewCourse from "../Dialogs/AddNewCourse";
import Button from "../../ui-components/Button";
import TableStyle from "../../ui-components/TableStyle";
import {DataContext} from "../../services/DataContext/DataContext";
import { useIntl } from "react-intl";

function CourseTable({data, refresh, setRefresh, isPersonal}) {

  const [showAdd, setShowAdd] = useState(false);
  const [editRow, setEditRow] = useState();
  const dataContext = useContext(DataContext);
  const intl = useIntl();

  return (
    <>
      <TableStyle>
        <MaterialTable
          title="Courses"
          columns={getColumns(dataContext.courseTypeList)}
          data={data}
          icons={tableIcons}
          isLoading={data === undefined}
          options={OPTIONS}
          localization={LOCALIZATION}
          actions={[
            {
              icon: () => <Button type="submit" disabled={isPersonal}>+</Button>,
              tooltip: intl.formatMessage({id: "add_course"}),
              onClick: () => setShowAdd(true),
              isFreeAction: true,
              disabled: isPersonal
            },
            {
              icon: tableIcons.Edit,
              tooltip: 'Edit',
              onClick: (event, rowData) => setEditRow(rowData)}
          ]}
        />
      </TableStyle>
      {(showAdd || editRow) &&
        <AddNewCourse
          intl={intl}
          toggleOpen={() => {
            setShowAdd(false)
            setEditRow(undefined)
          }}
          refresh={refresh}
          setRefresh={setRefresh}
          editRow={editRow}
        />
      }
    </>
  );
}

export default React.memo(CourseTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});


