import React from "react";

import {downloadExcel} from "../../utils";
import _ from "lodash";

// Table Params
import {COLUMNS, LOCALIZATION, OPTIONS} from "./Params/AttendanceReportParams";
import {tableIcons} from "../../ui-components/TableIcons";
import TableStyle from "../../ui-components/TableStyle";
import MaterialTable from "@material-table/core";

function AttendanceReportTable({title, data}) {

  const ref = React.useRef(null);

  return (
    <>
      <TableStyle>
        <MaterialTable
          tableRef={ref}
          title={title}
          columns={COLUMNS}
          data={data}
          icons={tableIcons}
          isLoading={data === undefined}
          options={OPTIONS}
          className="items-center"
          localization={LOCALIZATION}
          actions={[
            {
              icon: tableIcons.Download,
              tooltip: "Export in Excel",
              onClick: () => downloadExcel(ref.current.state.data),
              isFreeAction: true
            },

          ]}
        />
      </TableStyle>
    </>
  );
}

export default React.memo(AttendanceReportTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});
