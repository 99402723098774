import React, {useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";

// Components

// UI
import Placeholder from "../../ui-components/Placeholder";
import DatePicker from "../../ui-components/DatePicker";
import TeamProgressOkr from "../../components/Okr/TeamProgressOkr";


function TeamOkrs({push, intl, today}) {

  const quarter = Math.floor((today.getMonth() / 3));
  const startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
  const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);

  const [startDatePicker, setStartDatePicker] = useState(startFullQuarter);
  const [endDatePicker, setEndDatePicker] = useState(endFullQuarter);
  const [okrProgressData, setOkrProgressData] = useState();

  const getDateString = (date) => {
    return date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" +
      (date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate());
  }

  const fetchOkrProgressData = () => {
    if (startDatePicker === undefined) return;
    if (endDatePicker === undefined) return;
    apiRequest
      .get(`/okrs/subordinates_okrs_progress?start_date=${getDateString(startDatePicker)}&end_date=${getDateString(endDatePicker)}`)
      .then((result) => {
        setOkrProgressData(Object.values(result));
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "error_loading"}), type: "error"});
      })
  }


  useEffect(() => {
    fetchOkrProgressData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDatePicker, endDatePicker])

  return (
    <div>
      {okrProgressData ?
        <div className="my-5">
          <div className="flex flex-row gap-5 pb-5">
            <DatePicker
              value={startDatePicker}
              onChange={setStartDatePicker}
              label={intl.formatMessage({id: "start_date"})}
              placeholder={intl.formatMessage({id: "start_date"})}
              errorMessage={intl.formatMessage({id: "start_date_picker_error"})}
              maxDate={endDatePicker}
              minDate={new Date(0)}
            />
            <DatePicker
              value={endDatePicker}
              onChange={setEndDatePicker}
              label={intl.formatMessage({id: "end_date"})}
              placeholder={intl.formatMessage({id: "end_date"})}
              errorMessage={intl.formatMessage({id: "end_date_picker_error"})}
              minDate={startDatePicker}
            />
          </div>
          <div className="flex flex-col gap-2">
            {
              (okrProgressData || []).map((element, index) => {
                return (
                  <TeamProgressOkr
                    userId={element["id_user"]}
                    userName={element["name"]}
                    okrs={element["okr_list"]}
                    key={index}
                    startDate={getDateString(startDatePicker)}
                    endDate={getDateString(endDatePicker)}
                    getDateString={getDateString}
                  />
                )
              })
            }
          </div>
        </div>
        :
        <div className="mt-10 flex flex-col gap-5">
          <Placeholder width="w-full" height="h-40"/>
          <Placeholder width="w-full" height="h-40"/>
          <Placeholder width="w-full" height="h-40"/>
        </div>
      }
    </div>
  )
}

export default TeamOkrs;
