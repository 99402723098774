import React, {useEffect, useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {tableIcons} from "../../../ui-components/TableIcons";
import TableStyle from "../../../ui-components/TableStyle";
import SearchSelect from "../../../ui-components/SearchSelect";

const OPTIONS = {
  filtering: false,
  toolbar: false,
  paging: false,
  sorting: true,
  searchFieldStyle: {width: "100%"},
  filterCellStyle: {paddingTop: 8, maxWidth: 200},
  cellStyle: {fontSize: 10},
  headerStyle: {fontWeight: "bold", textAlign: "center", position: 'sticky', top: 0, fontSize: 9, lineHeight: 1.2},
  maxBodyHeight: '70vh',
  rowStyle: rowData => {
    if (rowData.risorsa === "TOTALE") {
      return {color: "rgb(0, 54, 70)", fontWeight: "bold"}
    }
  }
};

const abbreviations = {
  vendute: 'Vend',
  non_vendute: 'Non vend',
  innovation: 'Inn',
  formazione: 'For',
  non_lavorate: 'Non Lav',
}

function getColumns(categories) {
  let cols = [
    {
      title: "Risorsa",
      field: "risorsa",
      type: "string",
      width: "25%",
      render: ({risorsa}) => <b className="text-xs">{risorsa}</b>,
      cellStyle: {whiteSpace: "nowrap"}
    },
    {
      title: "Ore",
      field: "val_ore",
      render: ({val_ore}) => <b className="text-xs">{val_ore}</b>
    },
  ]
  categories.forEach(c => {
    cols.push({
      title: abbreviations[c],
      field: `ore_${c}`,
      render: rowData => <div className="text-xs font-medium -m-3">
        {rowData[`ore_${c}`] ?
          <div>
            <span className={`${rowData.risorsa === 'TOTALE' ? 'font-bold' : 'font-normal'}`}>
              {rowData[`ore_${c}`]}
            </span>
            <br/>
            <span className={`text-xs italic ${rowData.risorsa === 'TOTALE' ? 'font-bold' : 'font-normal'}`}>
              ({(rowData[`ore_${c}`] / rowData['val_ore'] * 100).toFixed(0)}%)
            </span>
          </div> : null
        }
      </div>,
      cellStyle: {whiteSpace: "nowrap"}
    })
  })
  return cols
}

function PeopleDetailTable({categories, data}) {

  const options = data.map(i => ({label: i.risorsa, value: i.risorsa}))
  const [columns] = useState(getColumns(categories));
  const [selected, setSelected] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [total, setTotal] = useState({});

  useEffect(() => {
    if (selected.length) {
      setFilterData(data.filter(i => selected.map(j => j.value).includes(i.risorsa)))
    } else {
      setFilterData(data)
    }
  }, [data, selected])

  useEffect(() => {
    let _total = {risorsa: 'TOTALE'}
    if (filterData.length) {
      const keys = [...new Set(filterData.reduce((acc, i) => acc.concat(Object.keys(i)), []))]
      keys.filter(k => k !== 'risorsa').forEach(k => {
        _total[k] = filterData.reduce((acc, i) => acc + (i[k] || 0), 0)
      })
    }
    setTotal(_total)
  }, [filterData])

  return (
    <>
      <SearchSelect
        label="Filtra risorse"
        isMulti
        value={selected}
        options={options}
        onChange={(e) => setSelected(e)}
      />
      <TableStyle>
        <MaterialTable
          columns={columns}
          data={[total].concat(filterData)}
          icons={tableIcons}
          options={OPTIONS}
        />
      </TableStyle>
    </>
  );
}

export default React.memo(PeopleDetailTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});


