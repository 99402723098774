import {COLUMNS} from "../components/Tables/Params/AttendanceReportParams";
import _ from "lodash";
import XLSX from "xlsx";

export function formatDate(inputDate) {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date
    .toString()
    .padStart(2, '0');

  month = month
    .toString()
    .padStart(2, '0');

  return `${year}-${month}-${date}`;
}

export const downloadExcel = (exportData) => {

  const fields = COLUMNS.filter(i => !i.hidden).map(i => i.field)
  const titles = COLUMNS.filter(i => !i.hidden).map(i => i.title)
  const content = exportData.map(key => _.pick(key, fields))

  const workSheet = XLSX.utils.json_to_sheet([])
  const workBook = XLSX.utils.book_new()
  XLSX.utils.sheet_add_aoa(workSheet, [titles]);
  XLSX.utils.sheet_add_json(workSheet, content, {origin: 'A2', skipHeader: true});

  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
  XLSX.write(workBook, {bookType: "xlsx", type: "buffer"})
  XLSX.write(workBook, {bookType: "xlsx", type: "binary"})
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  XLSX.writeFile(workBook, `table_export_${yyyy}${mm}${dd}.xlsx`)
}
// ##### DATE FUNCTIONALITIES ######
export const getDateOfISOWeek = (weekNumber, year) => {
  // given week number and year, return a Date object
  // with day as the first day of the week
  let simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  let dow = simple.getDay();
  let ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export const getDateOfISOYearMonth = (year) => {
  return new Date(year, 0, 1);
}

export const getWeekFromDate = (inputDate) => {
  // given a date, returns the week number of that day
  const firstMonday = getNextMonday(new Date(inputDate.getFullYear(), 0, 1));
  const numberOfDays =  Math.floor((inputDate - firstMonday) / (24 * 60 * 60 * 1000));      // milliseconds in one day
  if (numberOfDays < 0)
    return 52;
  return Math.floor(numberOfDays / 7) + 1;
}

const getCurrentMondayDate = (inputDate) => {
  let result = new Date(inputDate);
  const dayNumber = (result.getDay() || 7) - 1;        // Get current day number, converting Sun. to 7
  result.setDate(result.getDate() - dayNumber);
  return result;
};

const getNextMonday = (inputDate) => {
  const lastMonday = getCurrentMondayDate(inputDate);

  let result = new Date(lastMonday);
  result.setDate(result.getDate() + 7);
  return result;
};
