import _ from "lodash";
import React from "react";
import Tooltip from "@ui-components/Tooltip";
import {currencyStyleEur} from "@utils/constants";
import {ExclamationTriangleIcon, PencilIcon} from "@heroicons/react/24/outline";

const getTrancheButtonStyle = (rowData) => {
  const bg = Object.keys(rowData['tranche'] || {}).length === 0
    ? 'bg-red-600' : rowData['flg_done'] ? 'bg-green-700' : 'bg-transparent'
  return `px-3 py-2 rounded-3xl w-12 bg-opacity-50 border border-am-800 cursor-pointer ${bg} hover:bg-white`
}

export const getColumns = (intl, statusOptions, pmList, setEditTranche, tableFilters) => {
  const columns = [
    {
      title: "Stato",
      field: "status",
      lookup: statusOptions.reduce((obj, i) => _.set(obj, [i], i), {}),
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER'
    },
    {
      title: "Commessa",
      field: "cod_des_commessa",
      defaultSort: "desc",
      editable: 'never',
      render: rowData => <div className="flex flex-col text-xs w-40">
        <span className="font-bold">{rowData['cod_commessa']}</span>
        <span className="truncate">{rowData['des_commessa']}</span>
      </div>
    },
    {
      title: "Cliente",
      field: "des_cliente",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      render: rowData => <div className="text-xs w-36">
        <span>{rowData['des_cliente']}</span>
      </div>
    },
    {
      title: "Importo",
      field: "amt_importo",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      render: rowData => <b>{intl.formatNumber(rowData['amt_importo'], currencyStyleEur)}</b>
    },
    {
      title: "Inizio",
      field: "month",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      render: rowData => <div className="w-32">
        <span>{rowData['month']}</span>
      </div>
    },
    {
      title: <div className="flex-row flex items-center -py-2 -my-2 gap-2">
        <p>Tranche</p>
        <Tooltip
          customStyle="normal-case"
          size="small"
          text="Fatturazioni previste <br/>(si applica solo a DELTA e COMMITTED)"
        />
      </div>,
      field: "tranche",
      editable: 'never',
      render: rowData => <div>
        {['ESOLVER', 'RINNOVO'].includes(rowData.status)
          ? null
          : <div
              className={getTrancheButtonStyle(rowData)}
              onClick={() => setEditTranche(rowData)}>
              <PencilIcon className="shrink w-5 h-5"/>
            </div>
        }
      </div>
    },
    {
      title: <div className="flex-row flex items-center -py-2 -my-2 gap-2">
        <p>Mesi</p>
        <Tooltip
          customStyle="normal-case"
          size="small"
          text="Numero di mesi in cui ripartire il canone <br/>(si applica solo a ESOLVER e RINNOVO)"
        />
      </div>,
      field: "num_mesi_ripartizione",
      type: "numeric",
      editable: (_, rowData) => rowData && ['ESOLVER', 'RINNOVO'].includes(rowData.status),
      render: rowData => <div>
        {rowData['num_mesi_ripartizione']
          ? <span>{rowData['num_mesi_ripartizione']}</span>
          : rowData['status'] === 'ESOLVER'
            ? <span className="flex justify-end text-red-700">
                <ExclamationTriangleIcon width="15" height="15"/>
              </span>
            : null
        }
      </div>
    },
    {
      title: "PM",
      field: "cod_pm",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      lookup: pmList.reduce((obj, i) => _.set(obj, [i], i.replace('.', ' ')), {}),
      render: rowData => <div className="text-xs w-32">
        <span>{rowData['cod_pm'].replace('.', ' ')}</span>
      </div>
    },
    {
      title: "Categoria",
      field: "des_conto",
      editable: (_, rowData) => rowData && rowData.status === 'RINNOVO',
      render: rowData => <div className="text-xs">
        <span>{rowData['des_conto']}</span>
      </div>
    },
    {
      title: "Data ordine",
      field: "dat_arrivo_ordine",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      render: rowData => <div className="w-32">
        <span>{rowData['dat_arrivo_ordine']}</span>
      </div>
    },
    {
      title: "Data vinta HS",
      field: "dat_vinta_hubspot",
      editable: (_, rowData) => rowData && rowData.status !== 'ESOLVER',
      render: rowData => <div className="w-32">
        <span>{rowData['dat_vinta_hubspot']}</span>
      </div>
    },
  ];

  let cols_w_filters = columns;

  // maintains filters active when opening a Dialog component
  if (tableFilters) {
    tableFilters.forEach(i => {
      cols_w_filters = columns.map(c => (c.title === i.column.title ? {...c, defaultFilter: i.value} : c))
    })
  }
  return cols_w_filters

}

export const OPTIONS = {
  filtering: true,
  showTitle: false,
  toolbar: false,
  pageSize: 10,
  sorting: true,
  headerStyle: {fontWeight: "bold", whiteSpace: "nowrap", fontSize: 15, paddingTop: 5, paddingBottom: 5},
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    addTooltip: "Aggiungi",
    editTooltip: "Modifica",
    deleteTooltip: "Elimina",
    editRow: {
      deleteText: "Sei sicuro di voler eliminare la riga?",
      saveTooltip: "Salva",
      cancelTooltip: "Annulla",
    },
    emptyDataSourceMessage: "Non sono presenti dati da visualizzare"
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
    firstTooltip: "Prima pagina",
    nextTooltip: "Pagina successiva",
    previousTooltip: "Pagina precedente",
    lastTooltip: "Ultima pagina"
  },
  toolbar: {
    searchTooltip: "Cerca",
    searchPlaceholder: "Cerca"
  }
};
