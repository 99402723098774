import React, {useEffect, useState} from "react";
import _ from "lodash";
import RevenueTrendBarChart from "./RevenueTrendBarChart";
import {RevenueTotalTable} from "./RevenueTotalTable";
import RadioGroup from "../../../ui-components/RadioGroup";
import Toggle from "../../../ui-components/Toggle";

const categories = [
  'Prestazioni di servizi',
  'Canoni',
  'Manutenzione',
  'Licenze',
  'Hosting',
  'Totale'
]

export const RevenueTotalView = ({
                                   intl,
                                   totalData,
                                   pyData,
                                   selectedYear,
                                   currentMonth,
                                   currentYear,
                                   selectedView,
                                   extraEsolver,
                                   setExtraEsolver,
                                   setSelectedView
                                 }) => {

  const [totalKpis, setTotalKpis] = useState();
  const [chartData, setChartData] = useState();
  const months = [...Array(12).keys()]

  const getCategoryData = (cat, data) => {
    return cat === 'Totale'
      ? data.filter(i => categories.slice(0,2).includes(i.des_conto))
      : data.filter(i => i.des_conto === cat)
  }

  const getContabFcst = (year, m, data) => {
    const k = `${year}-${(m + 1).toString().padStart(2, '0')}-01`
    const contab = data.filter(i => i.flg_consuntivo).reduce((acc, i) => acc + (i.month_detail[k] || 0), 0)
    const fcst = data.filter(i => !i.flg_consuntivo && !i.flg_delta && !i.flg_committed).reduce((acc, i) => acc + (i.month_detail[k] || 0), 0)
    const delta = extraEsolver ? data.filter(i => i.flg_delta).reduce((acc, i) => acc + (i.month_detail[k] || 0), 0) : 0
    const committed = extraEsolver ? data.filter(i => i.flg_committed).reduce((acc, i) => acc + (i.month_detail[k] || 0), 0) : 0
    return [contab, fcst, delta, committed,  contab + fcst + delta + committed]
  }

  useEffect(() => {
    let tmp = categories.reduce((obj, i) => _.set(obj, [i], {absolute: {}, cumulative: {}}), {})
    categories.forEach(c => {

      const dataCat =getCategoryData(c, totalData)
      const dataCatPy =getCategoryData(c, pyData)

      months.forEach(m => {
        const [contab, fcst, delta, committed, total] = getContabFcst(selectedYear, m, dataCat)
        const [contabPy, fcstPy, deltaPy, committedPy, totalPy] = getContabFcst(selectedYear - 1, m, dataCatPy)
        tmp[c]['absolute'][m] = {
          contab: contab, fcst: fcst, delta: delta, committed: committed, total: total,
          contabPy: contabPy, fcstPy: fcstPy, deltaPy: deltaPy, committedPy: committedPy, totalPy: totalPy
        }
        tmp[c]['cumulative'][m] = {
          contab: (tmp[c]['cumulative'][m - 1]?.contab || 0) + contab,
          contabPy: (tmp[c]['cumulative'][m - 1]?.contabPy || 0) + contabPy,
          fcst: (tmp[c]['cumulative'][m - 1]?.fcst || 0) + fcst,
          fcstPy: (tmp[c]['cumulative'][m - 1]?.fcstPy || 0) + fcstPy,
          delta: (tmp[c]['cumulative'][m - 1]?.delta || 0) + delta,
          deltaPy: (tmp[c]['cumulative'][m - 1]?.deltaPy || 0) + deltaPy,
          committed: (tmp[c]['cumulative'][m - 1]?.committed || 0) + committed,
          committedPy: (tmp[c]['cumulative'][m - 1]?.committedPy || 0) + committedPy,
          total: (tmp[c]['cumulative'][m - 1]?.total || 0) + total,
          totalPy: (tmp[c]['cumulative'][m - 1]?.totalPy || 0) + totalPy,
        }
      })
    })
    setTotalKpis(tmp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraEsolver, totalData])

  useEffect(() => {
    if (totalKpis) {
      let res = []

      if (selectedView === 'quarterly') {
        [1, 2, 3, 4].forEach(q => {
          let row = {
            quarter: `Q${q}`,
            none: 0
          }
          categories.slice(0,2).forEach(c => {
            const abs = totalKpis[c]['absolute']
            const cont = abs[(q - 1) * 3]['contab'] + abs[(q - 1) * 3 + 1]['contab'] + abs[(q - 1) * 3 + 2]['contab']
            const contPy = abs[(q - 1) * 3]['contabPy'] + abs[(q - 1) * 3 + 1]['contabPy'] + abs[(q - 1) * 3 + 2]['contabPy']
            if (cont) {row[`quarterly_contab_${c.split(' ')[0]}`] = cont}
            if (contPy) {row[`quarterly_contab_${c.split(' ')[0]}_py`] = contPy}
            const fcst = abs[(q - 1) * 3]['fcst'] + abs[(q - 1) * 3 + 1]['fcst'] + abs[(q - 1) * 3 + 2]['fcst']
            const fcstPy = abs[(q - 1) * 3]['fcstPy'] + abs[(q - 1) * 3 + 1]['fcstPy'] + abs[(q - 1) * 3 + 2]['fcstPy']
            if (fcst) {row[`quarterly_fcst_${c.split(' ')[0]}`] = fcst}
            if (fcstPy) {row[`quarterly_fcst_${c.split(' ')[0]}_py`] = fcstPy}

            const delta = abs[(q - 1) * 3]['delta'] + abs[(q - 1) * 3 + 1]['delta'] + abs[(q - 1) * 3 + 2]['delta']
            if (delta) {row["quarterly_delta_Delta"] = (row["quarterly_delta_Delta"] || 0) + delta}
            const committed = abs[(q - 1) * 3]['committed'] + abs[(q - 1) * 3 + 1]['committed'] + abs[(q - 1) * 3 + 2]['committed']
            if (committed) {row["quarterly_committed_Committed"] = (row["quarterly_committed_Committed"] || 0) + committed}
          })
          res.push(row)
        })
      } else {
        months.forEach(m => {
          let row = {
            month: `${selectedYear}-${(m + 1).toString().padStart(2, '0')}-01`,
            none: 0
          }
          categories.slice(0,2).forEach(c => {
            ['absolute', 'cumulative'].forEach(v => {
              ['contab', 'fcst'].forEach(kpi => {
                if (totalKpis[c][v][m][kpi]) {
                  row[`${v}_${kpi}_${c.split(' ')[0]}`] = totalKpis[c][v][m][kpi]
                }
                if (totalKpis[c][v][m][`${kpi}Py`]) {
                  row[`${v}_${kpi}_${c.split(' ')[0]}_py`] = totalKpis[c][v][m][`${kpi}Py`]
                }
              });
              ['delta', 'committed'].forEach(kpi => {
                if (totalKpis[c][v][m][kpi]) {
                  const name = kpi.charAt(0).toUpperCase() + kpi.slice(1)
                  row[`${v}_${kpi}_${name}`] = (row[`${v}_${kpi}_${name}`] || 0) + totalKpis[c][v][m][kpi]
                }
              })
            })
          })
          res.push(row)
        })
      }
      setChartData(res)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedView, totalKpis])

  return (
    <div className="mt-4 flex flex-col mb-10">
      {totalKpis && totalData.length ?
        <div className="flex flex-col gap-5">
          {chartData &&
            <div>
              <div className="w-full flex flex-col sm:flex-row gap-32 justify-center">
                <RadioGroup
                  id="choose_view"
                  horizontal
                  options={[
                    {value: "absolute", name: "Mensile"},
                    {value: "quarterly", name: "Quarter"},
                    {value: "cumulative", name: "Cumulato"}
                  ]}
                  currentValue={selectedView}
                  onChange={(value) => {
                    setSelectedView(value)
                  }}
                />
                <div className="-mt-1 flex flex-row">
                  <Toggle
                    checked={extraEsolver}
                    onChange={() => setExtraEsolver(!extraEsolver)}
                  />
                  <p className="mt-6 text-sm mx-2">Includi extra Esolver</p>
                </div>
              </div>
              <RevenueTrendBarChart
                id="revenue-trend-barchart"
                data={chartData}
                categories={categories.slice(0,2)}
                selectedView={selectedView}
              />
            </div>
          }
          <RevenueTotalTable
            intl={intl}
            months={months}
            categories={categories}
            currentMonth={currentMonth}
            currentYear={currentYear}
            selectedYear={selectedYear}
            totalKpis={totalKpis}
            selectedView={selectedView}
          />
        </div>
        : null
      }
    </div>);
}
