import React, {useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";

// Components
import OkrTeamProgressBarChart from "./OkrTeamProgressBarChart";

// UI
import Placeholder from "../../ui-components/Placeholder";


function TeamProgress({push, intl, toggleHistory}) {

  const [okrTeamsData, setOkrTeamsData] = useState();

  const fetchOkrTeamsData = () => {
    apiRequest
      .get(`/okrs/teams_okr_progress?toggle_history=${toggleHistory}`)
      .then((result) => {
        setOkrTeamsData(
          Object.keys(result).map(key => ({
            category: key,
            value: result[key]
          })));
      })
      .catch(() => {
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
      })
  }

  useEffect(() => {
    fetchOkrTeamsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleHistory])

  return (
    <div className="mt-10">
      {okrTeamsData ? <div className="w-full bg-gray-100 mt-2 rounded-lg">
        <h1 className="text-center font-bold mb-10">Completamento OKR aggregato per team</h1>
        <OkrTeamProgressBarChart
          className="mt-20"
          id="teams-okr-progress-chart"
          data={okrTeamsData}
          measure='amt'
        />
      </div> : <Placeholder width="w-full" height="h-80"/>
      }
    </div>
  )
}

export default TeamProgress;
