import React, {useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import TableStyle from "../../../ui-components/TableStyle";
import {tableIcons} from "../../../ui-components/TableIcons";
import {OPTIONS, getLocalization, genericOnRowUpdate, genericOnRowAdd, genericOnRowDelete} from "./Params";


function SkillsTable({intl, push, data, setData, item, areas, types, topics}) {

  const [LOCALIZATION] = useState(getLocalization(intl))
  const _OPTIONS = {...OPTIONS, filtering: true, paging: true, pageSize: 5}

  const cod = 'id_skill'
  const des = 'des_skill'

  const _onRowUpdate = (newData, oldData) =>
    genericOnRowUpdate(newData, oldData, item, cod, setData, data, intl, push)

  const _onRowAdd = (newData) =>
    genericOnRowAdd(newData, item, cod, setData, data, intl, push)

  const _onRowDelete = (oldData) =>
    genericOnRowDelete(oldData, item, cod, setData, data, intl, push)

  return (
    <TableStyle>
      <MaterialTable
        columns={[
          {
            title: intl.formatMessage({id: "area"}),
            field: 'topic.cod_area',
            lookup: Object.values(areas).reduce((obj, i) => _.set(obj, [i.cod_area], i.cod_area), {}),
            width: "10%"
          },
          {
            title: intl.formatMessage({id: "type"}),
            field: 'topic.cod_type',
            lookup: Object.values(types).reduce((obj, i) => _.set(obj, [i.cod_type], i.cod_type), {}),
            width: "10%"
          },
          {
            title: intl.formatMessage({id: "topic"}),
            field: 'topic.id_topic',
            lookup: Object.values(topics).reduce((obj, i) => _.set(obj,
              [i.id_topic], `${i.cod_area} - ${i.cod_type} - ${i.des_topic}`), {})
          },
          {
            title: intl.formatMessage({id: "name"}),
            field: des
          },
        ]}
        data={_.orderBy(Object.values(data), ['topic.cod_area', 'topic.cod_type', 'topic.des_topic', 'des_skill'])
          .map((r, i) => ({...r, id: i}))}
        icons={tableIcons}
        title={intl.formatMessage({id: 'skills'})}
        options={_OPTIONS}
        localization={LOCALIZATION}
        editable={{
          onRowUpdate: _onRowUpdate,
          onRowAdd: _onRowAdd,
          onRowDelete: _onRowDelete
        }}
      />
    </TableStyle>
  );
}

export default React.memo(SkillsTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.data, nextProps.data)
    && _.isEqual(props.areas, nextProps.areas)
    && _.isEqual(props.types, nextProps.types)
    && _.isEqual(props.topics, nextProps.topics);
});
