import { useEffect, useState } from "react";
import CheckboxGroup from "@ui-components/CheckboxGroup";
import SearchSelect from "@ui-components/SearchSelect";
import RadioGroup from "@ui-components/RadioGroup";
import { PageSection } from "@ui-components/Container";
import Tabs from "@ui-components/Tabs";
import { api } from "@services/apiRequest";
import _ from "lodash";
import moment from "moment";
import { formatDate } from "./index";
import RicaviGiornataChart from "./RicaviGiornataChart";
import RicaviFteChart from "./RicaviFteChart";

const getRollingMonths = (month) => {
  const currentMonth = moment(month);
  let tmp = [];
  let prev12Month = moment(month).subtract(11, "months");
  while (prev12Month.isBefore(currentMonth)) {
    tmp.push(formatDate(prev12Month));
    prev12Month.add(1, "month");
  }
  tmp.push(formatDate(currentMonth));
  return tmp;
};

function DashBoardRevenueCharts({ teams, push }) {
  const [selectedTeams, setSelectedTeams] = useState([
    ...teams.map((team) => team.value),
    "",
  ]);
  const [clients, setClients] = useState([]);
  const [commesse, setCommesse] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedCommessa, setSelectedCommessa] = useState([]);
  const [selectedView, setSelectedView] = useState("ytd");
  const [data, setData] = useState();
  const [ricavoGiornataChartData, setRicavoGiornataChartData] = useState();
  const [ricavoFteChartData, setRicavoFteChartData] = useState();
  const [selectedChart, setSelectedChart] = useState(0);

  const fetchClients = () => {
    api
      .get(`/economics/productivity/analisi-ricavi/clients`)
      .then(({ data: result }) => {
        setClients(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchCommesse = () => {
    const payload = {
      clients: selectedClient.map((c) => c.value),
    };

    api
      .post(`/economics/productivity/analisi-ricavi/commesse`, payload)
      .then(({ data: result }) => {
        setCommesse(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  useEffect(() => {
    fetchClients();
    fetchCommesse();
  }, [selectedClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    const payload = {
      teams: selectedTeams,
      selectedView: selectedView,
      clients: selectedClient.map((c) => c.value),
      commesse: selectedCommessa.map((c) => c.value),
    };

    api
      .post(`/economics/productivity/analisi-ricavi`, payload)
      .then(({ data: result }) => {
        setData(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedTeams, selectedView, selectedClient, selectedCommessa]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const calcRicavoGiornataChartData = () => {
    if (!data) return;
    const ricavi = data.trend;
    const ore = data.trend_ore;
    const dataRicavoGiornata = _.merge({}, ricavi, ore);
    let chartData = [];

    if (selectedView === "ytd") {
      let sumRicavi = 0;
      let sumGiorni = 0;
      for (const value of Object.values(dataRicavoGiornata)) {
        sumRicavi += value.val_ricavi;
        sumGiorni += value.val_ore / 8;
        const tmp = {
          dat_month: value.dat_month,
          ricavi: sumRicavi,
          giorni: sumGiorni,
          kpi: sumRicavi / sumGiorni,
        };
        chartData.push(tmp);
      }
    } else if (selectedView === "rolling") {
      let rollingMonths = {};

      const currentDate = new Date();
      let rollingMonthStart;

      if (currentDate.getDate() > 10) {
        rollingMonthStart = formatDate(moment().subtract(12, "months").date(1));
      } else {
        rollingMonthStart = formatDate(moment().subtract(13, "months").date(1));
      }

      const data = _.values(dataRicavoGiornata);

      const months = data
        .filter((d) => d.dat_month >= rollingMonthStart)
        .map((d) => d.dat_month);

      months.forEach((month) => {
        rollingMonths[month] = getRollingMonths(month);
      });

      Object.keys(rollingMonths).forEach((month) => {
        const tmp = rollingMonths[month].map((m) => {
          const monthData = _.find(data, { dat_month: m });
          return monthData;
        });
        rollingMonths[month] = tmp;
      });

      Object.keys(rollingMonths).forEach((month) => {
        const sumRicavi = rollingMonths[month].reduce((acc, curr) => {
          if (!curr) return acc;
          return acc + curr.val_ricavi;
        }, 0);

        const sumGiorni = rollingMonths[month].reduce((acc, curr) => {
          if (!curr) return acc;
          return acc + curr.val_ore / 8;
        }, 0);

        const tmp = {
          dat_month: month,
          ricavi: sumRicavi,
          giorni: sumGiorni,
          kpi: sumRicavi / sumGiorni,
        };

        chartData.push(tmp);
      });
    }

    setRicavoGiornataChartData(chartData);
  };

  const calcRicavoFteChartDataTotale = (fte, ricavi) => {
    const fteData = _.values(_.groupBy(fte, "dat_month"));
    const ricaviData = _.values(ricavi);
    const chartData = [];

    if (selectedView === "ytd") {
      let fteCumulato = 0;
      let ricaviCumulati = 0;
      let ftePeriodo = fteData.map((monthData, index) => {
        fteCumulato += monthData.reduce(
          (acc, v) => acc + v.ore_lavorabili_risorsa / v.ore_totali_mese,
          0
        );

        return {
          dat_month: monthData[0].dat_month,
          fte_periodo: Math.floor((fteCumulato / (index + 1)) * 10) / 10,
        };
      });

      let ricaviPeriodo = ricaviData.map((r) => {
        ricaviCumulati += r.val_ricavi;
        return {
          dat_month: r.dat_month,
          ricavi: ricaviCumulati,
        };
      });

      const finalData = _.merge([], ftePeriodo, ricaviPeriodo).map((d) => {
        return {
          dat_month: d.dat_month,
          fte_periodo: d.fte_periodo,
          ricavi: d.ricavi,
          kpi: d.ricavi / d.fte_periodo,
        };
      });
      chartData.push(...finalData);
    } else if (selectedView === "rolling") {
      let rollingMonths = {};

      const currentDate = new Date();
      let rollingMonthStart;

      if (currentDate.getDate() > 10) {
        rollingMonthStart = formatDate(moment().subtract(12, "months").date(1));
      } else {
        rollingMonthStart = formatDate(moment().subtract(13, "months").date(1));
      }

      const months = ricaviData
        .filter((d) => d.dat_month >= rollingMonthStart)
        .map((d) => d.dat_month);

      months.forEach((month) => {
        rollingMonths[month] = getRollingMonths(month);
      });

      Object.keys(rollingMonths).forEach((month) => {
        const tmp = rollingMonths[month].map((m) => {
          const monthData = _.find(ricaviData, { dat_month: m });
          const fte = _.flatten(fteData).filter((f) => f.dat_month === m);
          return {
            ricavi: monthData,
            fte: fte,
          };
        });
        rollingMonths[month] = tmp;
      });

      Object.keys(rollingMonths).forEach((month) => {
        let fteCumulato = 0;
        let ricaviCumulati = 0;
        rollingMonths[month].forEach((monthData, index) => {
          if (monthData.fte.length > 0) {
            fteCumulato += monthData.fte.reduce(
              (acc, v) => acc + v.ore_lavorabili_risorsa / v.ore_totali_mese,
              0
            );
          } else {
            fteCumulato += 0;
          }
        });

        rollingMonths[month].forEach((monthData) => {
          ricaviCumulati += monthData.ricavi.val_ricavi;
        });

        const finalData = {
          dat_month: month,
          fte_periodo: fteCumulato / rollingMonths[month].length,
          ricavi: ricaviCumulati,
          kpi: ricaviCumulati / (fteCumulato / rollingMonths[month].length),
        };
        chartData.push(finalData);
      });
    }
    setRicavoFteChartData(chartData);
  };

  const calcRicavoFteChartDataFiltrato = (ricavi, oreRisorse, oreDetail) => {
    if (selectedView === "ytd") {
      let tmp = [];
      let fteCumulato = 0;
      let ricaviCumulati = 0;
      ricavi.forEach((ricavi, idx) => {
        let fte = 0;
        ricaviCumulati += ricavi.val_ricavi;
        const oreMeseCommesse = oreDetail.filter(
          (o) => o.dat_month === ricavi.dat_month
        );

        oreMeseCommesse.forEach((commessa) => {
          Object.keys(commessa.detail).forEach((risorsa) => {
            const oreTotaliRisorsa =
              oreRisorse.find(
                (r) =>
                  r.dat_month === ricavi.dat_month && r.cod_risorsa === risorsa
              ).val_ore || 0;

            fte +=
              Math.floor((commessa.detail[risorsa] / oreTotaliRisorsa) * 1000) /
              1000;
          });
        });
        fteCumulato += fte;
        tmp.push({
          dat_month: ricavi.dat_month,
          fte_periodo: fteCumulato ? fteCumulato / (idx + 1) : 0,
          ricavi: ricaviCumulati,
          kpi: ricaviCumulati / (fteCumulato ? fteCumulato / (idx + 1) : 1),
        });
      });
      setRicavoFteChartData(tmp);
    } else if (selectedView === "rolling") {
      let rollingMonths = {};
      let tmp = [];

      const currentDate = new Date();
      let rollingMonthStart;

      if (currentDate.getDate() > 10) {
        rollingMonthStart = formatDate(moment().subtract(12, "months").date(1));
      } else {
        rollingMonthStart = formatDate(moment().subtract(13, "months").date(1));
      }

      const data = _.values(ricavi);

      const months = data
        .filter((d) => d.dat_month >= rollingMonthStart)
        .map((d) => d.dat_month);

      months.forEach((month) => {
        rollingMonths[month] = getRollingMonths(month);
      });

      Object.keys(rollingMonths).forEach((month) => {
        const tmp = rollingMonths[month].map((m) => {
          const monthData = _.find(data, { dat_month: m });
          return monthData;
        });
        rollingMonths[month] = tmp;
      });

      Object.keys(rollingMonths).forEach((month) => {
        let fteCumulato = 0;
        const sumRicavi = rollingMonths[month].reduce((acc, curr) => {
          if (!curr) return acc;
          return acc + curr.val_ricavi;
        }, 0);

        rollingMonths[month].forEach((data) => {
          if (!data) return;
          let fte = 0;
          const oreMeseCommesse = oreDetail.filter(
            (o) => o.dat_month === data.dat_month
          );
          oreMeseCommesse.forEach((commessa) => {
            Object.keys(commessa.detail).forEach((risorsa) => {
              const oreTotaliRisorsa =
                oreRisorse.find(
                  (r) =>
                    r.dat_month === data.dat_month && r.cod_risorsa === risorsa
                ).val_ore || 0;

              fte +=
                Math.floor(
                  (commessa.detail[risorsa] / oreTotaliRisorsa) * 1000
                ) / 1000;
            });
          });
          fteCumulato += fte;
        });
        tmp.push({
          dat_month: month,
          fte_periodo: fteCumulato
            ? fteCumulato / rollingMonths[month].length
            : 0,
          ricavi: sumRicavi,
          kpi:
            sumRicavi /
            (fteCumulato ? fteCumulato / rollingMonths[month].length : 1),
        });
      });
      setRicavoFteChartData(tmp);
    }
  };
  const calcRicavoFteChartData = () => {
    if (!data) return;

    const ricavi = data.trend;
    const oreRisorse = data.risorse;
    const oreDetail = data.ore_detail;
    const fte = data.fte;

    if (selectedClient.length > 0 || selectedCommessa.length > 0) {
      calcRicavoFteChartDataFiltrato(ricavi, oreRisorse, oreDetail);
    } else {
      calcRicavoFteChartDataTotale(fte, ricavi);
    }
  };

  useEffect(() => {
    calcRicavoGiornataChartData();
    calcRicavoFteChartData();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="mt-5">
        <PageSection title={"Filtra per Team"}>
          <div className="flex flex-col items-center">
            <CheckboxGroup
              horizontal
              options={teams}
              values={selectedTeams}
              onChange={(value) => {
                if (selectedTeams.indexOf(value) >= 0) {
                  setSelectedTeams([
                    ...selectedTeams.filter((v) => v !== value),
                  ]);
                } else {
                  setSelectedTeams([...selectedTeams, value]);
                }
              }}
            />
          </div>
        </PageSection>
      </div>

      <div className="mb-5 -mt-8">
        <div className="w-full border-t border-gray-300" />
      </div>

      <div className="flex">
        <div className="flex">
          <span className="text-lg lg:mt-2 mr-2">Filtra Clienti</span>
          <SearchSelect
            isMulti
            options={clients.map((c) => ({
              value: c.cod_cliente,
              label: c.des_cliente,
            }))}
            values={selectedClient}
            onChange={(value) => {
              setSelectedClient(value);
            }}
          />
        </div>

        <div className="flex mx-10">
          <span className="text-lg lg:mt-2 mr-2">Filtra Commesse</span>
          <SearchSelect
            isMulti
            options={commesse.map((c) => ({
              value: c.cod_commessa,
              label: c.cod_commessa + " - " + c.des_commessa,
            }))}
            values={selectedCommessa}
            onChange={(value) => {
              setSelectedCommessa(value);
            }}
          />
        </div>

        <div className="flex -mt-1 mx-auto">
          <RadioGroup
            options={[
              {
                value: "ytd",
                name: "YTD",
              },
              {
                value: "rolling",
                name: "12 Mesi Rolling",
              },
            ]}
            currentValue={selectedView}
            onChange={(value) => setSelectedView(value)}
            horizontal
          />
        </div>
      </div>

      <div className="flex flex-col mt-3 gap-y-3">
        <Tabs
          tabs={[
            {
              value: 0,
              name: "Ricavi Per FTE",
            },
            {
              value: 1,
              name: "Ricavi Per Giornata",
            },
          ]}
          active={selectedChart}
          setActiveTab={setSelectedChart}
        />

        {selectedChart === 0 ? (
          <RicaviFteChart id={"ricavi-per-fte"} data={ricavoFteChartData} />
        ) : (
          <RicaviGiornataChart
            id={"ricavi-per-giornata"}
            data={ricavoGiornataChartData}
          />
        )}
      </div>
    </>
  );
}

export default DashBoardRevenueCharts;
