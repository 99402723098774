import React, {useCallback, useContext, useState} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";

import Input from "../../ui-components/Input";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import InputMultiLine from "../InputMultiLine";
import {DataContext} from "../../services/DataContext/DataContext";
import Label from "../../ui-components/Label";
import apiRequest from "../../services/apiRequest";
import SearchSelect from "../../ui-components/SearchSelect";
import Toggle from "../../ui-components/Toggle";

function AddNewCourse({intl, toggleOpen, refresh, setRefresh, editRow}) {

  const initialState = {
    name_course: editRow?.name_course,
    des_course: editRow?.des_course,
    json_url_site: editRow?.json_url_site,
    val_duration: editRow?.val_duration,
    flg_active_course: editRow?.flg_active_course || true,
    speaker: editRow?.speaker || "",
    note: editRow?.note || "",
    id_type: editRow?.id_type || null,
  }

  const {push} = useContext(NotificationsContext);
  const dataContext = useContext(DataContext);

  const [loading, setLoading] = useState(false);

  const [course, setCourse] = useState(initialState);
  const [linkList, setLinkList] = useState(initialState['json_url_site'] ? initialState['json_url_site'] : {});
  const [link, setLink] = useState("");
  const [link_type, setLinkType] = useState("");

  console.log(linkList)

  const [skillList, setSkillList] = useState(editRow
    ? editRow['skills_list'].map(i => ({value: i, label: i}))
    : []
  );

  const optionsSkill = dataContext.skillList ? dataContext.skillList.map(i => ({
    value: i.id_skill,
    label: i.des_skill,
  })) : []

  const optionsType = dataContext.courseTypeList ? [...new Set(Object.values(dataContext.courseTypeList).map(i => i.id_type))].map(i => ({
    value: i,
    label: i
  })) : []

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        if (!course.name_course || !course.des_course || !course.val_duration || !course.id_type) {
          push({
            title: "Missing course data",
            type: "error",
          });
          return;
        }

        setLoading(true);
        const idPath = editRow ? `/${editRow.id_course}` : ""
        apiRequest
          .post(`/course-suite/courses${idPath}`, course)
          .then((result) => {
            // Add skill to course
            let multiSkill = [];
            (skillList || []).forEach((skill) => {
              console.log(skill)
              if (!multiSkill.includes(skill.value)) {
                multiSkill.push(skill.value)
              }
            })
            apiRequest
              .post(`/course-suite/relative/${result['id_course']}`, multiSkill.map(i => ({id_skill: i})))
              .then(() => {
                push({
                  title: editRow ? "Corso modificato correttamente" : "Corso aggiunto correttamente",
                  type: "success",
                });
                toggleOpen();
                setRefresh(!refresh);
                setCourse(initialState);
              })
              .catch(() => {push({title: "Qualcosa è andato storto", type: "error"})})
          })
          .catch(() => {push({title: "Qualcosa è andato storto", type: "error"})})

      } catch (error) {
        push({title: "Qualcosa è andato storto", type: "error"})
      } finally {
        setLoading(false);
      }
    },
    [course, skillList] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            {editRow
              ? intl.formatMessage({id: "update_course"})
              : intl.formatMessage({id: "add_new_course"})
            }
          </h3>
        </div>

        <form id="add-course-form" onSubmit={_onSubmit}>
          <div className="flex flex-col sm:flex-row sm:gap-x-10">
            <div className="w-10/12">
              <Input
                value={course.name_course}
                onChange={(e) => {
                  setCourse({...course, name_course: e.target.value})
                }}
                label="Course Name"
                type="text"
                placeholder="Add course name"
                errorMessage="Missing course name"
                required
              />
            </div>
            <div className="w-2/12 justify-end">
              <p className="-mb-2">Attivo</p>
              <Toggle
                checked={course.flg_active_course}
                onChange={() => setCourse({...course, flg_active_course: !course.flg_active_course})}
              />
            </div>
          </div>
          <div>
            <InputMultiLine
              rows={5}
              value={course.des_course}
              onChange={(e) => {
                setCourse({...course, des_course: e.target.value})
              }}
              label="Description of the course"
              type="text"
              placeholder="Add the description of the course"
              errorMessage="Missing description of the course"
              required
            />
          </div>
          <div className="Links grid grid-cols-4 pb-4">
            <div className="Link-input col-span-3 grid grid-cols-8">
              <div className="px-2 col-span-5">
                <Input
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value)
                  }}
                  label="Course Site"
                  type="text"
                  placeholder="Add URL of the course"
                  errorMessage="Missing course site"
                />
              </div>
              <div  className="px-2 col-span-3">
                <Input
                  value={link_type}
                  onChange={(e) => {
                    setLinkType(e.target.value)
                  }}
                  label="Course Type"
                  type="text"
                  placeholder="Add type of link"
                  errorMessage="Missing type"
                />
              </div>
            </div>
            <div className="Link-button flex col-span-1 items-center justify-center">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  let key =  link_type + (Object.keys(linkList).length + 1);
                  setLinkList({...linkList, [key]: link});
                  setCourse({ ...course, json_url_site: {...linkList, [key]: link} })
                  setLink("");
                  setLinkType("");
                }}
              >{intl.formatMessage({id: "add_link"})}</Button>
            </div>
            {
              Object.keys(linkList).map((value, index) => {
                return (
                  <>
                    <div className="flex col-span-3 pl-4" key={index}>
                      <a href={linkList[value]} target="_blank" rel="noreferrer" className="flex truncate items-center w-8/12">{linkList[value]}</a>
                      <div className="flex items-center justify-center w-4/12">{value}</div>
                    </div>
                    <div className="flex col-span-1 items-center justify-center">
                      <Button
                        styleType="danger"
                        onClick={(e) =>{
                          e.preventDefault();
                          let list = Object.fromEntries(Object.entries(linkList).filter(([key, val]) => key !== value))
                          setLinkList(list);
                          setCourse({ ...course, json_url_site: list })
                        }}
                      >{intl.formatMessage({ id: "remove_link" })}</Button>
                    </div>
                  </>
                )
              })
            }
          </div>
          <div>
            <Label>Selection of Skills</Label>
            <SearchSelect
              value={skillList}
              isMulti
              onChange={(e) => setSkillList(e)}
              options={optionsSkill}/>
          </div>
          <div className="pt-3 pb-3">
            <Label>Selection of Type</Label>
            <SearchSelect
              value={{value: course.id_type, label: course.id_type}}
              onChange={(e) => {
                setCourse({...course, id_type: e.value})
              }}
              options={optionsType}/>
          </div>
          <div>
            <Input
              value={course.speaker}
              onChange={(e) => {
                setCourse({...course, speaker: e.target.value})
              }}
              label="Speaker"
              type="text"
              placeholder="Add the speaker of the course"
              errorMessage="Missing course speaker"
            />
          </div>
          <div className="">
            <div className="mt-5">
              <Input
                value={course.val_duration}
                onChange={(e) => {
                  setCourse({...course, val_duration: parseInt(e.target.value)})
                }}
                label="Duration in hours"
                placeholder="Add hours as an integer"
                type="text"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                errorMessage="Error: insert a positive integer"
                required
                numberOptions={{
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }}
              />
            </div>
            <div>
              <InputMultiLine
                rows={2}
                value={course.note}
                onChange={(e) => {
                  setCourse({...course, note: e.target.value})
                }}
                label="Notes"
                type="text"
                placeholder="Add notes for the course"
              />
            </div>
            <div className="mt-6 flex items-end justify-end">
              <Button submitting={loading} type="submit">
                {editRow
                  ? intl.formatMessage({id: "update"})
                  : intl.formatMessage({id: "add"})
                }
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddNewCourse;
