import React, {useEffect, useState} from "react";
import {PageSection} from "../../ui-components/Container";
import StaffTable from "../../components/Tables/StaffTable";
import apiRequest from "../../services/apiRequest";
import Placeholder from "../../ui-components/Placeholder";
import AddStaffToProject from "../../components/Dialogs/AddStaffToProject";
import Button from "../../ui-components/Button";
import {useIntl} from "react-intl";
import _ from "lodash";

function ProjectStaffing({project, editMode, organizationChart, almagammaRoles, dataContext}) {
  const lkpRoles = almagammaRoles.reduce((obj, i) => _.set(obj, [i['id_role']], i['role_name']), {})
  const organizationRoles = organizationChart.reduce((obj, i) => _.set(obj, [i['id_user']], lkpRoles[i['id_user_role']]), {})

  const intl = useIntl();

  const [projStaff, setProjStaff] = useState();
  const [showAdd, setShowAdd] = useState(false);

  const _setProjStaff = (e) => {
    dataContext.setMyProjects(undefined)
    setProjStaff(e)
  }
  const today = new Date();

  const fetchProjectStaffId = () => {
    apiRequest
      .get(`/projects/working_on/list_users_by_project/${project.id_proj}`)
      .then((result) => {
        setProjStaff((result || []).map(e => ({
          name: e.user.full_name,
          id: e.id_user,
          dat_init: e.dat_init,
          dat_fine: e.dat_fine
        })));
      })
      .catch(() => {
        console.log("ERROR")
      })
  }

  useEffect(() => {
    fetchProjectStaffId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])


  return (
    <div>
      <PageSection
        title={
          <div className="flex justify-between items-center">
            <div>
              {intl.formatMessage({id: "staff"})}
            </div>
            {editMode &&
              <div>
                <Button onClick={() => setShowAdd(true)} className="h-8">
                  +
                </Button>
              </div>
            }
          </div>
        }
      >
        {projStaff ? (
          <>
            <div className="flex justify-center my-2">{intl.formatMessage({id: "active"})}</div>
            <StaffTable
              projStaff={(projStaff || []).filter(e => Date.parse(e.dat_init) < today && today < Date.parse(e.dat_fine))}
              setProjStaff={_setProjStaff}
              project={project}
              editMode={editMode}
              organizationRoles={organizationRoles}
            />
          </>
        ) : (
          <div>
            <Placeholder width="w-full" height="h-64"/>
            <Placeholder width="w-full" height="h-64"/>
          </div>
        )}
        {(projStaff || []).filter(e => Date.parse(e.dat_init) > today || today > Date.parse(e.dat_fine)).length ?
          <>
            <div className="flex justify-center mt-4 mb-2">{intl.formatMessage({id: "inactive"})}</div>
            <StaffTable
              projStaff={(projStaff || []).filter(e => Date.parse(e.dat_init) > today || today > Date.parse(e.dat_fine))}
              setProjStaff={_setProjStaff}
              project={project}
              editMode={editMode}
              organizationRoles={organizationRoles}
            />
          </>
          : null
        }
      </PageSection>
      {showAdd &&
        <AddStaffToProject
          open={showAdd}
          toggleOpen={() => setShowAdd(false)}
          project={project}
          setProjStaff={_setProjStaff}
          projStaff={projStaff}
          organizationRoles={organizationRoles}
        />
      }
    </div>
  );
}

export default ProjectStaffing;