import React from "react";
import {CustomFilterDatePicker} from "./CustomDatePicker";

export const getColumns = (customers) => {
  return [
    {
      title: "Cliente",
      field: "cod_customer",
      lookup: customers.sort().reduce((o, i) => ({...o, [i.cod_customer]: i.des_customer}), {}),
      cellStyle: {textTransform: "uppercase", width: 250, minWidth: 250},
      headerStyle: {fontWeight: "bold", width: 250, minWidth: 250},
    },
    {
      title: "Codice",
      field: "project_number",
      type: "string",
      cellStyle: {fontWeight: "bold"},
      defaultSort: "desc",
    },
    {
      title: "Nome",
      field: "project_name",
      cellStyle: {textTransform: "uppercase", width: 350, minWidth: 350},
      headerStyle: {fontWeight: "bold", width: 350, minWidth: 350},
      type: "string"
    },
    {
      title: "Inizio",
      field: "dat_init",
      type: "date",
      filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    },
    {
      title: "Fine",
      field: "dat_finish",
      type: "date",
      filterComponent: (props) => <CustomFilterDatePicker {...props}/>,
    },
  ]
}

export const OPTIONS = {
  filtering: true,
  toolbar: false,
  pageSize: 10,
  sorting: true,
  showTitle: false,
  header: true,
  headerStyle: {fontWeight: "bold"},
  actionsColumnIndex: -1
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
  }
};
