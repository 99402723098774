import React from "react";

function Node({ Person , onClick , active }) {
  return (
    <div className={`px-4 py-1 flex flex-col items-center rounded-3xl border-2 ${active ? 'text-gray-50 bg-am-200 border-am-200' : 'text-am-800 bg-am-400 border-am-400'} shadow-md transform transition duration-100 cursor-pointer hover:shadow-2xl hover:scale-105 px-1`} onClick={onClick}>
      <p className="font-bold text-sm whitespace-pre">{Person['user_name']}</p>
      <p className="text-xs whitespace-pre">{Person['user_role_des']}</p>
    </div>
  )
}

export default Node;