import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, setChartColor} from "./commons";

function RadarChart({id, data, setter = null, useLegend}) {

  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        // innerRadius: am5.percent(30),
        radius: am5.percent(70),
        layout: root.verticalLayout
        // startAngle: -180,
        // endAngle: 0
      })
    );

    root.numberFormatter.set("numberFormat", "#,###.00");

    setChartColor(chart);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    let cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {}));

    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes

    // RENDERER X AXIS
    let xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 10
    });

    let textType = "adjusted";
    if (data.length >= 15)
      textType = "radial"

    xRenderer.labels.template.setAll({
      fontSize: 12,
      fontWeight: '300',
      textType: textType,
      radius: 20,
      paddingTop: 0,
      paddingBottom: 0,
      centerY: am5.p50,
      oversizedBehavior: "wrap",
      maxWidth: 100
    });

    xRenderer.grid.template.setAll({
      location: 0.5,
      strokeDasharray: [2, 2]
    });

    // X AXIS
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "level",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {})
      })
    );

    // MAKE LABELS CLICKABLE IF A SETTER FUNCTION IS PASSED
    if (setter) {
      xAxis.get('renderer').labels.template.setup = function (target) {
        target.set("background", am5.Rectangle.new(root, {
          fill: am5.color(0xff0000),
          fillOpacity: 0,
          cursorOverStyle: "pointer",
        }))
      }

      xAxis.get('renderer').labels.template.events.on('click', function (ev) {
        setter(ev.target.getText());
      });

      xAxis.get('renderer').labels.template.set("interactive", true);

      xAxis.get('renderer').labels.template.states.create("hover", {
        fill: am5.color(0xbb9f06),
      })
    }

    // RENDERER Y AXIS
    let yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 25
    });

    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2]
    });

    // Y AXIS
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: yRenderer,
          max: 5,
          min: 0
      })
    );


    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series

    // SERIES 2
    let series2 = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        name: "Self Evaluation",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "id_grade",
        categoryXField: "level",
        stacked: false,
        connectEnds: true,
        tooltip:am5.Tooltip.new(root, {labelText:"[bold]Self[\bold] \n{categoryX}: [bold]{valueY}[\bold]"})
      })
    );

    series2.strokes.template.setAll({
      strokeOpacity: 0
    });

    series2.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5
    });

    series2.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 2,
          fill: series2.get("fill")
        })
      });
    });

    // SERIES 3
    let series3 = chart.series.push(
      am5radar.RadarLineSeries.new(root, {
        name: "Superiors",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "avg_superior",
        categoryXField: "level",
        stacked: false,
        connectEnds: true,
        tooltip:am5.Tooltip.new(root, {labelText:"[bold]Valutazione[\bold] \n{categoryX}: [bold]{valueY}[\bold]"})
      })
    );

    series3.strokes.template.setAll({
      strokeOpacity: 0
    });

    series3.fills.template.setAll({
      visible: true,
      fillOpacity: 0.5
    });

    series3.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 2,
          fill: series3.get("fill")
        })
      });
    });


    // LEGEND
    let legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.percent(50),
        centerX: am5.percent(40),
        layout: root.horizontalLayout
      })
    );
    if (useLegend) {
      legend.data.setAll([series2, series3]);
    }

    series2.data.setAll(data);
    series3.data.setAll(data);
    xAxis.data.setAll(data);

    // Make stuff animate on load
    series2.appear(1000);
    series3.appear(1000);

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minHeight: "500px"}}/>
}

export default React.memo(RadarChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})