import { PageHeading } from "@ui-components/Container";
import Placeholder from "@ui-components/Placeholder";
import Tabs from "@ui-components/Tabs";
import { useState, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { api } from "@services/apiRequest";
import { NotificationsContext } from "../../../ui-components/Notifications";
import DashboardDettaglio from "./Dettaglio";
import DashBoardMarginality from "./Marginality";
import DashboardRoles from "./Roles";
import DashBoardRevenueCharts from "./RevenueCharts";

 export const teams = [
  {
    name: "Data Science",
    value: "DS",
  },
  {
    name: "Data Architecture & Visualization",
    value: "DAV",
  },
  {
    name: "Product Manager",
    value: "PM",
  },
  {
    name: "Innovation",
    value: "INN",
  },
  {
    name: "Human Sciences",
    value: "HS",
  },
  {
    name: "Customer Care",
    value: "CC",
  },
];

const minDate = new Date(2022, 0, 1);

let defaultDate = new Date();
if (defaultDate.getMonth() === 0) {
  defaultDate.setFullYear(defaultDate.getFullYear() - 1);
} else {
  defaultDate.setMonth(0);
  defaultDate.setDate(1);
}

let maxDate = new Date();
const year = maxDate.getFullYear();
const month = maxDate.getMonth();

if (maxDate.getDate() > 10) {
  maxDate.setDate(1);
  maxDate.setMonth(maxDate.getMonth() -1);
} else {
  if (month === 0) {
    maxDate.setFullYear(year - 1);
    maxDate.setMonth(10);
    maxDate.setDate(1);
  } else {
    maxDate.setMonth(maxDate.getMonth() - 2);
    maxDate.setDate(1);
  }
}

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

export default function DashboardProductivity() {
  const intl = useIntl();
  const [selectedTeams, setSelectedTeams] = useState(teams.map((t) => t.value));
  const [monthStartDatePicker, setMonthStartDatePicker] = useState(defaultDate);
  const [monthEndDatePicker, setMonthEndDatePicker] = useState(maxDate);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [commesse, setCommesse] = useState([]);
  const [selectedCommessa, setSelectedCommessa] = useState([]);
  const [data, setData] = useState(undefined);
  const { push } = useContext(NotificationsContext);
  const [chartData, setChartData] = useState();
  const [tableData, setTableData] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [marginalityData, setMarginalityData] = useState(undefined);

  const fetchClients = () => {
    const monthStart = formatDate(monthStartDatePicker);
    const monthEnd = formatDate(monthEndDatePicker);
    api
      .get(`/economics/productivity/clients/${monthStart}/${monthEnd}`)
      .then(({ data: result }) => {
        setClients(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchCommesse = () => {
    const monthStart = formatDate(monthStartDatePicker);
    const monthEnd = formatDate(monthEndDatePicker);
    const payload = {
      month_start: monthStart,
      month_end: monthEnd,
      clients: selectedClient.map((c) => c.value),
    };

    api
      .post(`/economics/productivity/commesse`, payload)
      .then(({ data: result }) => {
        setCommesse(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchData = () => {
    const monthStart = formatDate(monthStartDatePicker);
    const monthEnd = formatDate(monthEndDatePicker);
    const payload = {
      teams: selectedTeams,
      month_start: monthStart,
      month_end: monthEnd,
      cod_cliente: selectedClient.map((c) => c.value),
      cod_commessa: selectedCommessa.map((c) => c.value),
    };

    api
      .post(`/economics/productivity`, payload)
      .then(({ data: result }) => {
        setData(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  const fetchMarginalityData = () => {
    api
      .get(`/economics/productivity/marginality`)
      .then(({ data: result }) => {
        setMarginalityData(result);
      })
      .catch(() => {
        push({ title: "Errore del server", type: "error" });
      });
  };

  useEffect(() => {
    fetchClients();
    fetchCommesse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthStartDatePicker, monthEndDatePicker, selectedClient]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedTeams,
    monthStartDatePicker,
    monthEndDatePicker,
    selectedClient,
    selectedCommessa,
  ]);

  useEffect(() => {
    if (!marginalityData) {
      fetchMarginalityData();
    }
  });

  useEffect(() => {
    if (data) {
      setChartData(
        data.trend.map((t) => ({
          date: t.dat_month,
          val_giorni: t.val_ore_vendute_totali / 8,
          val_ricavi: t.val_ricavi_totali,
        }))
      );
      setTableData(data.people);
    }
  }, [data]);

  return (
    <div className="flex flex-col">
      <PageHeading title="Produttività" />
      <div className="-mt-12">
        <Tabs
          tabs={[
            {
              name: "Analisi Ricavi",
              value: 0,
            },
            {
              name: "Dettaglio",
              value: 1,
            },
            {
              name: "Marginalità",
              value: 2,
            },
            {
              name: "Ruoli",
              value: 3,
            },
          ]}
          active={tabIndex}
          setActiveTab={setTabIndex}
        />
      </div>

      {data ? (
        tabIndex === 0 ? (
          <DashBoardRevenueCharts
            teams={teams}
            push={push}
          />
        ) :
        tabIndex === 1 ? (
          <DashboardDettaglio
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            monthStartDatePicker={monthStartDatePicker}
            setMonthStartDatePicker={setMonthStartDatePicker}
            monthEndDatePicker={monthEndDatePicker}
            setMonthEndDatePicker={setMonthEndDatePicker}
            minDate={minDate}
            maxDate={maxDate}
            clients={clients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            commesse={commesse}
            selectedCommessa={selectedCommessa}
            setSelectedCommessa={setSelectedCommessa}
            data={data}
            chartData={chartData}
            tableData={tableData}
            intl={intl}
            push={push}
          />
        ) : tabIndex === 2 ? (
          <DashBoardMarginality
            data={marginalityData}
            intl={intl}
            push={push}
          />
        ) : (
          <DashboardRoles
            push={push}
            fetchMarginalityData={fetchMarginalityData}
          />
        )
      ) : (
        <div className="flex flex-col w-full mb-4 gap-y-5">
          <Placeholder height="h-12" classNames="rounded-lg" />
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-80" classNames="rounded-lg" />
          </div>
          <div className="flex flex-row gap-x-5">
            <Placeholder height="h-80" classNames="rounded-lg" />
          </div>
        </div>
      )}
    </div>
  );
}
