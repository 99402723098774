import React, {useState} from "react";

import _ from "lodash";
import {HandThumbDownIcon, HandThumbUpIcon} from "@heroicons/react/20/solid";
import Modal from "../../../ui-components/Modal";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";
import Separator from "../../../ui-components/Separator";


function MissingHoursDetail({intl, data, previousMonth, currentMonth}) {

  const [showDetailMonth, setShowDetailMonth] = useState(null);

  const title = {
    lastMonth: new Date(previousMonth),
    currentMonth: new Date(currentMonth)
  }

  return (
    <div className="flex flex-col gap-1 items-center">
      <div className="text-xs font-bold">Completamento Gescli</div>
      <div className="flex flex-row gap-2 w-72">
        {['lastMonth', 'currentMonth'].map(k => (
          <div key={k} className="w-full flex flex-col p-2 border border-gray-700 rounded-3xl items-center">
            <div className="text-xs font-bold capitalize text-am-700">{intl.formatDate(title[k], {
              month: "short",
              year: "numeric"
            })}</div>
            {data[k].length === 0
              ? <span className="text-green-500 opacity-60 mt-2 -mb-1"><HandThumbUpIcon width="20" height="20"/></span>
              : <div className="flex flex-row mt-2 gap-2 cursor-pointer" onClick={() => setShowDetailMonth(k)}>
                <span className="text-red-500 opacity-60 -mt-1"><HandThumbDownIcon width="20" height="20"/></span>
                <span className="text-xxs underline">{[...new Set(data[k].map(i => i.cod_risorsa))].length} persone mancanti</span>
              </div>
            }
          </div>
        ))}
      </div>
      {showDetailMonth &&
        <Modal opened={true} onExit={() => setShowDetailMonth(null)}>
          <div>
            <div className="flex justify-center"><ExclamationTriangleIcon width="30"/></div>
            <div className="font-bold text-lg text-center">Dettaglio Ore Mancanti</div>
            <div className="capitalize text-center">{intl.formatDate(title[showDetailMonth], {
              month: "long",
              year: "numeric"
            })}</div>
            {[...new Set(data[showDetailMonth].map(i => i.des_team))].sort().map(team => (
              <div key={team} className="mt-2">
                <Separator label={team}/>
                {[...new Set(data[showDetailMonth].filter(i => i.des_team === team).map(i => i.cod_risorsa))].map(risorsa => {
                  const rows = data[showDetailMonth].filter(i => i.cod_risorsa === risorsa)
                  return <div key={risorsa} className="text-sm ml-14">
                    {risorsa.replace('.', ' ')} <b>{rows.reduce((acc, i) => acc + i.num_ore_mancanti, 0)}</b>
                    <div className="mt-1 mb-3">
                      {rows.map(i => (<li className="ml-5 text-xs">
                        <b>{i.num_ore_mancanti}</b> ore il {intl.formatDate(i.dat_riferimento, {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      })}
                      </li>))}
                    </div>
                  </div>

                })}
              </div>
            ))}
          </div>
        </Modal>
      }
    </div>
  );
}

export default React.memo(MissingHoursDetail, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data);
});


