import React, {useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";
import _ from "lodash";

// Components

// Ui
import {PageSection} from "../../ui-components/Container";
import Placeholder from "../../ui-components/Placeholder";
import Input from "../../ui-components/Input";
import StaffTeamDonutChart from "./staffTeamDonutChart";
import RadioGroup from "../../ui-components/RadioGroup";
import DoubleGaugeChart from "@components/Charts/DoubleGaugeChart";


function StaffHome({intl, push, userData}) {

  const [data, setData] = useState();
  const [team, setTeam] = useState();
  const [hqFte, setHqFte] = useState('fte');
  const [projects, setProjects] = useState([]);
  const [filterHours, setFilterHours] = useState(8);
  const [hours, setHours] = useState({vendute: 0, lavorate: 0})
  const [trainingDetail, setTrainingDetail] = useState();
  const now = new Date();
  const firstGen = new Date(now.getFullYear(),  0 , 1)

  const fetchData = () => {
    apiRequest
      .post("/economics/home-kpis", {cod_gescli: userData.cod_gescli})
      .then((result) => {
        setData(result);
      })
      .catch(() => {
        push({title: "Errore del server", type: "error"});
      })
  }

  const fetchMyTrainingHours = () => {
    apiRequest
      .post("/course-suite/training-hours", { id_user: userData.id, year: new Date().getFullYear() })
      .then((result) => {
        setTrainingDetail(result);
      })
      .catch(() => {
        push({ title: intl.formatMessage({ id: "error_loading" }), type: "error" });
      })
  }

  useEffect(() => {
    fetchMyTrainingHours();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      const res = _(data)
        .groupBy('cod_team')
        .map((items, t) => {
          return ({
            value: t,
            name: items[0].des_team,
            num_resources: [...new Set(items.map(i => i.cod_risorsa))].length,
            val_ore: _.sumBy(items, 'val_ore'),
            fte: Math.round(_.uniqBy(items, 'cod_risorsa').reduce((acc, i) => acc + i.fte, 0) * 10) / 10
          })
        }).value()
      setTeam(_.orderBy(res, 'num_resources', 'desc'))

      const prj = _((data || []).filter(i => i.cod_categoria === 'vendute'))
        .groupBy('cod_commessa')
        .map((items, t) => {
          return ({
            value: t,
            name: items[0].des_commessa,
            customer: items[0].des_cliente,
            val_ore: _.sumBy(items, 'val_ore'),
          })
        }).value()
      setProjects(_.orderBy(prj, 'val_ore', 'desc'))
    }
  }, [data])

  useEffect(() => {
    if (projects && team) {
      setHours({
        lavorate: Math.round((team || []).reduce((acc, i) => acc + i.val_ore, 0)),
        vendute: Math.round(projects.reduce((acc, i) => acc + i.val_ore, 0))
      })
    }

  }, [projects, team])


  return (
    <div className="StaffHomeContainer mt-10">
      <div className="flex justify-between flex-col sm:flex-row gap-5">
        <div className="flex flex-col sm:w-3/12">
          <PageSection title={intl.formatMessage({id: "my_team"})}>
            {team ?
              <div>
                <div>
                  <RadioGroup
                    id="choose_hq_fte"
                    horizontal
                    options={[
                      {value: "fte", name: "FTE"},
                      {value: "num_resources", name: "Headcount"}
                    ]}
                    currentValue={hqFte}
                    onChange={(value) => {
                      setHqFte(value)
                    }}
                  />
                </div>
                <StaffTeamDonutChart id="staff-team-donut-chart" data={team} measure={hqFte}/>
              </div>
              : <Placeholder height="h-40" classNames="rounded-lg my-8"/>
            }
          </PageSection>
        </div>
        <div className="flex flex-col sm:w-4/12">
          <PageSection title="Progetti in corso">
            {data ?
              <div>
                <div className="text-xs italic">
                  Dal {intl.formatDate(firstGen, {day: "numeric", month: "long", year: "numeric"})}
                </div>
                <div className="w-56 mt-4">
                  <Input
                    value={filterHours}
                    onChange={(e) => {
                      setFilterHours(parseFloat(e.target.value))
                    }}
                    label="Numero minimo di ore"
                    placeholder="Ore"
                    type="number"
                    numberOptions={{
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }}
                  />
                </div>
                <div className="border border-neutral-600 rounded-2xl p-2 my-3 flex flex-row justify-between">
                  <span>Commesse in lavorazione</span>
                  <span className="font-bold">
                    {projects.filter(i => i.val_ore >= filterHours).length}
                  </span>
                </div>
                <div className="border border-neutral-600 rounded-2xl p-2 my-3 flex flex-row justify-between">
                  <span>Clienti distinti</span>
                  <span className="font-bold">
                    {[...new Set(projects.filter(i => i.val_ore >= filterHours).map(i => i.customer))].length}
                  </span>
                </div>
                <div className="border border-neutral-600 rounded-2xl p-2 my-3 flex flex-row justify-between">
                  <span>Ore vendute</span>
                  <span className="font-bold">
                    {hours.vendute} su {hours.lavorate} ({(hours.vendute / hours.lavorate * 100).toFixed(1)}%)
                  </span>
                </div>
                <div className="border border-neutral-600 rounded-2xl p-2 my-3 flex flex-row justify-between">
                  <span>Ore vendute per FTE</span>
                  <span className="font-bold">
                    {(hours.vendute / (team || []).reduce((acc, i) => acc + i.fte, 0)).toFixed(1)}
                  </span>
                </div>
              </div>
              : <Placeholder height="h-40" classNames="rounded-lg my-8"/>
            }
          </PageSection>
        </div>
        <div className="flex flex-col sm:w-4/12">
          <PageSection title={intl.formatMessage({ id: "training_hours" })}>
            {trainingDetail ? (
              <>
                <DoubleGaugeChart
                  id="Gauge Home"
                  data={trainingDetail}
                />
                <p className="text-center">{intl.formatMessage({ id: "available_hours" })}: {Math.max(Math.round(trainingDetail['available_training_hours'] - trainingDetail['total_training_hours']), 0)} </p>
              </>
            ) : (
              <Placeholder classNames="h-240px" />
            )
            }
          </PageSection>
        </div>
      </div>
    </div>
  );
}

export default StaffHome;
