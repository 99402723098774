import React, {useContext, useEffect, useRef, useState} from "react";
import {PageHeading, PageSection} from "../../ui-components/Container";
import PointRating from "../../components/PointRating";
import Button from "../../ui-components/Button";
import apiRequest from "../../services/apiRequest";
import CourseQuestion from "./CourseQuestion";
import {NotificationsContext} from "../../ui-components/Notifications";
import AddAttendanceReport from "../../components/Dialogs/AddAttendanceReport";
import {ArrowLeftIcon} from "@heroicons/react/24/solid";
import CourseAttendance from "./CourseAttendance";
import {formatDate} from "../../utils";
import {useIntl} from "react-intl";
import AuthenticationService from "../../services/AuthenticationService";
import Modal from "../../ui-components/Modal";


function CourseSpecifics({id_course}) {

  const userData = AuthenticationService.getUserData();
  const [courseInfo, setCourseInfo] = useState(undefined);
  const [courseQuestion, setCourseQuestion] = useState(undefined);
  const [coursePeople, setCoursePeople] = useState(undefined);
  const [courseAttend, setCourseAttend] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [reportHour, setReportHour] = useState(0);
  const [previousEvaluations, setPreviousEvaluations] = useState();
  const intl = useIntl();

  const {push} = useContext(NotificationsContext);
  const myRef = useRef(null)

  console.log(courseInfo)

  // Check if an object has undefined value
  const checkUndefinedValue = (state) => {
    if (state == null) {
      return true;
    }
    Object.keys(state).forEach((key) => {
      if (state[key] == null) {
        return true;
      }
    })
    return false;
  }

  const postAttendanceReport = () => {

    if (reportHour <= 0) {
      push({
        title: intl.formatMessage({id: "positive_hours_error"}),
        type: "error",
      });
      return;
    }

    const data = {
      dat_attendance: formatDate(new Date()),
      hours: reportHour,
      id_reported_course: id_course
    }

    apiRequest
      .post('/course-suite/attendance-report', data)
      .then(() => {
        push({
          title: intl.formatMessage({id: "hours_insert_success"}),
          type: "success",
        });
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
      })
  }

  const attendUserCourse = () => {
    apiRequest
      .post("/course-suite/attend", {id_course_attended: parseInt(id_course)})
      .then(() => {
        setCourseAttend(true);
        push({
          title: intl.formatMessage({id: "course_subscription_success"}),
          type: "success",
        });
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
      })
  }

  const removeUserFromCourse = () => {
    apiRequest
      .delete(`/course-suite/attend/${userData.id}/${id_course}`)
      .then(() => {
        setCourseAttend(false);
        push({
          title: intl.formatMessage({id: "delete_subscription_success"}),
          type: "success",
        });
      })
      .catch(() => {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
      })
  }

  const saveUserEvaluationCourse = () => {
    courseQuestion.forEach((q) => {
      apiRequest
        .get(`/course-suite/question-evaluation/update-evaluation/${id_course}/${q['id_question']}/${q.eval}`)
        .then(() => {
          push({title: intl.formatMessage({id: "evaluation_success"}), type: "success"});
          fetchCourseInfo();
        })
        .catch(() => {
          push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"});
        })
    })
  }

  const fetchCourseInfo = () => {
    apiRequest
      .get(`/course-suite/courses/skill/evaluation/${id_course}`)
      .then((result) => {
        setCourseInfo(result)
      })
      .catch(() => {
        console.log("ERROR")
      })
  }

  const fetchCourseAttendance = () => {
    apiRequest
      .get(`/course-suite/attend/course/${id_course}`)
      .then((result) => {
        setCoursePeople((result || []).map((e) => {
          if (e['id_user_attended'] === userData.id) {
            setCourseAttend(true)
          }
          return e['user_attended']['full_name']
        }))
      })
      .catch(() => {
        console.log("ERROR")
      })
  }

  useEffect(() => {
   fetchCourseAttendance()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseAttend])

  useEffect(() => {
    let tmp = {}
    apiRequest
      .get(`/course-suite/question-evaluation/user/course/${userData.id}/${id_course}`)
      .then((result) => {
        (result || []).forEach(i => {
          tmp[i['id_question']] = i['id_course_evaluation']
        })
      })
      .catch(() => {
        console.log("ERROR")
      })
      .finally(setPreviousEvaluations(tmp))
  }, [id_course, userData.id, setPreviousEvaluations])


  useEffect(() => {
    if (previousEvaluations) {

      fetchCourseInfo()

      apiRequest
        .get("/course-suite/questions")
        .then((result) => {
          setCourseQuestion((result || []).map((e) => {
            return {...e, eval: previousEvaluations[e['id_question']] || 0}
          }));
        })
        .catch(() => {
          console.log("ERROR")
        })

      apiRequest
        .get(`/course-suite/attendance-report/${userData.id}/${id_course}`)
        .then((result) => {
          if ((result || []).length) {
            setReportHour(result[0]['hours'])
          }
        })
        .catch(() => {
          console.log("ERROR")
        })

      fetchCourseAttendance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_course, previousEvaluations])


  return (
    <div>
      {!checkUndefinedValue(courseInfo) && (
        <div>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <PageHeading title={
              <div className="flex flex-col sm:flex-row sm:items-center">
                <ArrowLeftIcon
                  className="h-6 w-6 mr-2 cursor-pointer"
                  onClick={() => window.location = "/course"}
                />
                {courseInfo.name_course}
              </div>}
            />
            <div className="flex flex-row items-center gap-x-5 -mt-8 sm:mt-0">
              <p>{intl.formatMessage({id: "avg_course_evaluation"})}: </p>
              <PointRating evaluation={courseInfo.eval}/>
            </div>
          </div>
          <div className="flex justify-between mt-8 sm:mt-0">
            <PageSection title={intl.formatMessage({ id: "goto_course_site" })}>
            <div className={"flex gap-x-5"}>
              {courseInfo.json_url_site ?
                // console.log(courseInfo.json_url_site) : <></>
                Object.keys(courseInfo.json_url_site).map((value, index) => {
                  console.log(value);
                  console.log(courseInfo.json_url_site[value]);
                  return (
                    <form action={courseInfo.json_url_site[value]} target="_blank" key={index}>
                      <Button>
                        {value}
                      </Button>
                    </form>
                  )
                }) : <></>
              }
            </div>
            </PageSection>
            <div className="flex items-center">
              <div className="flex">
                {courseAttend ? (
                  <Button styleType="danger" onClick={() => setShowRemoveConfirm(true)}>
                    {intl.formatMessage({id: "remove_participation"})}
                  </Button>
                ) : (
                  <Button onClick={() => attendUserCourse()}>
                    {intl.formatMessage({id: "partecipate"})}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="pt-6">
            <PageSection title={intl.formatMessage({id: "description"})}>
              <p>
                {courseInfo.des_course}
              </p>
            </PageSection>
          </div>
          {courseInfo.note && courseInfo.note !== "" && (
            <div>
              <PageSection title={intl.formatMessage({id: "notes"})}>
                <p>
                  {courseInfo.note}
                </p>
              </PageSection>
            </div>
          )}
          <div>
            <PageSection title={intl.formatMessage({id: "info"})}>
              <div className="flex flex-row gap-x-2">
                <p className="font-bold ">{intl.formatMessage({id: "id_course"})}: </p>
                <p>{courseInfo.id_course} <span className="italic text-xs">(da riportare in Gescli)</span></p>
              </div>
              <div className="flex flex-row gap-x-2 my-1">
                <p className="font-bold ">{intl.formatMessage({id: "course_type"})}: </p>
                <p>{courseInfo.id_type}</p>
              </div>
              {courseInfo.speaker !== "" && (
                <div className="flex flex-row gap-x-2 pt-2">
                  <p className="font-bold">{intl.formatMessage({id: "speaker"})}: </p>
                  <p>{courseInfo.speaker}</p>
                </div>
              )}
            </PageSection>
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-x-8">
            <div className="w-full sm:w-6/12">
              <PageSection title={intl.formatMessage({id: "covered_skills"})}>
                {courseInfo['skills_list'].map((item) => {
                  return (<li className="list-disc list-inside">
                    {item}
                  </li>)
                })}
              </PageSection>
            </div>
            <div className="w-full sm:w-6/12">
              <PageSection title={intl.formatMessage({id: "course_followers"})}>
                {coursePeople && coursePeople.map((person) => {
                  return (<li key={person} className="list-disc list-inside">{person}</li>)
                })}
              </PageSection>
            </div>
          </div>
          {courseAttend && (
            <div className="flex flex-col">
              <PageSection title={intl.formatMessage({id: "eval_the_course"})}>
                <div className="flex flex-col w-full">
                  {courseQuestion && (
                    courseQuestion.map((q) => {
                      return <CourseQuestion
                        key={q['id_question']}
                        question={q}
                        courseQuestion={courseQuestion}
                        setCourseQuestion={setCourseQuestion}
                      />
                    })
                  )}
                  {courseQuestion && (
                    <div ref={myRef} className="flex justify-end pt-5">
                      <Button
                        onClick={() => saveUserEvaluationCourse()}>{intl.formatMessage({id: "submit_eval"})}
                      </Button>
                    </div>
                  )}
                </div>
              </PageSection>
              <PageSection title={intl.formatMessage({id: "hours_report"})}>
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                  <CourseAttendance reportHour={reportHour} setReportHour={setReportHour}/>
                  <div className="flex justify-end">
                    <Button className="mb-3" onClick={() => postAttendanceReport()}>
                      {intl.formatMessage({id: "submit_hours_report"})}
                    </Button>
                  </div>
                </div>
              </PageSection>
            </div>
          )}
        </div>
      )}
      <AddAttendanceReport
        open={showReport}
        toggleOpen={() => setShowReport(false)}
        id_course={id_course}
      />
      {showRemoveConfirm &&
        <Modal
          title={intl.formatMessage({id: "confirm_remove_participation"})}
          onExit={() => setShowRemoveConfirm(false)}
          onConfirm={removeUserFromCourse}
        />
      }
    </div>
  );
}

export default CourseSpecifics;