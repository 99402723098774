import React from "react";

const Separator = ({label}) => {
  return (
      <div className="relative flex py-5 items-center">
        <div className="w-14 border-t border-gray-400"></div>
        <span className="flex-shrink mx-4 text-gray-900 text-lg font-extrabold">{label}</span>
        <div className="flex-grow border-t border-gray-400"></div>
      </div>
  )
};


export default Separator;