import React, {createContext, useState} from 'react';

const DataContext = createContext({});
const {Provider} = DataContext;
const DataProvider = ({children}) => {

  // List of user
  const [userList, setUserList] = useState(null);
  // List of skills
  const [skillList, setSkillList] = useState(null);
  // List of course type
  const [courseTypeList, setCourseTypeList] = useState(null);
  // List of customers
  const [customerList, setCustomerList] = useState(null);
  // List of projects
  const [projectList, setProjectList] = useState(null);
  // List of projects I'm working on
  const [myProjects, setMyProjects] = useState(null);

  return (
    <Provider
      value={{
        userList, setUserList: (v) => setUserList(v),
        skillList, setSkillList: (v) => setSkillList(v),
        courseTypeList, setCourseTypeList: (v) => setCourseTypeList(v),
        customerList, setCustomerList : (v) => setCustomerList(v),
        projectList, setProjectList : (v) => setProjectList(v),
        myProjects, setMyProjects : (v) => setMyProjects(v),
      }}
    >
      {children}
    </Provider>
  );
};
export {DataContext, DataProvider}