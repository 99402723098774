import React, {useState, useEffect} from "react";

// Services
import {useIntl} from "react-intl";
import {api} from "@services/apiRequest";
import useHostsWithoutSummary from "@hooks/useMonitoring/useHostsWithoutSummary";

// Ui
import Tabs from "@ui-components/Tabs";
import Placeholder from "@ui-components/Placeholder";
import {PageHeading} from "@ui-components/Container";

// Types
import {AppSchema, CustomerSchema, HostSchema, User, People} from "@pages/Monitoring/Monitoring.types";
import {MonitoringOverview} from "@pages/Monitoring/Overview";
import {MonitoringDetail} from "@pages/Monitoring/Detail";
import Button from "@ui-components/Button";
import {AddUpdateAppDialog} from "@pages/Monitoring/Detail/AddUpdateAppDialog";
import useOrganizationChart from "@ui-components/pages/Users/hooks/useOrganizationChart";
import useAlmagammaRoles from "@ui-components/pages/Users/hooks/useAlmagammaRoles";


export function Monitoring() {
  const intl = useIntl();
  const hostsWithoutSummary: HostSchema[] | undefined = useHostsWithoutSummary();
  const [customers, setCustomers] = useState<CustomerSchema[] | undefined>(undefined);
  const [appList, setAppList] = useState<AppSchema[] | undefined>(undefined);
  const [ccApps, setCcApps] = useState<AppSchema[] | undefined>(undefined);
  const [addEditApp, setAddEditApp] = useState<AppSchema | undefined>(undefined);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [people, setPeople] = useState<People[] | undefined>(undefined);
  const [tabIndex, setTabIndex] = useState(0);

  const statoCaringOptions = [
    'fine sviluppi',
    'rilascio in corso',
    'UAT',
    'redazione manuale utenti',
    'intro CC',
    'affiancamenti',
    'follow-up settimanale',
    'follow-up mensile',
    'follow-up trimestrale',
    're-training',
    'monitoraggio utilizzo',
  ]

  // @ts-ignore
  const almagammaRoles: any[] = useAlmagammaRoles();
  // @ts-ignore
  const organizationChart: any[] = useOrganizationChart();

  useEffect(() => {
    const fetchCustomers = () => {
      api
        .get<CustomerSchema[]>("/monitoring/customer")
        .then(({data}) => {
          setCustomers(data);
        })
        .catch(() => {
          console.log("error");
        });
    };

    if (!customers) {
      fetchCustomers();
    }

    const fetchAppList = () => {
      api
        .get<AppSchema[]>("/monitoring/cc_apps")
        .then(({data}) => {
          setCcApps(data);
          setAppList(data.filter(i => i.des_dev_link || i.des_prod_link));
        })
        .catch(() => {
          console.log("error");
        });
    };

    if (!appList) {
      fetchAppList();
    }

    const fetchUsers = () => {
      api
        .get<User[]>("/users")
        .then(({data}) => {
          setUsers(data);
        })
        .catch(() => {
          console.log("error");
        });
    };

    if (!users) {
      fetchUsers();
    }

  }, [customers, appList, users]);

  useEffect(() => {
    if (almagammaRoles && organizationChart && users) {
      setPeople(users.map(i => {
        const org = organizationChart.filter(j => j.id_user === i.id)[0]
        return {
          id: i.id,
          disabled: i.disabled,
          cod_gescli: org['cod_gescli'],
          role_name: almagammaRoles.filter(j => j.id_role === org.id_user_role)[0]['role_name']
        }
      }))
    }
  }, [almagammaRoles, organizationChart, users])


  return (
    <div>
      <div className="-mb-5 sm:-mb-10">
        <PageHeading
          title={intl.formatMessage({id: "monitoring"})}
          contentActions={tabIndex === 1
            // @ts-ignore
            ? <Button onClick={() => setAddEditApp({id: 0})}>
              Aggiungi
            </Button>
            : null}
        />
      </div>
      {hostsWithoutSummary && customers && appList && ccApps ?
        <div>
          <Tabs
            setActiveTab={setTabIndex}
            active={tabIndex}
            tabs={[
              {name: "Overview"},
              {name: "Dettaglio Commesse"},
            ]}
          />
          {tabIndex === 0 &&
            <MonitoringOverview
              appList={appList}
              customers={customers}
              hostsWithoutSummary={hostsWithoutSummary}
            />
          }
          {tabIndex === 1 &&
            <MonitoringDetail
              ccApps={ccApps}
              customers={customers}
              setAddEditApp={setAddEditApp}
              statoCaringOptions={statoCaringOptions}
            />
          }
          {addEditApp && people ?
            <AddUpdateAppDialog
              people={people}
              app={addEditApp}
              onExit={() => setAddEditApp(undefined)}
              setCcApps={setCcApps}
              statoCaringOptions={statoCaringOptions}
            />
            : null
          }
        </div>
        :
        <div className='flex flex-col w-full my-5 gap-2'>
          <div className='flex flex-row gap-10 mb-1'>
            <Placeholder height='h-12' classNames='rounded-lg'/>
            <Placeholder height='h-12' classNames='rounded-lg'/>
          </div>
          <Placeholder height='h-24' classNames='rounded-lg'/>
          {[...new Array(3)].map((_, i) =>
            <div className='flex flex-row gap-5 mb-1' key={i}>
              <Placeholder height='h-56' classNames='rounded-lg'/>
              <Placeholder height='h-56' classNames='rounded-lg'/>
              <Placeholder height='h-56' classNames='rounded-lg'/>
            </div>
          )}
        </div>
      }

    </div>
  )
    ;
}