import React, {useContext, useEffect, useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {tableIcons} from "../../ui-components/TableIcons";
import Button from "../../ui-components/Button"
import {COLUMNS, LOCALIZATION, OPTIONS} from "./Params/EvaluationParamsP2P";
import Slider from "../../ui-components/Slider";
import apiRequest from "../../services/apiRequest";
import {formatDate} from "../../utils";
import {NotificationsContext} from "../../ui-components/Notifications";
import TableStyle from "../../ui-components/TableStyle";

function EvaluationTableP2P({data, id_user, id_proj}) {

    const [sendData, setSendData] = useState(data);
    const {push} = useContext(NotificationsContext);

    const marks = [
        {value: -1, label: 'NA',},
        {value: 0, label: '0',},
        {value: 1, label: '1',},
        {value: 2, label: '2',},
        {value: 3, label: '3',},
        {value: 4, label: '4',},
        {value: 5, label: '5',}
    ];

    useEffect(() => {
        const temp = data.map((e) => {
            return {...e, evaluation: -1}
        })
        setSendData(temp);
    }, [data])


    const checkIfAllEvalIsNaN = () => {
        sendData.forEach((e) => {
            if (e.evaluation !== -1) {
                return true
            }
        })
        return false;
    }

    const updateData = (id_skill, evaluation) => {
        let temp = sendData;
        const objIndex = sendData.findIndex((obj => obj.id_skill === id_skill));
        temp[objIndex].evaluation = evaluation;
        setSendData(temp);
    }

    const postEvaluation = () => {
        const datEvaluation = formatDate(new Date())
        sendData.forEach((obj) => {
            if (obj.evaluation && obj.evaluation !== -1) {
                const temp = {
                    id_user_valutato: id_user,
                    id_proj: id_proj,
                    id_skill: obj.id_skill,
                    id_grade: obj.evaluation,
                    dat_evaluation: datEvaluation
                }
                console.log(temp);
                apiRequest
                    .post(`/evaluation-suite/p2p`, temp)
                    .then((result) => {
                       console.log("OK")
                    })
                    .catch((error) => {
                        push({title: "You already evaluate this user for this project!", type: "error"});
                        return;
                    })
            }

        })
        push({title: "Thanks!", type: "info"});
    }


    return (
        <div>
            {data && (
                <div>
                    <TableStyle>
                        <MaterialTable
                            columns={COLUMNS}
                            data={data}
                            icons={tableIcons}
                            isLoading={data === undefined}
                            options={OPTIONS}
                            localization={LOCALIZATION}
                            actions={
                                [
                                    {
                                        icon: () => <Button>eval</Button>,
                                        tooltip: 'Save Evaluations',
                                        onClick: (newValue, row_data) => {
                                            updateData(row_data.id_skill, newValue)
                                        }
                                    }
                                ]}
                            components={{
                                Action: props => (
                                    <Slider
                                        step={1}
                                        min={-1}
                                        max={5}
                                        marks={marks}
                                        // defaultValue={props.data.evaluation}
                                        defaultValue={data.evaluation}
                                        onChange={(e, newValue) => {
                                            props.action.onClick(newValue, props.data)
                                        }
                                        }
                                    />
                                ),
                            }}
                        />
                    </TableStyle>
                    <div className="w-full flex flex-row-reverse p-3">
                        <Button onClick={() => {
                            if (!checkIfAllEvalIsNaN()) {
                                push({title: "At least one evaluation must to be set!", type: "error"});
                            }
                            postEvaluation();
                        }}>Save</Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default React.memo(EvaluationTableP2P, (props, nextProps) => {
    // Render component only when users changes
    return _.isEqual(props.data, nextProps.data);

});


