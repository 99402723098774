import React, {useCallback, useContext, useEffect, useState} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";
import {DataContext} from "../../services/DataContext/DataContext";
import apiRequest from "../../services/apiRequest";
import {formatDate} from "../../utils";
import {useIntl} from "react-intl";

// Ui
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import DatePicker from "../../ui-components/DatePicker";
import SearchSelect from "../../ui-components/SearchSelect";

function AddStaffToProject({open, toggleOpen, project, setProjStaff, projStaff, userId, organizationRoles}) {

  const [loading, setLoading] = useState(false);
  const {push} = useContext(NotificationsContext);
  const intl = useIntl();
  const [userDatStart, setUserDatStart] = useState(new Date(project.dat_init));
  const [userDatEnd, setUserDatEnd] = useState(new Date(project.dat_finish));
  
  const dataContext = useContext(DataContext);
  const options = (dataContext.userList || []).map(i => ({label: i.full_name, value: i.id}))
  const [userChoose, setUserChoose] = useState(userId ? (options.filter((i) => i.value === userId)[0]) : null);

  useEffect(() => {
    if (options && userId && !userChoose) {
      setUserChoose(options.filter((i) => i.value === userId)[0])
    }
  }, [userChoose, options, userId])

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!userChoose || !userDatStart || !userDatEnd) {
          push({title: intl.formatMessage({ id: "missing_data" }), type: "error"});
          return;
        }
        setLoading(true);
        const data = {
          id_user: userChoose.value,
          id_proj: project.id_proj,
          dat_init: userDatStart ? formatDate(userDatStart) : null,
          dat_fine: userDatEnd ? formatDate(userDatEnd) : null
        }

        apiRequest
          .post("/projects/rel/working_on", data)
          .then(() => {
            toggleOpen();
            push({title: intl.formatMessage({ id: "item_added" }), type: "success"});
            const newUser = options.find(i => i.value === data.id_user)
            setProjStaff([
              ...projStaff.filter(i => i.id !== data.id_user),
              {name: newUser.label, id: data.id_user, dat_init: data.dat_init, dat_fine: data.dat_fine}
            ])
            setUserChoose(null)
          })
          .catch(() => {
            push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
          })

      } catch (error) {
        push({title: intl.formatMessage({id: "something_went_wrong"}), type: "error"})
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userChoose, dataContext.userList, userDatStart, userDatEnd]
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            {userId ? (
              intl.formatMessage({id: "modify_staffing"})
            ) : (
              intl.formatMessage({id: "add_new_items"})
            )
            }
          </h3>
        </div>

        <form id="add-user-form" onSubmit={_onSubmit}>
          <div className="flex flex-col gap-y-5">
            <SearchSelect
              value={userChoose}
              onChange={(value) => {setUserChoose(value)}}
              options={options}
              CustomOption={(props) => {
                const { innerProps, innerRef } = props;
                return (
                  <article ref={innerRef} {...innerProps} className="custom-option">
                    <p className="font-light text-xs italic  cursor-pointer pl-2 pt-2">
                      {organizationRoles[props.data.value]}
                    </p>
                    <p className="font-bold cursor-pointer border-b-1 pl-2 pb-2">
                      {props.data.label}
                    </p>
                  </article>
                );
              }}
            />
            <div className="flex justify-between">
              <div className="w-2/5">
                <p>{intl.formatMessage({id: "date_init"})}</p>
                <DatePicker
                  minDate={new Date(project.dat_init)}
                  maxDate={new Date(project.dat_finish)}
                  value={userDatStart}
                  errorMessage="Seleziona una data"
                  onChange={(e) => setUserDatStart(e)}
                />
              </div>
              <div className="w-2/5">
                <p>{intl.formatMessage({id: "date_finish"})}</p>
                <DatePicker
                  minDate={new Date(project.dat_init)}
                  maxDate={new Date(project.dat_finish)}
                  value={userDatEnd}
                  errorMessage="Seleziona una data"
                  onChange={(e) => {console.log(e); setUserDatEnd(e)}}
                />
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              {userId ? (
                intl.formatMessage({id: "modify"})
              ) : (
                intl.formatMessage({id: "add"})
              )}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddStaffToProject;
