import React, {useEffect, useState} from "react";

// Services
import apiRequest from "../../services/apiRequest";
import Modal from "../../ui-components/Modal";
import EvaluationTableP2P from "../Tables/EvalutaionTableP2P";

function AddEvaluationP2P({open, toggleOpen, id_proj, id_user}) {

    const [skillToEval, setSkillToEval] = useState([]);
    const [skillJoinList, setSkillJoinList] = useState([]);
    const [skillTable, setSkillTable] = useState([]);


    useEffect(() => {
        apiRequest
            .get(`/skills/list_joined_skill`)
            .then((result) => {
                setSkillJoinList(result);
                console.log(result)
            })
            .catch((error) => {
                console.log("ERROR")
            })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (id_proj) {
            apiRequest
                .get(`/projects/explain/${id_proj}`)
                .then((result) => {
                    setSkillToEval(result);
                })
                .catch((error) => {
                    console.log("ERROR")
                })
        }

    }, [id_proj, open, id_user, toggleOpen])

    useEffect(() => {
        const temp = [];
        if (skillToEval === []) {
            console.log("NON CI SONO SKILL DA VALUTARE");
            return;
        }
        if (skillJoinList !== []) {
            skillJoinList.forEach((e) => {
                if (skillToEval.filter(i => i.id_skill === e.id_skill).length > 0) {
                    temp.push({...e, evaluation: -1});
                }
            })
        }
        setSkillTable(temp);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skillToEval])

    if (!open) {
        return null;
    }

    return (
        <div>
            {id_proj && (
                <div>
                    <Modal onExit={toggleOpen} className="w-fit">
                        <div className="m-8">
                            <h3 className="text-center text-2xl font-extrabold text-gray-900">
                                Evaluate
                            </h3>
                        </div>
                        <div className="">
                            {skillTable && (
                                <EvaluationTableP2P data={skillTable} id_user={id_user} id_proj={id_proj}/>
                            )}
                        </div>

                    </Modal>

                </div>
            )}

        </div>
    );
}

export default AddEvaluationP2P;
