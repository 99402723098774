import React, {useCallback, useContext, useState} from "react";

// Services
import {NotificationsContext} from "../../ui-components/Notifications";
import Modal from "../../ui-components/Modal";
import Button from "../../ui-components/Button";
import DatePicker from "../../ui-components/DatePicker";
import Input from "../../ui-components/Input";
import apiRequest from "../../services/apiRequest";
import {formatDate} from "../../utils";

function AddAttendanceReport({open, toggleOpen, id_course}) {
  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);

  const [report, setReport] = useState({
    dat_attendance: new Date(),
    hours: 0
  });

  const _onSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();

        if (!report.dat_attendance || !report.hours) {
          push({
            title: "Missing course data",
            type: "error",
          });
          return;
        }

        if (!report.hours <= 0) {
          push({
            title: "Number of hours must be greater than 0",
            type: "error",
          });
          return;
        }

        setLoading(true);

        const data = {
          dat_attendance: formatDate(report.dat_attendance),
          hours: report.hours,
          id_reported_course: id_course
        }

        apiRequest
          .post('/course-suite/attendance-report', data)
          .then(() => {
            toggleOpen();
            push({
              title: "Ore aggiunte correttamente!",
              type: "success",
            });
          })
          .catch(() => {
            push({title: "Qualcosa è andato storto. Forse hai già aggiunto ore per questo giorno?", type: "error"});
          })
      } catch (error) {
        console.log(error)
        push({
          title: "Errore imprevisto",
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [report] // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!open) {
    return null;
  }

  return (
    <div>
      <Modal onExit={toggleOpen}>
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            Reporting of hours
          </h3>
        </div>

        <form id="add-course-form" onSubmit={_onSubmit}>
          <div className="md:col-span-2">
            <DatePicker
              minDate={null}
              required
              value={report.dat_attendance}
              onChange={(i) => setReport({...report, dat_attendance: i})}
              label="Date attendance"
            />
          </div>
          <Input
            type="number"
            value={report.hours}
            required
            onChange={(e) => {
              setReport({...report, hours: parseInt(e.target.value)})
            }
            }
            validation={(value) => value > 0}
            label="Hours"
            numberOptions={{
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }}
          />
          <div className="mt-6 flex items-end justify-end">
            <Button submitting={loading} type="submit">
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddAttendanceReport;
