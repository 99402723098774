import React, {useState} from "react";

// UI
import MaterialTable from "@material-table/core";
import _ from "lodash";

// Table Params
import {getColumns, LOCALIZATION, OPTIONS} from "./Params/Project";
import {tableIcons} from "../../ui-components/TableIcons";
import TableStyle from "../../ui-components/TableStyle";

function ProjectTable({data, customers, setSelectedProject}) {

  const [columns] = useState(getColumns(customers))

  return (
    <>
      <TableStyle>
        <MaterialTable
          columns={columns}
          data={data.map((i, index) => ({...i, id: index}))}
          icons={tableIcons}
          options={OPTIONS}
          className="items-center"
          localization={LOCALIZATION}
          onRowClick={(rows, rowData) => setSelectedProject(rowData)}
        />
      </TableStyle>
    </>
  );
}

export default React.memo(ProjectTable, (props, nextProps) => {
  // Render component only when users changes
  return _.isEqual(props.data, nextProps.data) && _.isEqual(props.customers, nextProps.customers);
});
